import { useCartSkuCountQuery } from "../../../generated/graphql";
import { pagesPath } from "../../../utils/$path";
import { CartIcon } from "../../icons/CartIcon";
import { PrettyLink } from "../PrettyLink";

type Props = {
  iconSize?: number;
};

export const CartBox: React.FC<Props> = ({ iconSize }) => {
  const [{ data }] = useCartSkuCountQuery();

  const count = data?.giftDraft?.giftDraftSkus.length ?? 0;

  return (
    <PrettyLink href={pagesPath.cart.$url()}>
      <span className="relative">
        <CartIcon size={iconSize} />
        {count !== 0 && (
          <span className="absolute left-[12px] right-auto -top-[10px] flex h-[18px] min-w-[18px] items-center justify-center rounded-full bg-brand p-[2px] text-xs text-white">
            {count > 99 ? "99+" : count}
          </span>
        )}
      </span>
    </PrettyLink>
  );
};
