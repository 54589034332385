import { default as clsx } from "clsx";
import Link from "next/link";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { match } from "ts-pattern";

type Url = Parameters<typeof Link>[0]["href"];

type Props = {
  href: Url;
  disabled?: boolean;
  color?: "brand-white" | "white-black";
  px?: 3 | 4;
  py?: 3 | 4;
  children?: ReactNode;
};

export const ButtonLikeLink = forwardRef<HTMLAnchorElement, Props>(
  function ButtonLikeLink(props, ref) {
    const { color = "brand-white", px = 3, py = 3 } = props;

    return (
      <Link
        href={props.href}
        ref={ref}
        className={clsx(
          "block w-full rounded-lg border text-center text-sm font-bold focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-light",
          match(color)
            .with("brand-white", () => "border-brand bg-brand text-white")
            .with("white-black", () => "border-border bg-white text-text")
            .exhaustive(),
          match(px)
            .with(3, () => "px-3")
            .with(4, () => "px-4")
            .exhaustive(),
          match(py)
            .with(3, () => "py-3")
            .with(4, () => "py-4")
            .exhaustive(),
          props.disabled === true &&
            "pointer-events-none cursor-not-allowed opacity-30",
        )}
      >
        {props.children}
      </Link>
    );
  },
);
