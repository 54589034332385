type Props = {
  size?: number;
};

export const NotificationIcon: React.FC<Props> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C11.4477 2 11 2.44772 11 3V4C11 4.02372 11.0008 4.04724 11.0025 4.07054C7.60897 4.55472 5.00001 7.47268 5.00001 11L5.00001 17L3.85356 18.1464C3.53858 18.4614 3.76167 19 4.20712 19H5.00001H19H19.7929C20.2384 19 20.4614 18.4614 20.1465 18.1464L19 17L19 11C19 7.47268 16.3911 4.55472 12.9976 4.07054C12.9992 4.04724 13 4.02372 13 4V3C13 2.44772 12.5523 2 12 2ZM17 17V11C17 8.23858 14.7614 6 12 6C9.23859 6 7.00001 8.23858 7.00001 11V17H17ZM10 20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20H10Z"
        fill="#222222"
      />
    </svg>
  );
};
