import { atom } from "recoil";
import type { MeQuery } from "../generated/graphql";

export type Me = Omit<NonNullable<MeQuery["me"]["user"]>, "__typename">;

// キャッシュのため
export const meState = atom<[null | Me, boolean]>({
  key: "meState",
  default: [null, true],
});
