const plugin = require("tailwindcss/plugin");

/**
 * @type {import('tailwindcss').Config}
 */
module.exports = {
  content: ["./src/**/*.{ts,tsx}"],
  darkMode: "media",
  theme: {
    extend: {
      colors: {
        brand: {
          DEFAULT: "#d0576b",
          light: "#f2bDb8",
          pale: "#F3E6E5",
        },
        secondary: {
          DEFAULT: "#b4964b",
          30: "#e9e0c9",
        },
        text: "#383635",
        subtext: "#888686",
        border: "#e6e5e4",
        background: "#f7f6f3",
        placeholder: "#bab7b7",
        red: {
          DEFAULT: "#eb5757",
          light: "#fef5f5",
        },
      },
      dropShadow: {
        like: "0px 0px 4px rgba(0, 0, 0, 0.5)",
        "header-menu": "0px 2px 8px rgba(0, 0, 0, 0.12)",
        "float-button": "0px 2px 8px rgba(0, 0, 0, 0.12)",
        "bottom-area": "0px -2px 8px rgba(0, 0, 0, 0.12)",
        toast: "0px 2px 8px rgba(0, 0, 0, 0.12)",
        "giff-letter": "4px 8px 24px rgba(0, 0, 0, 0.1)",
      },
      transitionDuration: {
        850: "850ms",
      },
      spacing: {
        "header-menu-height": "48px",
        "header-menu-height-md": "64px",
        "bottom-menu-height": "52px",
      },
      maxWidth: {
        "screen-md": "752px",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    plugin(({ addVariant, e }) => {
      addVariant("inner-spin-button", ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => {
          return `.${e(
            `inner-spin-button${separator}${className}`,
          )}::-webkit-inner-spin-button`;
        });
      });
      addVariant("outer-spin-button", ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => {
          return `.${e(
            `outer-spin-button${separator}${className}`,
          )}::-webkit-outer-spin-button`;
        });
      });
    }),
  ],
};
