import { useRouter } from "next/router";
import { i18n } from "../../../i18n";
import { PrettyLink } from "../PrettyLink";
import { CalendarIcon } from "./CalendarIcon";
import { HomeIcon } from "./HomeIcon";
import { LetterIcon } from "./LetterIcon";
import { LikeIcon } from "./LikeIcon";
import { UserIcon } from "./UserIcon";
import { MenuLink } from "./MenuLink";
import { LinkList } from "./LinkList";
import { pagesPath, staticPath } from "../../../utils/$path";
import { useSession } from "../../../hooks/useSession";
import { Spinner } from "../../icons/Spinner";
import { AvatarIcon } from "./AvatarIcon";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

type SpMenuItem = {
  path: string;
  Icon: React.FC<{ active: boolean }>;
};

type PcLink = {
  href: string;
  label: string;
};

type PcLinkListItem = {
  title: string;
  links: PcLink[];
};

// TODO: SNSのhrefの修正
const pcLinkListItems: readonly PcLinkListItem[] = [
  {
    title: i18n.searchByList,
    links: [
      {
        href: "/",
        label: i18n.categoryList,
      },
      {
        href: pagesPath.brands.$url().pathname,
        label: i18n.brandList,
      },
      {
        href: "/",
        label: i18n.rankingList,
      },
      {
        href: "/",
        label: i18n.areaList,
      },
    ],
  },
  {
    title: i18n.companyInformation,
    links: [
      {
        href: pagesPath.faq.$url().pathname,
        label: i18n.faq,
      },
      {
        href: pagesPath.inquiry.$url().pathname,
        label: i18n.inquiry,
      },
      {
        href: pagesPath.privacy_policy.$url().pathname,
        label: i18n.privacyPolicy,
      },
      {
        href: pagesPath.tokutei.$url().pathname,
        label: i18n.tokutei,
      },
      {
        href: pagesPath.tos.$url().pathname,
        label: i18n.termsOfService,
      },
      {
        href: pagesPath.company.$url().pathname,
        label: i18n.company,
      },
    ],
  },
  {
    title: "SNS",
    links: [
      {
        href: "/",
        label: i18n.iPhoneApp,
      },
      {
        href: "/",
        label: i18n.androidApp,
      },
      {
        href: "/",
        label: i18n.twitter,
      },
      {
        href: "/",
        label: i18n.facebook,
      },
      {
        href: "/",
        label: i18n.instagram,
      },
    ],
  },
];

export const BottomMenu: React.FC = () => {
  const router = useRouter();
  const [me, isSessionLoading] = useSession();
  const { isMd } = useMediaQueries();

  const spMenuItems: readonly SpMenuItem[] = [
    {
      path: "/",
      Icon: HomeIcon,
    },
    {
      path: pagesPath.likes.$url().pathname,
      Icon: LikeIcon,
    },
    {
      path: pagesPath.gifts.letters.$url().pathname,
      Icon: LetterIcon,
    },
    {
      path: pagesPath.anniversaries.$url().pathname,
      Icon: CalendarIcon,
    },
    {
      path: pagesPath.mypage.$url().pathname,
      Icon: isSessionLoading
        ? () => <Spinner size={24} />
        : me === null
        ? UserIcon
        : ({ active }) => (
            <AvatarIcon active={active} url={me.avatarImageUrl} />
          ),
    },
  ];

  return (
    <>
      {isMd ? (
        <div className="bg-[#edeceb] px-5">
          <div className="mx-auto flex max-w-screen-lg py-12">
            <div className="flex-grow">
              <LinkList items={pcLinkListItems} />
            </div>
            <div className="ml-5 flex items-center">
              <div className="-mt-4">
                <PrettyLink href="/">
                  <img
                    src={staticPath.assets.logo_png}
                    width={142}
                    height={30}
                    alt="giff letter"
                    decoding="async"
                    loading="lazy"
                  />
                </PrettyLink>
                <p className="mt-3 text-sm text-subtext">
                  Copyright© pamxy inc
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[51px]">
          <div className="fixed inset-x-0 bottom-0 flex h-bottom-menu-height border-t border-border bg-white">
            {spMenuItems.map(({ path, Icon }) => (
              <MenuLink href={path} key={path}>
                <Icon active={router.asPath === path} />
              </MenuLink>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
