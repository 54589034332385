type Props = {
  active: boolean;
};

export const UserIcon: React.FC<Props> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8ZM14.7424 13.338C16.6769 12.3421 18 10.3256 18 8C18 4.68629 15.3137 2 12 2C8.68629 2 6 4.68629 6 8C6 10.3256 7.32308 12.3421 9.25756 13.338C7.12975 13.8894 5.50418 15.0577 4.34154 16.4108C2.75715 18.2547 2 20.4799 2 22H4C4 21.0201 4.54285 19.2453 5.85846 17.7142C7.1391 16.2238 9.12171 15 12 15C14.8783 15 16.8609 16.2238 18.1415 17.7142C19.4571 19.2453 20 21.0201 20 22H22C22 20.4799 21.2429 18.2547 19.6585 16.4108C18.4958 15.0577 16.8703 13.8894 14.7424 13.338Z"
        fill={active ? "#D0576B" : "#BAB7B7"}
      />
    </svg>
  );
};
