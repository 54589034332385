import type { OptionalQuery as OptionalQuery0 } from '../pages/brands'
import type { OptionalQuery as OptionalQuery1 } from '../pages/email-verification'
import type { OptionalQuery as OptionalQuery2 } from '../pages/items'
import type { OptionalQuery as OptionalQuery3 } from '../pages/items/[id]'
import type { OptionalQuery as OptionalQuery4 } from '../pages/signin'
import type { OptionalQuery as OptionalQuery5 } from '../pages/signin/email'
import type { OptionalQuery as OptionalQuery6 } from '../pages/signup'
import type { OptionalQuery as OptionalQuery7 } from '../pages/signup/email'
import type { OptionalQuery as OptionalQuery8 } from '../pages/signup/social'

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "anniversaries": {
    _anniversaryRepetitionId: (anniversaryRepetitionId: string | number) => ({
      _dateId: (dateId: string | number) => ({
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/anniversaries/[anniversaryRepetitionId]/[dateId]/edit' as const, query: { anniversaryRepetitionId, dateId }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/anniversaries/[anniversaryRepetitionId]/[dateId]' as const, query: { anniversaryRepetitionId, dateId }, hash: url?.hash })
      })
    }),
    "create": {
      $url: (url?: { hash?: string }) => ({ pathname: '/anniversaries/create' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/anniversaries' as const, hash: url?.hash })
  },
  "articles": {
    "haha_no_hi_2023": {
      $url: (url?: { hash?: string }) => ({ pathname: '/articles/haha-no-hi-2023' as const, hash: url?.hash })
    },
    "how_to_use_giff_letter": {
      $url: (url?: { hash?: string }) => ({ pathname: '/articles/how-to-use-giff-letter' as const, hash: url?.hash })
    }
  },
  "brands": {
    _brandName: (brandName: string | number) => ({
      "edit": {
        $url: (url?: { hash?: string }) => ({ pathname: '/brands/[brandName]/edit' as const, query: { brandName }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/brands/[brandName]' as const, query: { brandName }, hash: url?.hash })
    }),
    "create": {
      $url: (url?: { hash?: string }) => ({ pathname: '/brands/create' as const, hash: url?.hash })
    },
    $url: (url?: { query?: OptionalQuery0, hash?: string }) => ({ pathname: '/brands' as const, query: url?.query, hash: url?.hash })
  },
  "cart": {
    $url: (url?: { hash?: string }) => ({ pathname: '/cart' as const, hash: url?.hash })
  },
  "company": {
    $url: (url?: { hash?: string }) => ({ pathname: '/company' as const, hash: url?.hash })
  },
  "deactivate": {
    "completed": {
      $url: (url?: { hash?: string }) => ({ pathname: '/deactivate/completed' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/deactivate' as const, hash: url?.hash })
  },
  "delivery_infos_csv_export": {
    $url: (url?: { hash?: string }) => ({ pathname: '/delivery-infos-csv-export' as const, hash: url?.hash })
  },
  "email_verification": {
    $url: (url?: { query?: OptionalQuery1, hash?: string }) => ({ pathname: '/email-verification' as const, query: url?.query, hash: url?.hash })
  },
  "faq": {
    $url: (url?: { hash?: string }) => ({ pathname: '/faq' as const, hash: url?.hash })
  },
  "follow_requests": {
    $url: (url?: { hash?: string }) => ({ pathname: '/follow_requests' as const, hash: url?.hash })
  },
  "g": {
    _id: (id: string | number) => ({
      "consignee": {
        $url: (url?: { hash?: string }) => ({ pathname: '/g/[id]/consignee' as const, query: { id }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/g/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  "gifts": {
    "complete": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/gifts/complete/[id]' as const, query: { id }, hash: url?.hash })
      })
    },
    "confirm": {
      $url: (url?: { hash?: string }) => ({ pathname: '/gifts/confirm' as const, hash: url?.hash })
    },
    "items": {
      $url: (url?: { hash?: string }) => ({ pathname: '/gifts/items' as const, hash: url?.hash })
    },
    "letters": {
      $url: (url?: { hash?: string }) => ({ pathname: '/gifts/letters' as const, hash: url?.hash })
    },
    "new": {
      $url: (url?: { hash?: string }) => ({ pathname: '/gifts/new' as const, hash: url?.hash })
    },
    "order": {
      $url: (url?: { hash?: string }) => ({ pathname: '/gifts/order' as const, hash: url?.hash })
    }
  },
  "i": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/i/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  "inquiry": {
    "completed": {
      $url: (url?: { hash?: string }) => ({ pathname: '/inquiry/completed' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/inquiry' as const, hash: url?.hash })
  },
  "items": {
    _id: (id: string | number) => ({
      "edit": {
        $url: (url?: { hash?: string }) => ({ pathname: '/items/[id]/edit' as const, query: { id }, hash: url?.hash })
      },
      $url: (url?: { query?: OptionalQuery3, hash?: string }) => ({ pathname: '/items/[id]' as const, query: { id, ...url?.query }, hash: url?.hash })
    }),
    "create": {
      $url: (url?: { hash?: string }) => ({ pathname: '/items/create' as const, hash: url?.hash })
    },
    $url: (url?: { query?: OptionalQuery2, hash?: string }) => ({ pathname: '/items' as const, query: url?.query, hash: url?.hash })
  },
  "likes": {
    $url: (url?: { hash?: string }) => ({ pathname: '/likes' as const, hash: url?.hash })
  },
  "menu": {
    $url: (url?: { hash?: string }) => ({ pathname: '/menu' as const, hash: url?.hash })
  },
  "mypage": {
    $url: (url?: { hash?: string }) => ({ pathname: '/mypage' as const, hash: url?.hash })
  },
  "notifications": {
    $url: (url?: { hash?: string }) => ({ pathname: '/notifications' as const, hash: url?.hash })
  },
  "password": {
    "reset": {
      $url: (url?: { hash?: string }) => ({ pathname: '/password/reset' as const, hash: url?.hash })
    }
  },
  "privacy_policy": {
    $url: (url?: { hash?: string }) => ({ pathname: '/privacy-policy' as const, hash: url?.hash })
  },
  "profile": {
    "edit": {
      $url: (url?: { hash?: string }) => ({ pathname: '/profile/edit' as const, hash: url?.hash })
    }
  },
  "settings": {
    "consignees": {
      $url: (url?: { hash?: string }) => ({ pathname: '/settings/consignees' as const, hash: url?.hash })
    },
    "email": {
      $url: (url?: { hash?: string }) => ({ pathname: '/settings/email' as const, hash: url?.hash })
    },
    "notifications": {
      "email": {
        $url: (url?: { hash?: string }) => ({ pathname: '/settings/notifications/email' as const, hash: url?.hash })
      },
      "push": {
        $url: (url?: { hash?: string }) => ({ pathname: '/settings/notifications/push' as const, hash: url?.hash })
      }
    },
    "password": {
      $url: (url?: { hash?: string }) => ({ pathname: '/settings/password' as const, hash: url?.hash })
    },
    "payment_methods": {
      $url: (url?: { hash?: string }) => ({ pathname: '/settings/payment-methods' as const, hash: url?.hash })
    },
    "privacy": {
      "account": {
        $url: (url?: { hash?: string }) => ({ pathname: '/settings/privacy/account' as const, hash: url?.hash })
      },
      "blocked_users": {
        $url: (url?: { hash?: string }) => ({ pathname: '/settings/privacy/blocked-users' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/settings/privacy' as const, hash: url?.hash })
    },
    "purchases": {
      $url: (url?: { hash?: string }) => ({ pathname: '/settings/purchases' as const, hash: url?.hash })
    }
  },
  "signin": {
    "email": {
      $url: (url?: { query?: OptionalQuery5, hash?: string }) => ({ pathname: '/signin/email' as const, query: url?.query, hash: url?.hash })
    },
    $url: (url?: { query?: OptionalQuery4, hash?: string }) => ({ pathname: '/signin' as const, query: url?.query, hash: url?.hash })
  },
  "signout": {
    $url: (url?: { hash?: string }) => ({ pathname: '/signout' as const, hash: url?.hash })
  },
  "signup": {
    "email": {
      $url: (url?: { query?: OptionalQuery7, hash?: string }) => ({ pathname: '/signup/email' as const, query: url?.query, hash: url?.hash })
    },
    "social": {
      $url: (url?: { query?: OptionalQuery8, hash?: string }) => ({ pathname: '/signup/social' as const, query: url?.query, hash: url?.hash })
    },
    $url: (url?: { query?: OptionalQuery6, hash?: string }) => ({ pathname: '/signup' as const, query: url?.query, hash: url?.hash })
  },
  "stores": {
    _id: (id: string | number) => ({
      "edit": {
        $url: (url?: { hash?: string }) => ({ pathname: '/stores/[id]/edit' as const, query: { id }, hash: url?.hash })
      }
    }),
    "create": {
      $url: (url?: { hash?: string }) => ({ pathname: '/stores/create' as const, hash: url?.hash })
    }
  },
  "tokutei": {
    $url: (url?: { hash?: string }) => ({ pathname: '/tokutei' as const, hash: url?.hash })
  },
  "tos": {
    $url: (url?: { hash?: string }) => ({ pathname: '/tos' as const, hash: url?.hash })
  },
  "u": {
    _userName: (userName: string | number) => ({
      "followees": {
        $url: (url?: { hash?: string }) => ({ pathname: '/u/[userName]/followees' as const, query: { userName }, hash: url?.hash })
      },
      "followers": {
        $url: (url?: { hash?: string }) => ({ pathname: '/u/[userName]/followers' as const, query: { userName }, hash: url?.hash })
      },
      "wishes": {
        $url: (url?: { hash?: string }) => ({ pathname: '/u/[userName]/wishes' as const, query: { userName }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/u/[userName]' as const, query: { userName }, hash: url?.hash })
    })
  },
  "user_search": {
    $url: (url?: { hash?: string }) => ({ pathname: '/user-search' as const, hash: url?.hash })
  },
  "wishes": {
    $url: (url?: { hash?: string }) => ({ pathname: '/wishes' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath

export const staticPath = {
  android_chrome_192x192_png: '/android-chrome-192x192.png',
  android_chrome_256x256_png: '/android-chrome-256x256.png',
  apple_touch_icon_png: '/apple-touch-icon.png',
  assets: {
    app_icon_jpg: '/assets/app-icon.jpg',
    articles: {
      haha_no_hi_2023: {
        $01_jpg: '/assets/articles/haha-no-hi-2023/01.jpg',
        $13_jpg: '/assets/articles/haha-no-hi-2023/13.jpg',
        banner_jpg: '/assets/articles/haha-no-hi-2023/banner.jpg',
        オイル_jpg: '/assets/articles/haha-no-hi-2023/オイル.jpg',
        キャンドル_png: '/assets/articles/haha-no-hi-2023/キャンドル.png',
        クレンズリフト_png: '/assets/articles/haha-no-hi-2023/クレンズリフト.png',
        ネストボード_png: '/assets/articles/haha-no-hi-2023/ネストボード.png',
        パーツケアギフト_png: '/assets/articles/haha-no-hi-2023/パーツケアギフト.png',
        ヒマラヤバスソルトレッド_jpg: '/assets/articles/haha-no-hi-2023/ヒマラヤバスソルトレッド.jpg',
        マカロン_png: '/assets/articles/haha-no-hi-2023/マカロン.png',
        ミニブーケ_png: '/assets/articles/haha-no-hi-2023/ミニブーケ.png',
        ラスク屋さん_png: '/assets/articles/haha-no-hi-2023/ラスク屋さん.png',
        ラッピング袋_jpg: '/assets/articles/haha-no-hi-2023/ラッピング袋.jpg',
        ローズラボ_png: '/assets/articles/haha-no-hi-2023/ローズラボ.png',
        焼肉食べ比べセット_png: '/assets/articles/haha-no-hi-2023/焼肉食べ比べセット.png'
      },
      how_to_use_giff_letter: {
        $01_jpg: '/assets/articles/how-to-use-giff-letter/01.jpg',
        $01_02_png: '/assets/articles/how-to-use-giff-letter/01_02.png',
        $02_jpg: '/assets/articles/how-to-use-giff-letter/02.jpg',
        $03_jpg: '/assets/articles/how-to-use-giff-letter/03.jpg',
        $04_jpg: '/assets/articles/how-to-use-giff-letter/04.jpg',
        $05_jpg: '/assets/articles/how-to-use-giff-letter/05.jpg',
        $06_jpg: '/assets/articles/how-to-use-giff-letter/06.jpg',
        $07_jpg: '/assets/articles/how-to-use-giff-letter/07.jpg',
        $08_jpg: '/assets/articles/how-to-use-giff-letter/08.jpg',
        $09_jpg: '/assets/articles/how-to-use-giff-letter/09.jpg',
        $10_jpg: '/assets/articles/how-to-use-giff-letter/10.jpg',
        $11_jpg: '/assets/articles/how-to-use-giff-letter/11.jpg',
        $12_jpg: '/assets/articles/how-to-use-giff-letter/12.jpg',
        $13_jpg: '/assets/articles/how-to-use-giff-letter/13.jpg',
        $14_jpg: '/assets/articles/how-to-use-giff-letter/14.jpg',
        banner_png: '/assets/articles/how-to-use-giff-letter/banner.png'
      }
    },
    delivery_truck_png: '/assets/delivery-truck.png',
    donwload_app_2_png: '/assets/donwload-app-2.png',
    download_app_jpg: '/assets/download-app.jpg',
    e_gift_illust_png: '/assets/e-gift-illust.png',
    gift_ogp_png: '/assets/gift-ogp.png',
    google_play_store_png: '/assets/google-play-store.png',
    letter_bg_png: '/assets/letter-bg.png',
    logo_png: '/assets/logo.png',
    logo_svg: '/assets/logo.svg',
    ogp_png: '/assets/ogp.png',
    public_sample_png: '/assets/public-sample.png',
    relations: {
      boyfriend_png: '/assets/relations/boyfriend.png',
      daughter_png: '/assets/relations/daughter.png',
      father_in_law_png: '/assets/relations/father-in-law.png',
      father_png: '/assets/relations/father.png',
      female_baby_png: '/assets/relations/female-baby.png',
      female_client_png: '/assets/relations/female-client.png',
      female_coworker_png: '/assets/relations/female-coworker.png',
      female_friend_png: '/assets/relations/female-friend.png',
      female_junior_png: '/assets/relations/female-junior.png',
      female_senior_png: '/assets/relations/female-senior.png',
      girlfriend_png: '/assets/relations/girlfriend.png',
      grandfather_png: '/assets/relations/grandfather.png',
      grandmother_png: '/assets/relations/grandmother.png',
      husband_png: '/assets/relations/husband.png',
      male_baby_png: '/assets/relations/male-baby.png',
      male_client_png: '/assets/relations/male-client.png',
      male_coworker_png: '/assets/relations/male-coworker.png',
      male_friend_png: '/assets/relations/male-friend.png',
      male_junior_png: '/assets/relations/male-junior.png',
      male_senior_png: '/assets/relations/male-senior.png',
      mother_in_law_png: '/assets/relations/mother-in-law.png',
      mother_png: '/assets/relations/mother.png',
      nephew_png: '/assets/relations/nephew.png',
      niece_png: '/assets/relations/niece.png',
      older_brother_png: '/assets/relations/older-brother.png',
      older_sister_png: '/assets/relations/older-sister.png',
      others_png: '/assets/relations/others.png',
      partner_png: '/assets/relations/partner.png',
      pet_png: '/assets/relations/pet.png',
      relatives_png: '/assets/relations/relatives.png',
      son_png: '/assets/relations/son.png',
      wife_png: '/assets/relations/wife.png',
      younger_brother_png: '/assets/relations/younger-brother.png',
      younger_sister_png: '/assets/relations/younger-sister.png'
    },
    saving_gift_png: '/assets/saving-gift.png',
    secret_gift_jpg: '/assets/secret-gift.jpg',
    secret_sample_png: '/assets/secret-sample.png'
  },
  browserconfig_xml: '/browserconfig.xml',
  favicon_16x16_png: '/favicon-16x16.png',
  favicon_32x32_png: '/favicon-32x32.png',
  favicon_ico: '/favicon.ico',
  mstile_150x150_png: '/mstile-150x150.png',
  safari_pinned_tab_svg: '/safari-pinned-tab.svg',
  site_webmanifest: '/site.webmanifest'
} as const

export type StaticPath = typeof staticPath
