import gql from "graphql-tag";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: unknown;
};

export type AddCreditCardInput = {
  readonly cvc: Scalars["String"];
  readonly expMonth: Scalars["Int"];
  readonly expYear: Scalars["Int"];
  readonly number: Scalars["String"];
};

export type AddCreditCardResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly creditCard: Maybe<CreditCard>;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type AddFcmRegistrationTokenResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type AddGiftDraftSkuInput = {
  readonly optionItemIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
};

export type AddGiftDraftSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type Allergy = {
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type Anniversary = {
  readonly anniversaryUser: AnniversaryUser;
  readonly id: Scalars["ID"];
  readonly repetitions: ReadonlyArray<AnniversaryRepetition>;
};

export const AnniversaryFrequency = {
  Monthly: "MONTHLY",
  None: "NONE",
  Yearly: "YEARLY",
} as const;

export type AnniversaryFrequency =
  (typeof AnniversaryFrequency)[keyof typeof AnniversaryFrequency];
export const AnniversaryRemindTiming = {
  BeforeOneDay: "BEFORE_ONE_DAY",
  BeforeOneWeek: "BEFORE_ONE_WEEK",
  ThatDay: "THAT_DAY",
} as const;

export type AnniversaryRemindTiming =
  (typeof AnniversaryRemindTiming)[keyof typeof AnniversaryRemindTiming];
export type AnniversaryRepetition = {
  readonly anniversary: Anniversary;
  readonly frequency: AnniversaryFrequency;
  readonly id: Scalars["ID"];
  readonly memo: Scalars["String"];
  readonly name: Scalars["String"];
  readonly private: Scalars["Boolean"];
  readonly recommendedItems: RecommendedItemsConnection;
  readonly remindTimings: ReadonlyArray<AnniversaryRemindTiming>;
  readonly start: Date;
  readonly type: AnniversaryType;
  readonly until: Maybe<Date>;
};

export type AnniversaryRepetitionRecommendedItemsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type AnniversaryRepetitionInput = {
  readonly frequency: AnniversaryFrequency;
  readonly memo: Scalars["String"];
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly private?: InputMaybe<Scalars["Boolean"]>;
  readonly remindTimings: ReadonlyArray<AnniversaryRemindTiming>;
  readonly start: DateInput;
  readonly type: AnniversaryType;
  readonly until?: InputMaybe<DateInput>;
};

export const AnniversaryType = {
  Birthday: "BIRTHDAY",
  BirthCelebration: "BIRTH_CELEBRATION",
  ChichiNoHi: "CHICHI_NO_HI",
  Christmas: "CHRISTMAS",
  HahaNoHi: "HAHA_NO_HI",
  IiFufuNoHi: "II_FUFU_NO_HI",
  KeiroNoHi: "KEIRO_NO_HI",
  Others: "OTHERS",
  PrivateCelebration: "PRIVATE_CELEBRATION",
  ValentineDay: "VALENTINE_DAY",
  Wedding: "WEDDING",
  WhiteDay: "WHITE_DAY",
} as const;

export type AnniversaryType =
  (typeof AnniversaryType)[keyof typeof AnniversaryType];
export type AnniversaryUser = {
  readonly id: Scalars["ID"];
  readonly isDirty: Scalars["Boolean"];
  readonly name: Scalars["String"];
  readonly relation: AnniversaryUserRelation;
  readonly user: Maybe<User>;
};

export type AnniversaryUserEdge = {
  readonly cursor: Scalars["String"];
  readonly node: AnniversaryUser;
};

export const AnniversaryUserRelation = {
  Boyfriend: "BOYFRIEND",
  Daughter: "DAUGHTER",
  Father: "FATHER",
  FatherInLaw: "FATHER_IN_LAW",
  FemaleBaby: "FEMALE_BABY",
  FemaleClient: "FEMALE_CLIENT",
  FemaleCoworker: "FEMALE_COWORKER",
  FemaleFriend: "FEMALE_FRIEND",
  FemaleJunior: "FEMALE_JUNIOR",
  FemaleSenior: "FEMALE_SENIOR",
  Girlfriend: "GIRLFRIEND",
  Grandfather: "GRANDFATHER",
  Grandmother: "GRANDMOTHER",
  Husband: "HUSBAND",
  MaleBaby: "MALE_BABY",
  MaleClient: "MALE_CLIENT",
  MaleCoworker: "MALE_COWORKER",
  MaleFriend: "MALE_FRIEND",
  MaleJunior: "MALE_JUNIOR",
  MaleSenior: "MALE_SENIOR",
  Mother: "MOTHER",
  MotherInLaw: "MOTHER_IN_LAW",
  Nephew: "NEPHEW",
  Niece: "NIECE",
  OlderBrother: "OLDER_BROTHER",
  OlderSister: "OLDER_SISTER",
  Others: "OTHERS",
  Partner: "PARTNER",
  Pet: "PET",
  Relatives: "RELATIVES",
  Son: "SON",
  Wife: "WIFE",
  YoungerBrother: "YOUNGER_BROTHER",
  YoungerSister: "YOUNGER_SISTER",
} as const;

export type AnniversaryUserRelation =
  (typeof AnniversaryUserRelation)[keyof typeof AnniversaryUserRelation];
export type AnniversaryUsersConnection = {
  readonly edges: ReadonlyArray<AnniversaryUserEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type ApproveFollowRequestResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
  readonly user: Maybe<User>;
};

export type Area = {
  readonly id: Scalars["ID"];
  readonly municipalities: ReadonlyArray<Municipality>;
  readonly name: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type AreasInput = {
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly prefectureId?: InputMaybe<Scalars["ID"]>;
};

export type AttachPromotionCodeResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export const AvailableRegion = {
  Limited: "LIMITED",
  Nationwide: "NATIONWIDE",
} as const;

export type AvailableRegion =
  (typeof AvailableRegion)[keyof typeof AvailableRegion];
export type Banner = {
  readonly bannerType: BannerType;
  readonly id: Scalars["ID"];
  readonly image: Image;
  readonly url: Scalars["String"];
};

export const BannerType = {
  Article: "ARTICLE",
  ForFemaleFriends: "FOR_FEMALE_FRIENDS",
  PopularSweets: "POPULAR_SWEETS",
} as const;

export type BannerType = (typeof BannerType)[keyof typeof BannerType];
export type BlockUserResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
  readonly user: Maybe<User>;
};

export type BlockedUsersConnection = {
  readonly edges: ReadonlyArray<UserEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type Brand = {
  readonly alterImage: Maybe<Image>;
  readonly brandName: Scalars["String"];
  readonly description: Scalars["String"];
  readonly displayName: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly items: BrandItemsConnection;
  readonly logoImage: Maybe<Image>;
  readonly pinCodes: ReadonlyArray<Scalars["String"]>;
  readonly priority: Scalars["Int"];
  readonly stores: ReadonlyArray<Store>;
  readonly visibility: BrandVisibility;
};

export type BrandItemsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type BrandEdge = {
  readonly cursor: Scalars["String"];
  readonly node: Brand;
};

export type BrandItemsConnection = {
  readonly edges: ReadonlyArray<ItemEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export const BrandVisibility = {
  Limited: "LIMITED",
  Public: "PUBLIC",
} as const;

export type BrandVisibility =
  (typeof BrandVisibility)[keyof typeof BrandVisibility];
export type BrandsConnection = {
  readonly edges: ReadonlyArray<BrandEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type BrandsInput = {
  readonly q?: InputMaybe<Scalars["String"]>;
};

export type CancelFollowRequestResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
  readonly user: Maybe<User>;
};

export type CardDesign = {
  readonly id: Scalars["ID"];
  readonly image: Image;
  readonly name: Scalars["String"];
  readonly scene: CardDesignScene;
};

export type CardDesignScene = {
  readonly cardDesigns: ReadonlyArray<CardDesign>;
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type CardDesignsInput = {
  readonly sceneId?: InputMaybe<Scalars["ID"]>;
};

export type CheckItemResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly item: Maybe<Item>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type ConfirmGiftConsigneeResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly gift: Maybe<Gift>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type Consignee = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type CreateAnniversaryInput = {
  readonly anniversaryUserId: Scalars["ID"];
  readonly repetition: AnniversaryRepetitionInput;
};

export type CreateAnniversaryResponse = MutationResponse & {
  readonly anniversary: Maybe<Anniversary>;
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type CreateAnniversaryUserInput = {
  readonly name: Scalars["String"];
  readonly relation: AnniversaryUserRelation;
};

export type CreateAnniversaryUserResponse = MutationResponse & {
  readonly anniversaryUser: Maybe<AnniversaryUser>;
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type CreateBrandInput = {
  readonly alterImageId?: InputMaybe<Scalars["ID"]>;
  readonly brandName: Scalars["String"];
  readonly description: Scalars["String"];
  readonly displayName: Scalars["String"];
  readonly logoImageId?: InputMaybe<Scalars["ID"]>;
  readonly visibility: BrandVisibility;
};

export type CreateBrandResponse = MutationResponse & {
  readonly brand: Maybe<Brand>;
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type CreateConsigneeInput = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefectureId: Scalars["ID"];
};

export type CreateConsigneeResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly consignee: Maybe<Consignee>;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type CreateItemInput = {
  readonly allergyIds: ReadonlyArray<Scalars["ID"]>;
  readonly attention: Scalars["String"];
  readonly availableRegion: AvailableRegion;
  readonly brandId: Scalars["ID"];
  readonly description: DescriptionInput;
  readonly expireAfter: Scalars["Int"];
  readonly expireTimeUnit: ExpireTimeUnit;
  readonly imageIds: ReadonlyArray<Scalars["ID"]>;
  readonly itemCategoryId: Scalars["String"];
  readonly name: Scalars["String"];
  readonly onSale: Scalars["Boolean"];
  readonly priority: Scalars["Int"];
  readonly receivingWay: ReceivingWay;
  readonly reconciliationWay?: InputMaybe<ReconciliationWay>;
  readonly relations: ReadonlyArray<AnniversaryUserRelation>;
  readonly sceneIds: ReadonlyArray<Scalars["ID"]>;
  readonly shortUsage: Scalars["String"];
  readonly usageExplanations: ReadonlyArray<Scalars["String"]>;
};

export type CreateItemResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly item: Maybe<Item>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type CreateShipperInput = {
  readonly name: Scalars["String"];
};

export type CreateShipperResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly shipper: Maybe<Shipper>;
  readonly success: Scalars["Boolean"];
};

export type CreateSkuGroupInput = {
  readonly itemId: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type CreateSkuGroupResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly skuGroup: Maybe<SkuGroup>;
  readonly success: Scalars["Boolean"];
};

export type CreateSkuGroupValueInput = {
  readonly groupId: Scalars["ID"];
  readonly id?: InputMaybe<Scalars["ID"]>;
  readonly imageId?: InputMaybe<Scalars["ID"]>;
  readonly name: Scalars["String"];
};

export type CreateSkuGroupValueResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly skuGroupValue: Maybe<SkuGroupValue>;
  readonly success: Scalars["Boolean"];
};

export type CreateSkuInput = {
  readonly defaultShippingCost?: InputMaybe<Scalars["Int"]>;
  readonly itemId: Scalars["ID"];
  readonly onSale: Scalars["Boolean"];
  readonly price: Scalars["Int"];
  readonly shipperId?: InputMaybe<Scalars["ID"]>;
  readonly shippingCosts?: InputMaybe<ReadonlyArray<ShippingCostInput>>;
  readonly skuCode?: InputMaybe<Scalars["String"]>;
  readonly skuGroupValue1Id?: InputMaybe<Scalars["ID"]>;
  readonly skuGroupValue2Id?: InputMaybe<Scalars["ID"]>;
  readonly stockCount: Scalars["Int"];
  readonly taxRate: Scalars["Int"];
};

export type CreateSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type CreateStoreInput = {
  readonly address: Scalars["String"];
  readonly brandId: Scalars["String"];
  readonly businessHours: Scalars["String"];
  readonly googleMapSrc: Scalars["String"];
  readonly municipalityIds: ReadonlyArray<Scalars["ID"]>;
  readonly name: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly transportation: Scalars["String"];
};

export type CreateStoreResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly store: Maybe<Store>;
  readonly success: Scalars["Boolean"];
};

export type CreditCard = {
  readonly brand: Scalars["String"];
  readonly expMonth: Scalars["Int"];
  readonly expYear: Scalars["Int"];
  readonly id: Scalars["ID"];
  readonly last4: Scalars["String"];
};

export type Customer = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type Date = {
  readonly day: Scalars["Int"];
  readonly month: Scalars["Int"];
  readonly year: Scalars["Int"];
};

export type DateInput = {
  readonly day: Scalars["Int"];
  readonly month: Scalars["Int"];
  readonly year: Scalars["Int"];
};

export type DeactivateInput = {
  readonly comment: Scalars["String"];
  readonly reason: Scalars["String"];
};

export type DeactivateResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DecreaseGiftDraftSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DeleteAnniversaryResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DeleteAnniversaryUserResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DeleteBrandResponse = MutationResponse & {
  readonly brand: Maybe<Brand>;
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DeleteConsigneeResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DeleteCustomerResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DeleteGiftDraftSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DeleteItemResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly item: Maybe<Item>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DeleteStoreResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly store: Maybe<Store>;
  readonly success: Scalars["Boolean"];
};

export type DeliveryInfo = {
  readonly consignee: GiftConsignee;
  readonly createdAt: Scalars["String"];
  readonly deliveryDate: Maybe<Scalars["String"]>;
  readonly deliveryTime: Maybe<Scalars["String"]>;
  readonly gift: Gift;
  readonly giftDelivery: GiftDelivery;
  readonly id: Scalars["ID"];
  readonly purchasePrice: Scalars["Int"];
  readonly sku: Sku;
  readonly skuOptionItems: ReadonlyArray<SkuOptionItem>;
};

export type DeliveryInfosInput = {
  readonly since: Scalars["String"];
  readonly test: Scalars["Boolean"];
  readonly until: Scalars["String"];
};

export const DeliveryMethod = {
  EGift: "E_GIFT",
  ShipToMe: "SHIP_TO_ME",
  ShipToOutsider: "SHIP_TO_OUTSIDER",
} as const;

export type DeliveryMethod =
  (typeof DeliveryMethod)[keyof typeof DeliveryMethod];
export type DeliveryOption = {
  readonly deliveryOptionItems: ReadonlyArray<DeliveryOptionItem>;
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type DeliveryOptionItem = {
  readonly attention: Scalars["String"];
  readonly deliveryOption: DeliveryOption;
  readonly description: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly image: Image;
  readonly name: Scalars["String"];
  readonly onSale: Scalars["Boolean"];
  readonly price: Scalars["Int"];
  readonly shipper: Shipper;
  readonly stockCount: Scalars["Int"];
};

export type DeliveryTimesInput = {
  readonly giftDeliveryId?: InputMaybe<Scalars["ID"]>;
  readonly giftDraftDeliveryId?: InputMaybe<Scalars["ID"]>;
};

export type Description = {
  readonly renderers: ReadonlyArray<DescriptionRenderer>;
};

export type DescriptionImageRenderer = {
  readonly height: Scalars["Int"];
  readonly type: DescriptionRendererType;
  readonly url: Scalars["String"];
  readonly width: Scalars["Int"];
};

export type DescriptionImageRendererInput = {
  readonly height: Scalars["Int"];
  readonly type: DescriptionRendererType;
  readonly url: Scalars["String"];
  readonly width: Scalars["Int"];
};

export type DescriptionInput = {
  readonly renderers: ReadonlyArray<DescriptionRendererInput>;
};

export type DescriptionRenderer =
  | DescriptionImageRenderer
  | DescriptionTextRenderer;

export type DescriptionRendererInput = {
  readonly image?: InputMaybe<DescriptionImageRendererInput>;
  readonly text?: InputMaybe<DescriptionTextRendererInput>;
  readonly type: DescriptionRendererType;
};

export const DescriptionRendererType = {
  Image: "IMAGE",
  Text: "TEXT",
} as const;

export type DescriptionRendererType =
  (typeof DescriptionRendererType)[keyof typeof DescriptionRendererType];
export type DescriptionTextRenderer = {
  readonly color: DescriptionTextRendererColor;
  readonly size: DescriptionTextRendererSize;
  readonly text: Scalars["String"];
  readonly type: DescriptionRendererType;
  readonly weight: DescriptionTextRendererWeight;
};

export const DescriptionTextRendererColor = {
  Brand: "BRAND",
  Subtext: "SUBTEXT",
  Text: "TEXT",
} as const;

export type DescriptionTextRendererColor =
  (typeof DescriptionTextRendererColor)[keyof typeof DescriptionTextRendererColor];
export type DescriptionTextRendererInput = {
  readonly color: DescriptionTextRendererColor;
  readonly size: DescriptionTextRendererSize;
  readonly text: Scalars["String"];
  readonly type: DescriptionRendererType;
  readonly weight: DescriptionTextRendererWeight;
};

export const DescriptionTextRendererSize = {
  Large: "LARGE",
  Medium: "MEDIUM",
  Small: "SMALL",
} as const;

export type DescriptionTextRendererSize =
  (typeof DescriptionTextRendererSize)[keyof typeof DescriptionTextRendererSize];
export const DescriptionTextRendererWeight = {
  Bold: "BOLD",
  Normal: "NORMAL",
} as const;

export type DescriptionTextRendererWeight =
  (typeof DescriptionTextRendererWeight)[keyof typeof DescriptionTextRendererWeight];
export type DetachPromotionCodeResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type DynamicLinkInput = {
  readonly fallbackToLink: Scalars["Boolean"];
  readonly link: Scalars["String"];
};

export type EmailAvailabilityResponse = {
  readonly available: Scalars["Boolean"];
};

export const EmailNotificationType = {
  AnniversaryReminder: "ANNIVERSARY_REMINDER",
  FollowRequestApproved: "FOLLOW_REQUEST_APPROVED",
  GiftReminder: "GIFT_REMINDER",
  Newsletter: "NEWSLETTER",
  NewFollower: "NEW_FOLLOWER",
  Restock: "RESTOCK",
} as const;

export type EmailNotificationType =
  (typeof EmailNotificationType)[keyof typeof EmailNotificationType];
export const ExpireTimeUnit = {
  Days: "DAYS",
  Months: "MONTHS",
} as const;

export type ExpireTimeUnit =
  (typeof ExpireTimeUnit)[keyof typeof ExpireTimeUnit];
export type FollowUserResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
  readonly user: Maybe<User>;
};

export type Gift = {
  readonly cardDesign: Maybe<CardDesign>;
  readonly consignee: Maybe<GiftConsignee>;
  readonly consigneeDraft: Maybe<GiftConsigneeDraft>;
  readonly customer: Maybe<GiftCustomer>;
  readonly deliveryMethod: Maybe<DeliveryMethod>;
  readonly giftDeliveries: Maybe<ReadonlyArray<GiftDelivery>>;
  readonly giftFor: GiftFor;
  readonly giftSkus: ReadonlyArray<GiftSku>;
  readonly gifteeName: Scalars["String"];
  readonly gifter: User;
  readonly gifterName: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly itemsAmount: Scalars["Int"];
  readonly message: Scalars["String"];
  readonly optionsAmount: Maybe<OptionsAmount>;
  readonly orderNumber: Scalars["String"];
  readonly payment: Maybe<Payment>;
  readonly promotionCodeAmount: Maybe<Scalars["Int"]>;
  readonly purchaseDate: Scalars["String"];
  readonly savedAt: Maybe<Scalars["String"]>;
  readonly secret: Scalars["Boolean"];
  readonly shipFrom: Maybe<ShipFrom>;
  readonly shipFromName: Maybe<Scalars["String"]>;
  readonly shippingCostsAmount: Maybe<PurchasedShippingCostsAmount>;
  readonly totalAmount: Scalars["Int"];
  readonly url: Scalars["String"];
};

export type GiftConsignee = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly gift: Gift;
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type GiftConsigneeDraft = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly gift: Gift;
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type GiftConsigneeInput = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefectureId: Scalars["ID"];
};

export type GiftCustomer = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly gift: Gift;
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type GiftCustomerInput = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefectureId: Scalars["ID"];
};

export type GiftDelivery = {
  readonly deliveryDate: Scalars["String"];
  readonly deliveryDateDraft: Scalars["String"];
  readonly deliveryOptionItems: ReadonlyArray<DeliveryOptionItem>;
  readonly deliveryTime: Scalars["String"];
  readonly deliveryTimeDraft: Scalars["String"];
  readonly gift: Gift;
  readonly id: Scalars["ID"];
  readonly purchaseOptionsPrice: Maybe<Scalars["Int"]>;
  readonly purchaseShippingCost: Scalars["Int"];
  readonly shipper: Shipper;
};

export type GiftDraft = {
  readonly abandonedGiftDraftSkus: ReadonlyArray<GiftDraftSku>;
  readonly anonymousCustomerEmail: Scalars["String"];
  readonly cardDesign: Maybe<CardDesign>;
  readonly consignee: Maybe<GiftDraftConsignee>;
  readonly creditCard: Maybe<CreditCard>;
  readonly customer: Maybe<GiftDraftCustomer>;
  readonly deliveryMethod: Maybe<DeliveryMethod>;
  readonly giftDraftDeliveries: ReadonlyArray<GiftDraftDelivery>;
  readonly giftDraftSkus: ReadonlyArray<GiftDraftSku>;
  readonly giftFor: GiftFor;
  readonly gifteeName: Scalars["String"];
  readonly gifterName: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly itemsAmount: Scalars["Int"];
  readonly message: Scalars["String"];
  readonly optionsAmount: Maybe<OptionsAmount>;
  readonly paymentMethod: PaymentMethod;
  readonly promotionCode: Maybe<Scalars["String"]>;
  readonly promotionCodeAmount: Maybe<Scalars["Int"]>;
  readonly secret: Scalars["Boolean"];
  readonly shipFrom: Maybe<ShipFrom>;
  readonly shipFromName: Maybe<Scalars["String"]>;
  readonly shippingCostsAmount: Maybe<ShippingCostsAmount>;
  readonly totalAmount: Scalars["Int"];
};

export type GiftDraftShippingCostsAmountArgs = {
  input: InputMaybe<GiftDraftShippingCostsAmountInput>;
};

export type GiftDraftTotalAmountArgs = {
  input: InputMaybe<GiftDraftTotalAmountInput>;
};

export type GiftDraftConsignee = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly originalConsigneeId: Maybe<Scalars["String"]>;
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type GiftDraftCustomer = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type GiftDraftDelivery = {
  readonly deliveryDate: Scalars["String"];
  readonly deliveryOptions: ReadonlyArray<DeliveryOption>;
  readonly deliveryTime: Scalars["String"];
  readonly giftDraft: GiftDraft;
  readonly id: Scalars["ID"];
  readonly selectedDeliveryOptionItems: ReadonlyArray<DeliveryOptionItem>;
  readonly shipper: Shipper;
};

export type GiftDraftShippingCostsAmountInput = {
  readonly deliveryMethod?: InputMaybe<DeliveryMethod>;
  readonly prefectureId?: InputMaybe<Scalars["ID"]>;
};

export type GiftDraftSku = {
  readonly id: Scalars["ID"];
  readonly optionItems: ReadonlyArray<SkuOptionItem>;
  readonly sku: Sku;
};

export type GiftDraftTotalAmountInput = {
  readonly deliveryMethod?: InputMaybe<DeliveryMethod>;
  readonly prefectureId?: InputMaybe<Scalars["ID"]>;
};

export type GiftEdge = {
  readonly cursor: Scalars["String"];
  readonly node: Gift;
};

export const GiftFor = {
  Me: "ME",
  Outsider: "OUTSIDER",
} as const;

export type GiftFor = (typeof GiftFor)[keyof typeof GiftFor];
export type GiftSku = {
  readonly customUrl: Maybe<Scalars["String"]>;
  readonly expireAt: Scalars["String"];
  readonly gift: Gift;
  readonly id: Scalars["ID"];
  readonly optionItems: ReadonlyArray<SkuOptionItem>;
  readonly order: Scalars["Int"];
  readonly purchaseOptionsPrice: Maybe<Scalars["Int"]>;
  readonly purchasePrice: Scalars["Int"];
  readonly receivingWay: ReceivingWay;
  readonly serialCode: Maybe<Scalars["String"]>;
  readonly sku: Sku;
  readonly usedAt: Maybe<Scalars["String"]>;
};

export type GiftSkuEdge = {
  readonly cursor: Scalars["String"];
  readonly node: GiftSku;
};

export type Image = {
  readonly height: Scalars["Int"];
  readonly id: Scalars["ID"];
  readonly url: Scalars["String"];
  readonly width: Scalars["Int"];
};

export type IncreaseGiftDraftSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type InquireInput = {
  readonly content: Scalars["String"];
  readonly email: Scalars["String"];
  readonly name: Scalars["String"];
  readonly type: Scalars["String"];
};

export type InquireResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type Item = {
  readonly allergies: ReadonlyArray<Allergy>;
  readonly attention: Scalars["String"];
  readonly availableRegion: AvailableRegion;
  readonly brand: Brand;
  readonly description: Description;
  readonly expireAfter: Scalars["Int"];
  readonly expireAt: Scalars["String"];
  readonly expireTimeUnit: ExpireTimeUnit;
  readonly id: Scalars["ID"];
  readonly images: ReadonlyArray<Image>;
  readonly isSkuPriceConstant: Scalars["Boolean"];
  readonly itemCategory: ItemCategory;
  readonly liked: Maybe<Scalars["Boolean"]>;
  readonly minSkuPrice: Scalars["Int"];
  readonly municipalities: ReadonlyArray<Municipality>;
  readonly name: Scalars["String"];
  readonly onSale: Scalars["Boolean"];
  readonly priority: Scalars["Int"];
  readonly receivingWay: ReceivingWay;
  readonly reconciliationWay: ReconciliationWay;
  readonly relations: ReadonlyArray<AnniversaryUserRelation>;
  readonly scenes: ReadonlyArray<ItemScene>;
  readonly shortUsage: Scalars["String"];
  readonly skus: ReadonlyArray<Sku>;
  readonly topImage: Image;
  readonly usageExplanations: ReadonlyArray<Scalars["String"]>;
  readonly wished: Maybe<Scalars["Boolean"]>;
};

export type ItemCategory = {
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly subgroup: ItemCategorySubgroup;
};

export type ItemCategoryGroup = {
  readonly iconImage: Image;
  readonly id: Scalars["ID"];
  readonly image: Image;
  readonly name: Scalars["String"];
  readonly rankingItems: ReadonlyArray<Item>;
  readonly subgroups: ReadonlyArray<ItemCategorySubgroup>;
};

export type ItemCategorySubgroup = {
  readonly categories: ReadonlyArray<ItemCategory>;
  readonly group: ItemCategoryGroup;
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type ItemEdge = {
  readonly cursor: Scalars["String"];
  readonly node: Item;
};

export type ItemScene = {
  readonly id: Scalars["ID"];
  readonly image: Image;
  readonly name: Scalars["String"];
};

export type ItemsConnection = {
  readonly edges: ReadonlyArray<ItemEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type ItemsInput = {
  readonly brandIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly excludeAllergyIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly itemCategoryIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly itemSceneIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly maxPrice?: InputMaybe<Scalars["Int"]>;
  readonly minPrice?: InputMaybe<Scalars["Int"]>;
  readonly municipalityIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly order?: InputMaybe<ItemsOrder>;
  readonly q?: InputMaybe<Scalars["String"]>;
  readonly receivingWays?: InputMaybe<ReadonlyArray<ReceivingWay>>;
  readonly relations?: InputMaybe<ReadonlyArray<AnniversaryUserRelation>>;
};

export const ItemsOrder = {
  New: "NEW",
  Popularity: "POPULARITY",
  Price: "PRICE",
} as const;

export type ItemsOrder = (typeof ItemsOrder)[keyof typeof ItemsOrder];
export type LeadTime = {
  readonly dayCount: Scalars["Int"];
  readonly id: Scalars["ID"];
  readonly prefecture: Prefecture;
};

export type LeadTimesInput = {
  readonly giftDeliveryId?: InputMaybe<Scalars["ID"]>;
  readonly giftDraftDeliveryId?: InputMaybe<Scalars["ID"]>;
};

export type LikeSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly sku: Maybe<Sku>;
  readonly success: Scalars["Boolean"];
};

export type LikesConnection = {
  readonly edges: ReadonlyArray<SkuEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type MeResponse = {
  readonly status: MeStatus;
  readonly user: Maybe<User>;
};

export const MeStatus = {
  Ok: "OK",
  TokenInvalid: "TOKEN_INVALID",
  UserNotFound: "USER_NOT_FOUND",
} as const;

export type MeStatus = (typeof MeStatus)[keyof typeof MeStatus];
export type MunicipalitiesInput = {
  readonly areaId?: InputMaybe<Scalars["ID"]>;
  readonly name?: InputMaybe<Scalars["String"]>;
};

export type Municipality = {
  readonly area: Area;
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type Mutation = {
  readonly addCreditCard: AddCreditCardResponse;
  readonly addFcmRegistrationToken: AddFcmRegistrationTokenResponse;
  readonly addGiftDraftSku: AddGiftDraftSkuResponse;
  readonly approveFollowRequest: ApproveFollowRequestResponse;
  readonly attachPromotionCode: AttachPromotionCodeResponse;
  readonly blockUser: BlockUserResponse;
  readonly cancelFollowRequest: CancelFollowRequestResponse;
  readonly checkItem: CheckItemResponse;
  readonly confirmGiftConsignee: ConfirmGiftConsigneeResponse;
  readonly createAnniversary: CreateAnniversaryResponse;
  readonly createAnniversaryUser: CreateAnniversaryUserResponse;
  readonly createBrand: CreateBrandResponse;
  readonly createConsignee: CreateConsigneeResponse;
  readonly createItem: CreateItemResponse;
  readonly createShipper: CreateShipperResponse;
  readonly createSku: CreateSkuResponse;
  readonly createSkuGroup: CreateSkuGroupResponse;
  readonly createSkuGroupValue: CreateSkuGroupValueResponse;
  readonly createStore: CreateStoreResponse;
  readonly deactivate: DeactivateResponse;
  readonly decreaseGiftDraftSku: DecreaseGiftDraftSkuResponse;
  readonly deleteAnniversary: DeleteAnniversaryResponse;
  readonly deleteAnniversaryUser: DeleteAnniversaryUserResponse;
  readonly deleteBrand: DeleteBrandResponse;
  readonly deleteConsignee: DeleteConsigneeResponse;
  readonly deleteCustomer: DeleteCustomerResponse;
  readonly deleteGiftDraftSku: DeleteGiftDraftSkuResponse;
  readonly deleteGiftDraftSkuAll: DeleteGiftDraftSkuResponse;
  readonly deleteItem: DeleteItemResponse;
  readonly deleteStore: DeleteStoreResponse;
  readonly detachPromotionCode: DetachPromotionCodeResponse;
  readonly followUser: FollowUserResponse;
  readonly increaseGiftDraftSku: IncreaseGiftDraftSkuResponse;
  readonly inquire: InquireResponse;
  readonly likeSku: LikeSkuResponse;
  readonly purchaseGift: PurchaseGiftResponse;
  readonly reconcileGiftSku: ReconcileGiftSkuResponse;
  readonly rejectFollowRequest: RejectFollowRequestResponse;
  readonly removeCreditCard: RemoveCreditCardResponse;
  readonly reportUser: ReportUserResponse;
  readonly resendPurchaseGiftEmail: ResendPurchaseGiftEmailResponse;
  readonly saveGift: SaveGiftResponse;
  readonly signUp: SignUpResponse;
  readonly transferAnniversaries: TransferAnniversariesResponse;
  readonly unblockUser: UnblockUserResponse;
  readonly unfollowUser: UnfollowUserResponse;
  readonly unlikeSku: UnlikeSkuResponse;
  readonly unwishSku: UnwishSkuResponse;
  readonly updateAnniversary: UpdateAnniversaryResponse;
  readonly updateAnniversaryUser: UpdateAnniversaryUserResponse;
  readonly updateBrand: UpdateBrandResponse;
  readonly updateConsignee: UpdateConsigneeResponse;
  readonly updateCustomer: UpdateCustomerResponse;
  readonly updateFcmRegistrationToken: UpdateFcmRegistrationTokenResponse;
  readonly updateGiftConsigneeDraft: UpdateGiftConsigneeDraftResponse;
  readonly updateGiftDelivery: UpdateGiftDeliveryResponse;
  readonly updateGiftDraft: UpdateGiftDraftResponse;
  readonly updateGiftDraftDelivery: UpdateGiftDraftDeliveryResponse;
  readonly updateGiftDraftSku: UpdateGiftDraftSkuResponse;
  readonly updateGiftOrder: UpdateGiftOrderResponse;
  readonly updateItem: UpdateItemResponse;
  readonly updateMe: UpdateMeResponse;
  readonly updateSetting: UpdateSettingResponse;
  readonly updateStore: UpdateStoreResponse;
  readonly uploadImage: UploadImageResponse;
  readonly uploadImageByUrl: UploadImageByUrlResponse;
  readonly wishSku: WishSkuResponse;
};

export type MutationAddCreditCardArgs = {
  input: AddCreditCardInput;
};

export type MutationAddFcmRegistrationTokenArgs = {
  deviceId: Scalars["String"];
  token: Scalars["String"];
};

export type MutationAddGiftDraftSkuArgs = {
  input: InputMaybe<AddGiftDraftSkuInput>;
  skuId: Scalars["ID"];
};

export type MutationApproveFollowRequestArgs = {
  userId: Scalars["ID"];
};

export type MutationAttachPromotionCodeArgs = {
  code: Scalars["String"];
};

export type MutationBlockUserArgs = {
  id: Scalars["ID"];
};

export type MutationCancelFollowRequestArgs = {
  userId: Scalars["ID"];
};

export type MutationCheckItemArgs = {
  id: Scalars["ID"];
};

export type MutationConfirmGiftConsigneeArgs = {
  giftId: Scalars["ID"];
};

export type MutationCreateAnniversaryArgs = {
  input: CreateAnniversaryInput;
};

export type MutationCreateAnniversaryUserArgs = {
  input: CreateAnniversaryUserInput;
};

export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};

export type MutationCreateConsigneeArgs = {
  input: CreateConsigneeInput;
};

export type MutationCreateItemArgs = {
  input: CreateItemInput;
};

export type MutationCreateShipperArgs = {
  input: CreateShipperInput;
};

export type MutationCreateSkuArgs = {
  input: CreateSkuInput;
};

export type MutationCreateSkuGroupArgs = {
  input: CreateSkuGroupInput;
};

export type MutationCreateSkuGroupValueArgs = {
  input: CreateSkuGroupValueInput;
};

export type MutationCreateStoreArgs = {
  input: CreateStoreInput;
};

export type MutationDeactivateArgs = {
  input: DeactivateInput;
};

export type MutationDecreaseGiftDraftSkuArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAnniversaryArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAnniversaryUserArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteBrandArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteConsigneeArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGiftDraftSkuArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGiftDraftSkuAllArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteItemArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteStoreArgs = {
  id: Scalars["ID"];
};

export type MutationFollowUserArgs = {
  id: Scalars["ID"];
};

export type MutationIncreaseGiftDraftSkuArgs = {
  id: Scalars["ID"];
};

export type MutationInquireArgs = {
  input: InquireInput;
};

export type MutationLikeSkuArgs = {
  id: Scalars["ID"];
};

export type MutationReconcileGiftSkuArgs = {
  id: Scalars["ID"];
};

export type MutationRejectFollowRequestArgs = {
  userId: Scalars["ID"];
};

export type MutationRemoveCreditCardArgs = {
  id: Scalars["ID"];
};

export type MutationReportUserArgs = {
  id: Scalars["ID"];
  input: ReportUserInput;
};

export type MutationResendPurchaseGiftEmailArgs = {
  id: Scalars["ID"];
};

export type MutationSaveGiftArgs = {
  id: Scalars["ID"];
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationTransferAnniversariesArgs = {
  input: TransferAnniversariesInput;
};

export type MutationUnblockUserArgs = {
  id: Scalars["ID"];
};

export type MutationUnfollowUserArgs = {
  id: Scalars["ID"];
};

export type MutationUnlikeSkuArgs = {
  id: Scalars["ID"];
};

export type MutationUnwishSkuArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateAnniversaryArgs = {
  id: Scalars["ID"];
  input: UpdateAnniversaryInput;
};

export type MutationUpdateAnniversaryUserArgs = {
  id: Scalars["ID"];
  input: UpdateAnniversaryUserInput;
};

export type MutationUpdateBrandArgs = {
  id: Scalars["ID"];
  input: UpdateBrandInput;
};

export type MutationUpdateConsigneeArgs = {
  id: Scalars["ID"];
  input: UpdateConsigneeInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateFcmRegistrationTokenArgs = {
  deviceId: Scalars["String"];
  token: Scalars["String"];
};

export type MutationUpdateGiftConsigneeDraftArgs = {
  giftId: Scalars["ID"];
  input: UpdateGiftConsigneeDraftInput;
};

export type MutationUpdateGiftDeliveryArgs = {
  id: Scalars["ID"];
  input: UpdateGiftDeliveryInput;
};

export type MutationUpdateGiftDraftArgs = {
  input: UpdateGiftDraftInput;
};

export type MutationUpdateGiftDraftDeliveryArgs = {
  id: Scalars["ID"];
  input: UpdateGiftDraftDeliveryInput;
};

export type MutationUpdateGiftDraftSkuArgs = {
  id: Scalars["ID"];
  input: UpdateGiftDraftSkuInput;
};

export type MutationUpdateGiftOrderArgs = {
  input: UpdateGiftOrderInput;
};

export type MutationUpdateItemArgs = {
  id: Scalars["ID"];
  input: UpdateItemInput;
};

export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};

export type MutationUpdateSettingArgs = {
  input: UpdateSettingInput;
};

export type MutationUpdateStoreArgs = {
  id: Scalars["ID"];
  input: UpdateStoreInput;
};

export type MutationUploadImageArgs = {
  image: Scalars["Upload"];
};

export type MutationUploadImageByUrlArgs = {
  url: Scalars["String"];
};

export type MutationWishSkuArgs = {
  id: Scalars["ID"];
};

export type MutationResponse = {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export const MutationResponseCode = {
  BadRequest: "BAD_REQUEST",
  Forbidden: "FORBIDDEN",
  Ok: "OK",
} as const;

export type MutationResponseCode =
  (typeof MutationResponseCode)[keyof typeof MutationResponseCode];
export type MutationResponseError = {
  readonly key: Scalars["String"];
  readonly message: Scalars["String"];
};

export type Notification = {
  readonly body: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly notifiedAt: Scalars["String"];
  readonly title: Scalars["String"];
  readonly type: NotificationType;
};

export type NotificationEdge = {
  readonly cursor: Scalars["String"];
  readonly node: Notification;
};

export const NotificationType = {
  AnniversaryReminder: "ANNIVERSARY_REMINDER",
  FollowRequestApproved: "FOLLOW_REQUEST_APPROVED",
  GiftReminder: "GIFT_REMINDER",
  NewFollower: "NEW_FOLLOWER",
} as const;

export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType];
export type NotificationsConnection = {
  readonly edges: ReadonlyArray<NotificationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type OptionsAmount = {
  readonly amount: Scalars["Int"];
  readonly details: ReadonlyArray<OptionsAmountDetail>;
};

export type OptionsAmountDetail = {
  readonly amount: Scalars["Int"];
  readonly count: Scalars["Int"];
  readonly id: Scalars["String"];
  readonly optionItemName: Maybe<Scalars["String"]>;
  readonly optionName: Scalars["String"];
};

export type PageInfo = {
  readonly endCursor: Maybe<Scalars["String"]>;
  readonly hasNextPage: Scalars["Boolean"];
  readonly hasPreviousPage: Scalars["Boolean"];
  readonly startCursor: Maybe<Scalars["String"]>;
};

export type Payment = {
  readonly creditCard: Maybe<CreditCard>;
  readonly paymentMethod: PaymentMethod;
};

export const PaymentMethod = {
  CreditCard: "CREDIT_CARD",
} as const;

export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];
export type PostalDistrict = {
  readonly city: Scalars["String"];
  readonly prefecture: Prefecture;
};

export type Prefecture = {
  readonly areas: ReadonlyArray<Area>;
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type PrefecturesInput = {
  readonly name?: InputMaybe<Scalars["String"]>;
};

export type PurchaseGiftResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly gift: Maybe<Gift>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type PurchasedGiftsConnection = {
  readonly edges: ReadonlyArray<GiftEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type PurchasedShippingCostsAmount = {
  readonly amount: Scalars["Int"];
  readonly details: ReadonlyArray<PurchasedShippingCostsAmountDetail>;
};

export type PurchasedShippingCostsAmountDetail = {
  readonly amount: Scalars["Int"];
  readonly giftDeliveryId: Scalars["String"];
};

export const PushNotificationType = {
  AnniversaryReminder: "ANNIVERSARY_REMINDER",
  FollowRequestApproved: "FOLLOW_REQUEST_APPROVED",
  GiftReminder: "GIFT_REMINDER",
  NewFollower: "NEW_FOLLOWER",
} as const;

export type PushNotificationType =
  (typeof PushNotificationType)[keyof typeof PushNotificationType];
export type Query = {
  readonly allergies: ReadonlyArray<Allergy>;
  readonly anniversaries: ReadonlyArray<Anniversary>;
  readonly anniversary: Maybe<Anniversary>;
  readonly anniversaryRepetition: Maybe<AnniversaryRepetition>;
  readonly anniversaryUser: Maybe<AnniversaryUser>;
  readonly anniversaryUsers: AnniversaryUsersConnection;
  readonly area: Maybe<Area>;
  readonly areas: ReadonlyArray<Area>;
  readonly banners: ReadonlyArray<Banner>;
  readonly blockingUsers: BlockedUsersConnection;
  readonly brand: Maybe<Brand>;
  readonly brands: BrandsConnection;
  readonly cardDesign: Maybe<CardDesign>;
  readonly cardDesignScenes: ReadonlyArray<CardDesignScene>;
  readonly cardDesigns: ReadonlyArray<CardDesign>;
  readonly consignee: Maybe<Consignee>;
  readonly consignees: ReadonlyArray<Consignee>;
  readonly creditCards: ReadonlyArray<CreditCard>;
  readonly customer: Maybe<Customer>;
  readonly defaultAvatarImageUrls: ReadonlyArray<Scalars["String"]>;
  readonly defaultConsignee: Maybe<Consignee>;
  readonly defaultCreditCard: Maybe<CreditCard>;
  readonly deliveryInfos: Maybe<ReadonlyArray<DeliveryInfo>>;
  readonly deliveryTimes: ReadonlyArray<Scalars["String"]>;
  readonly dynamicLink: Scalars["String"];
  readonly emailAvailability: EmailAvailabilityResponse;
  readonly gift: Maybe<Gift>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly giftSku: Maybe<GiftSku>;
  readonly item: Maybe<Item>;
  readonly itemCategoryGroups: ReadonlyArray<ItemCategoryGroup>;
  readonly itemScenes: ReadonlyArray<ItemScene>;
  readonly items: ItemsConnection;
  readonly leadTimes: ReadonlyArray<LeadTime>;
  readonly me: MeResponse;
  readonly municipalities: ReadonlyArray<Municipality>;
  readonly municipality: Maybe<Municipality>;
  readonly notification: Maybe<Notification>;
  readonly notifications: NotificationsConnection;
  readonly ownAnniversaryUser: Maybe<AnniversaryUser>;
  readonly ping: Scalars["String"];
  readonly prefecture: Maybe<Prefecture>;
  readonly prefectures: ReadonlyArray<Prefecture>;
  readonly purchasedGifts: PurchasedGiftsConnection;
  readonly rankingItemCategoryGroups: ReadonlyArray<ItemCategoryGroup>;
  readonly receivedFollowRequests: ReceivedFollowRequestsConnection;
  readonly recentlyCheckedItems: RecentlyCheckedItemsConnection;
  readonly savedGiftSkus: SavedGiftSkusConnection;
  readonly savedGifts: SavedGiftsConnecction;
  readonly searchPostalDistrict: Maybe<PostalDistrict>;
  readonly searchUsers: SearchUsersConnection;
  readonly setting: Maybe<Setting>;
  readonly shippers: ReadonlyArray<Shipper>;
  readonly store: Maybe<Store>;
  readonly user: Maybe<User>;
  readonly userNameAvailability: UserNameAvailabilityResponse;
};

export type QueryAnniversaryArgs = {
  id: Scalars["ID"];
};

export type QueryAnniversaryRepetitionArgs = {
  id: Scalars["ID"];
};

export type QueryAnniversaryUserArgs = {
  id: Scalars["ID"];
};

export type QueryAnniversaryUsersArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QueryAreaArgs = {
  id: Scalars["ID"];
};

export type QueryAreasArgs = {
  input: InputMaybe<AreasInput>;
};

export type QueryBlockingUsersArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QueryBrandArgs = {
  brandName: Scalars["String"];
};

export type QueryBrandsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  input: InputMaybe<BrandsInput>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QueryCardDesignArgs = {
  id: Scalars["ID"];
};

export type QueryCardDesignsArgs = {
  input: InputMaybe<CardDesignsInput>;
};

export type QueryConsigneeArgs = {
  id: Scalars["ID"];
};

export type QueryDeliveryInfosArgs = {
  input: DeliveryInfosInput;
};

export type QueryDeliveryTimesArgs = {
  input: DeliveryTimesInput;
};

export type QueryDynamicLinkArgs = {
  input: DynamicLinkInput;
};

export type QueryEmailAvailabilityArgs = {
  email: Scalars["String"];
};

export type QueryGiftArgs = {
  id: Scalars["ID"];
};

export type QueryGiftSkuArgs = {
  id: Scalars["ID"];
};

export type QueryItemArgs = {
  id: Scalars["ID"];
};

export type QueryItemsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  input: InputMaybe<ItemsInput>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QueryLeadTimesArgs = {
  input: LeadTimesInput;
};

export type QueryMunicipalitiesArgs = {
  input: InputMaybe<MunicipalitiesInput>;
};

export type QueryMunicipalityArgs = {
  id: Scalars["ID"];
};

export type QueryNotificationArgs = {
  id: Scalars["ID"];
};

export type QueryNotificationsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QueryPrefectureArgs = {
  id: Scalars["ID"];
};

export type QueryPrefecturesArgs = {
  input: InputMaybe<PrefecturesInput>;
};

export type QueryPurchasedGiftsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QueryReceivedFollowRequestsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QueryRecentlyCheckedItemsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QuerySavedGiftSkusArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  input: InputMaybe<SavedGiftSkusInput>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QuerySavedGiftsArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchPostalDistrictArgs = {
  postalCode: Scalars["String"];
};

export type QuerySearchUsersArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  input: SearchUsersInput;
  last: InputMaybe<Scalars["Int"]>;
};

export type QueryStoreArgs = {
  id: Scalars["ID"];
};

export type QueryUserArgs = {
  userName: Scalars["String"];
};

export type QueryUserNameAvailabilityArgs = {
  userName: Scalars["String"];
};

export type ReceivedFollowRequestsConnection = {
  readonly edges: ReadonlyArray<UserEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export const ReceivingWay = {
  Delivery: "DELIVERY",
  Reconciliation: "RECONCILIATION",
  SerialCode: "SERIAL_CODE",
} as const;

export type ReceivingWay = (typeof ReceivingWay)[keyof typeof ReceivingWay];
export type RecentlyCheckedItemsConnection = {
  readonly edges: ReadonlyArray<ItemEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type RecommendedItemsConnection = {
  readonly edges: ReadonlyArray<ItemEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type ReconcileGiftSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftSku: Maybe<GiftSku>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export const ReconciliationWay = {
  Normal: "NORMAL",
  Pin: "PIN",
} as const;

export type ReconciliationWay =
  (typeof ReconciliationWay)[keyof typeof ReconciliationWay];
export type RejectFollowRequestResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
  readonly user: Maybe<User>;
};

export type RemoveCreditCardResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type ReportUserInput = {
  readonly reason: Scalars["String"];
};

export type ReportUserResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type ResendPurchaseGiftEmailResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type SaveGiftResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly gift: Maybe<Gift>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type SavedGiftSkusConnection = {
  readonly edges: ReadonlyArray<GiftSkuEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type SavedGiftSkusInput = {
  readonly used?: InputMaybe<Scalars["Boolean"]>;
};

export type SavedGiftsConnecction = {
  readonly edges: ReadonlyArray<GiftEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type SearchUsersConnection = {
  readonly edges: ReadonlyArray<UserEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type SearchUsersInput = {
  readonly q: Scalars["String"];
};

export type Setting = {
  readonly email: Scalars["String"];
  readonly emailNotifications: ReadonlyArray<EmailNotificationType>;
  readonly id: Scalars["ID"];
  readonly pushNotifications: ReadonlyArray<PushNotificationType>;
};

export const ShipFrom = {
  Anonymous: "ANONYMOUS",
  Me: "ME",
} as const;

export type ShipFrom = (typeof ShipFrom)[keyof typeof ShipFrom];
export type Shipper = {
  readonly deliveryDateBegin: Maybe<Scalars["String"]>;
  readonly deliveryDateEnd: Maybe<Scalars["String"]>;
  readonly deliveryDateSpecifiable: Scalars["Boolean"];
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type ShippingCost = {
  readonly cost: Scalars["Int"];
  readonly id: Scalars["ID"];
  readonly prefecture: Prefecture;
  readonly sku: Sku;
};

export type ShippingCostInput = {
  readonly cost: Scalars["Int"];
  readonly prefectureId: Scalars["ID"];
};

export type ShippingCostsAmount = {
  readonly amount: Scalars["Int"];
  readonly details: ReadonlyArray<ShippingCostsAmountDetail>;
  readonly minAmount: Scalars["Int"];
};

export type ShippingCostsAmountDetail = {
  readonly amount: Scalars["Int"];
  readonly giftDraftDeliveryId: Scalars["String"];
};

export type SignUpInput = {
  readonly allergyIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly avatarImageId: Scalars["String"];
  readonly birthDay: Scalars["Int"];
  readonly birthMonth: Scalars["Int"];
  readonly birthYear: Scalars["Int"];
  readonly displayName: Scalars["String"];
  readonly email: Scalars["String"];
  readonly firebaseAuthIdToken: Scalars["String"];
  readonly municipalityIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly private: Scalars["Boolean"];
  readonly showYear: Scalars["Boolean"];
  readonly userName: Scalars["String"];
};

export type SignUpResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly me: Maybe<User>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type Sku = {
  readonly id: Scalars["ID"];
  readonly item: Item;
  readonly liked: Maybe<Scalars["Boolean"]>;
  readonly onSale: Scalars["Boolean"];
  readonly options: ReadonlyArray<SkuOption>;
  readonly price: Scalars["Int"];
  readonly shipper: Maybe<Shipper>;
  readonly shippingCosts: Maybe<ReadonlyArray<ShippingCost>>;
  readonly skuCode: Maybe<Scalars["String"]>;
  readonly skuGroupValue1: Maybe<SkuGroupValue>;
  readonly skuGroupValue2: Maybe<SkuGroupValue>;
  readonly taxRate: Maybe<Scalars["Int"]>;
  readonly wished: Maybe<Scalars["Boolean"]>;
};

export type SkuEdge = {
  readonly cursor: Scalars["String"];
  readonly node: Sku;
};

export type SkuGroup = {
  readonly id: Scalars["ID"];
  readonly item: Item;
  readonly name: Scalars["String"];
  readonly values: ReadonlyArray<SkuGroupValue>;
};

export type SkuGroupValue = {
  readonly group: SkuGroup;
  readonly id: Scalars["ID"];
  readonly image: Maybe<Image>;
  readonly name: Scalars["String"];
};

export type SkuOption = {
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
  readonly optionItems: ReadonlyArray<SkuOptionItem>;
  readonly sku: Sku;
};

export type SkuOptionItem = {
  readonly attention: Scalars["String"];
  readonly description: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly image: Image;
  readonly name: Scalars["String"];
  readonly onSale: Scalars["Boolean"];
  readonly price: Scalars["Int"];
  readonly skuOption: SkuOption;
  readonly stockCount: Scalars["Int"];
};

export type Store = {
  readonly address: Scalars["String"];
  readonly brand: Brand;
  readonly businessHours: Scalars["String"];
  readonly googleMapSrc: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly municipalities: ReadonlyArray<Municipality>;
  readonly name: Scalars["String"];
  readonly order: Scalars["Int"];
  readonly phoneNumber: Scalars["String"];
  readonly transportation: Scalars["String"];
};

export type TransferAnniversariesInput = {
  readonly fromAnniversaryUserId: Scalars["ID"];
  readonly toAnniversaryUserId: Scalars["ID"];
};

export type TransferAnniversariesResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UnblockUserResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
  readonly user: Maybe<User>;
};

export type UnfollowUserResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
  readonly user: Maybe<User>;
};

export type UnlikeSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly sku: Maybe<Sku>;
  readonly success: Scalars["Boolean"];
};

export type UnwishSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly sku: Maybe<Sku>;
  readonly success: Scalars["Boolean"];
};

export type UpdateAnniversaryInput = {
  readonly anniversaryUserId?: InputMaybe<Scalars["ID"]>;
  readonly repetitions?: InputMaybe<ReadonlyArray<AnniversaryRepetitionInput>>;
};

export type UpdateAnniversaryResponse = MutationResponse & {
  readonly anniversary: Maybe<Anniversary>;
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateAnniversaryUserInput = {
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly relation?: InputMaybe<AnniversaryUserRelation>;
};

export type UpdateAnniversaryUserResponse = MutationResponse & {
  readonly anniversaryUser: Maybe<AnniversaryUser>;
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateBrandInput = {
  readonly alterImageId?: InputMaybe<Scalars["ID"]>;
  readonly brandName?: InputMaybe<Scalars["String"]>;
  readonly description?: InputMaybe<Scalars["String"]>;
  readonly displayName?: InputMaybe<Scalars["String"]>;
  readonly logoImageId?: InputMaybe<Scalars["ID"]>;
  readonly visibility?: InputMaybe<BrandVisibility>;
};

export type UpdateBrandResponse = MutationResponse & {
  readonly brand: Maybe<Brand>;
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateConsigneeInput = {
  readonly building?: InputMaybe<Scalars["String"]>;
  readonly city?: InputMaybe<Scalars["String"]>;
  readonly email?: InputMaybe<Scalars["String"]>;
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly nameKana?: InputMaybe<Scalars["String"]>;
  readonly phoneNumber?: InputMaybe<Scalars["String"]>;
  readonly postalCode?: InputMaybe<Scalars["String"]>;
  readonly prefectureId?: InputMaybe<Scalars["ID"]>;
};

export type UpdateConsigneeResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly consignee: Maybe<Consignee>;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateCustomerInput = {
  readonly building: Scalars["String"];
  readonly city: Scalars["String"];
  readonly email: Scalars["String"];
  readonly name: Scalars["String"];
  readonly nameKana: Scalars["String"];
  readonly phoneNumber: Scalars["String"];
  readonly postalCode: Scalars["String"];
  readonly prefectureId: Scalars["ID"];
};

export type UpdateCustomerResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly customer: Maybe<Customer>;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateFcmRegistrationTokenResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateGiftConsigneeDraftInput = {
  readonly consignee?: InputMaybe<GiftConsigneeInput>;
  readonly consigneeId?: InputMaybe<Scalars["ID"]>;
  readonly saveConsignee?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateGiftConsigneeDraftResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateGiftDeliveryInput = {
  readonly deliveryDateDraft?: InputMaybe<Scalars["String"]>;
  readonly deliveryTimeDraft?: InputMaybe<Scalars["String"]>;
};

export type UpdateGiftDeliveryResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDelivery: Maybe<GiftDelivery>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateGiftDraftDeliveryInput = {
  readonly deliveryDate?: InputMaybe<Scalars["String"]>;
  readonly deliveryOptionItemIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly deliveryTime?: InputMaybe<Scalars["String"]>;
};

export type UpdateGiftDraftDeliveryResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraftDelivery: Maybe<GiftDraftDelivery>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateGiftDraftInput = {
  readonly cardDesignId?: InputMaybe<Scalars["ID"]>;
  readonly deliveryMethod?: InputMaybe<DeliveryMethod>;
  readonly giftFor?: InputMaybe<GiftFor>;
  readonly gifteeName?: InputMaybe<Scalars["String"]>;
  readonly gifterName?: InputMaybe<Scalars["String"]>;
  readonly message?: InputMaybe<Scalars["String"]>;
  readonly secret?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateGiftDraftResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateGiftDraftSkuInput = {
  readonly optionItemIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
};

export type UpdateGiftDraftSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly giftDraft: Maybe<GiftDraft>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateGiftOrderInput = {
  readonly anonymousCustomerEmail?: InputMaybe<Scalars["String"]>;
  readonly consignee?: InputMaybe<GiftConsigneeInput>;
  readonly consigneeId?: InputMaybe<Scalars["ID"]>;
  readonly creditCardId?: InputMaybe<Scalars["String"]>;
  readonly customer?: InputMaybe<GiftCustomerInput>;
  readonly deliveryMethod?: InputMaybe<DeliveryMethod>;
  readonly paymentMethod: PaymentMethod;
  readonly saveConsignee?: InputMaybe<Scalars["Boolean"]>;
  readonly saveCustomer?: InputMaybe<Scalars["Boolean"]>;
  readonly shipFrom?: InputMaybe<ShipFrom>;
  readonly shipFromName?: InputMaybe<Scalars["String"]>;
};

export type UpdateGiftOrderResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateItemInput = {
  readonly allergyIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly attention?: InputMaybe<Scalars["String"]>;
  readonly availableRegion?: InputMaybe<AvailableRegion>;
  readonly description?: InputMaybe<DescriptionInput>;
  readonly expireAfter?: InputMaybe<Scalars["Int"]>;
  readonly expireTimeUnit?: InputMaybe<ExpireTimeUnit>;
  readonly imageIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly itemCategoryId?: InputMaybe<Scalars["String"]>;
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly onSale?: InputMaybe<Scalars["Boolean"]>;
  readonly priority?: InputMaybe<Scalars["Int"]>;
  readonly receivingWay?: InputMaybe<ReceivingWay>;
  readonly reconciliationWay?: InputMaybe<ReconciliationWay>;
  readonly relations?: InputMaybe<ReadonlyArray<AnniversaryUserRelation>>;
  readonly sceneIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly shortUsage?: InputMaybe<Scalars["String"]>;
  readonly usageExplanations?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
};

export type UpdateItemResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly item: Maybe<Item>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateMeInput = {
  readonly allergyIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly avatarImageId?: InputMaybe<Scalars["ID"]>;
  readonly biography?: InputMaybe<Scalars["String"]>;
  readonly birthDay?: InputMaybe<Scalars["Int"]>;
  readonly birthMonth?: InputMaybe<Scalars["Int"]>;
  readonly birthYear?: InputMaybe<Scalars["Int"]>;
  readonly displayName?: InputMaybe<Scalars["String"]>;
  readonly municipalityIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly private?: InputMaybe<Scalars["Boolean"]>;
  readonly showYear?: InputMaybe<Scalars["Boolean"]>;
  readonly userName?: InputMaybe<Scalars["String"]>;
};

export type UpdateMeResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly me: Maybe<User>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UpdateSettingInput = {
  readonly email?: InputMaybe<Scalars["String"]>;
  readonly emailNotifications?: InputMaybe<
    ReadonlyArray<EmailNotificationType>
  >;
  readonly pushNotifications?: InputMaybe<ReadonlyArray<PushNotificationType>>;
};

export type UpdateSettingResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly setting: Maybe<Setting>;
  readonly success: Scalars["Boolean"];
};

export type UpdateStoreInput = {
  readonly address?: InputMaybe<Scalars["String"]>;
  readonly businessHours?: InputMaybe<Scalars["String"]>;
  readonly googleMapSrc?: InputMaybe<Scalars["String"]>;
  readonly municipalityIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly phoneNumber?: InputMaybe<Scalars["String"]>;
  readonly transportation?: InputMaybe<Scalars["String"]>;
};

export type UpdateStoreResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly store: Maybe<Store>;
  readonly success: Scalars["Boolean"];
};

export type UploadImageByUrlResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly image: Maybe<Image>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type UploadImageResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly image: Maybe<Image>;
  readonly message: Scalars["String"];
  readonly success: Scalars["Boolean"];
};

export type User = {
  readonly allergies: Maybe<ReadonlyArray<Allergy>>;
  readonly anniversaries: Maybe<ReadonlyArray<Anniversary>>;
  readonly anniversaryUser: Maybe<AnniversaryUser>;
  readonly avatarImageUrl: Scalars["String"];
  readonly biography: Scalars["String"];
  readonly birthDay: Maybe<Scalars["Int"]>;
  readonly birthMonth: Maybe<Scalars["Int"]>;
  readonly birthYear: Maybe<Scalars["Int"]>;
  readonly blockingMe: Scalars["Boolean"];
  readonly displayName: Scalars["String"];
  readonly followeeCount: Scalars["Int"];
  readonly followees: Maybe<UserFolloweesConnection>;
  readonly followerCount: Scalars["Int"];
  readonly followers: Maybe<UserFollowersConnection>;
  readonly followingMe: Scalars["Boolean"];
  readonly id: Scalars["ID"];
  readonly likes: Maybe<LikesConnection>;
  readonly municipalities: Maybe<ReadonlyArray<Municipality>>;
  readonly private: Scalars["Boolean"];
  readonly relationStatus: Maybe<UserRelationStatus>;
  readonly role: Maybe<UserRole>;
  readonly showYear: Scalars["Boolean"];
  readonly userName: Scalars["String"];
  readonly wishes: Maybe<WishesConnection>;
};

export type UserFolloweesArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type UserFollowersArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type UserLikesArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type UserWishesArgs = {
  after: InputMaybe<Scalars["String"]>;
  before: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
};

export type UserEdge = {
  readonly cursor: Scalars["String"];
  readonly node: User;
};

export type UserFolloweesConnection = {
  readonly edges: ReadonlyArray<UserEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type UserFollowersConnection = {
  readonly edges: ReadonlyArray<UserEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type UserNameAvailabilityResponse = {
  readonly available: Scalars["Boolean"];
};

export const UserRelationStatus = {
  Blocking: "BLOCKING",
  Following: "FOLLOWING",
  Pending: "PENDING",
} as const;

export type UserRelationStatus =
  (typeof UserRelationStatus)[keyof typeof UserRelationStatus];
export const UserRole = {
  Admin: "ADMIN",
  BrandUser: "BRAND_USER",
  General: "GENERAL",
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export type WishSkuResponse = MutationResponse & {
  readonly code: MutationResponseCode;
  readonly errors: Maybe<ReadonlyArray<MutationResponseError>>;
  readonly message: Scalars["String"];
  readonly sku: Maybe<Sku>;
  readonly success: Scalars["Boolean"];
};

export type WishesConnection = {
  readonly edges: ReadonlyArray<SkuEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"];
};

export type AccountPrivacyDataQueryVariables = Exact<{ [key: string]: never }>;

export type AccountPrivacyDataQuery = {
  readonly me: {
    readonly user: { readonly id: string; readonly private: boolean } | null;
  };
};

export type AddCreditCardMutationVariables = Exact<{
  input: AddCreditCardInput;
}>;

export type AddCreditCardMutation = {
  readonly addCreditCard: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type AddGiftDraftSkuMutationVariables = Exact<{
  skuId: Scalars["ID"];
  input: InputMaybe<AddGiftDraftSkuInput>;
}>;

export type AddGiftDraftSkuMutation = {
  readonly addGiftDraftSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly giftDraft: {
      readonly id: string;
      readonly itemsAmount: number;
      readonly giftDraftSkus: ReadonlyArray<{
        readonly id: string;
        readonly sku: {
          readonly id: string;
          readonly price: number;
          readonly item: {
            readonly id: string;
            readonly name: string;
            readonly expireAt: string;
            readonly topImage: { readonly id: string; readonly url: string };
            readonly brand: {
              readonly id: string;
              readonly displayName: string;
            };
          };
        };
      }>;
    } | null;
  };
};

export type AllergiesQueryVariables = Exact<{ [key: string]: never }>;

export type AllergiesQuery = {
  readonly allergies: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};

export type AnniversariesQueryVariables = Exact<{ [key: string]: never }>;

export type AnniversariesQuery = {
  readonly anniversaries: ReadonlyArray<{
    readonly id: string;
    readonly anniversaryUser: {
      readonly id: string;
      readonly name: string;
      readonly relation: AnniversaryUserRelation;
      readonly user: {
        readonly id: string;
        readonly avatarImageUrl: string;
      } | null;
    };
    readonly repetitions: ReadonlyArray<{
      readonly id: string;
      readonly type: AnniversaryType;
      readonly name: string;
      readonly frequency: AnniversaryFrequency;
      readonly private: boolean;
      readonly start: {
        readonly year: number;
        readonly month: number;
        readonly day: number;
      };
      readonly until: {
        readonly year: number;
        readonly month: number;
        readonly day: number;
      } | null;
    }>;
  }>;
};

export type AnniversaryCreatePageDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AnniversaryCreatePageDataQuery = {
  readonly ownAnniversaryUser: { readonly id: string } | null;
};

export type AnniversaryEditPageDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AnniversaryEditPageDataQuery = {
  readonly ownAnniversaryUser: { readonly id: string } | null;
};

export type AnniversaryPageDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AnniversaryPageDataQuery = {
  readonly anniversaryRepetition: {
    readonly id: string;
    readonly type: AnniversaryType;
    readonly name: string;
    readonly anniversary: {
      readonly id: string;
      readonly anniversaryUser: {
        readonly id: string;
        readonly name: string;
        readonly relation: AnniversaryUserRelation;
        readonly user: {
          readonly id: string;
          readonly userName: string;
          readonly avatarImageUrl: string;
          readonly wishes: {
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly price: number;
                readonly liked: boolean | null;
                readonly item: {
                  readonly id: string;
                  readonly name: string;
                  readonly topImage: {
                    readonly id: string;
                    readonly url: string;
                  };
                  readonly brand: {
                    readonly id: string;
                    readonly displayName: string;
                  };
                  readonly municipalities: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                  }>;
                };
                readonly skuGroupValue1: {
                  readonly id: string;
                  readonly name: string;
                  readonly group: {
                    readonly id: string;
                    readonly name: string;
                  };
                } | null;
                readonly skuGroupValue2: {
                  readonly id: string;
                  readonly name: string;
                  readonly group: {
                    readonly id: string;
                    readonly name: string;
                  };
                } | null;
              };
            }>;
          } | null;
        } | null;
      };
    };
  } | null;
};

export type AnniversaryRecommendedItemsQueryVariables = Exact<{
  anniversaryRepetitionId: Scalars["ID"];
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
}>;

export type AnniversaryRecommendedItemsQuery = {
  readonly anniversaryRepetition: {
    readonly id: string;
    readonly recommendedItems: {
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly minSkuPrice: number;
          readonly isSkuPriceConstant: boolean;
          readonly liked: boolean | null;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
          readonly municipalities: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
          }>;
          readonly skus: ReadonlyArray<{ readonly id: string }>;
        };
      }>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
    };
  } | null;
};

export type AnniversaryRepetitionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AnniversaryRepetitionQuery = {
  readonly anniversaryRepetition: {
    readonly id: string;
    readonly type: AnniversaryType;
    readonly name: string;
    readonly frequency: AnniversaryFrequency;
    readonly remindTimings: ReadonlyArray<AnniversaryRemindTiming>;
    readonly private: boolean;
    readonly memo: string;
    readonly anniversary: {
      readonly id: string;
      readonly anniversaryUser: {
        readonly id: string;
        readonly user: { readonly id: string } | null;
      };
      readonly repetitions: ReadonlyArray<{
        readonly id: string;
        readonly type: AnniversaryType;
        readonly name: string;
        readonly frequency: AnniversaryFrequency;
        readonly remindTimings: ReadonlyArray<AnniversaryRemindTiming>;
        readonly private: boolean;
        readonly memo: string;
        readonly start: {
          readonly year: number;
          readonly month: number;
          readonly day: number;
        };
        readonly until: {
          readonly year: number;
          readonly month: number;
          readonly day: number;
        } | null;
      }>;
    };
    readonly start: {
      readonly year: number;
      readonly month: number;
      readonly day: number;
    };
    readonly until: {
      readonly year: number;
      readonly month: number;
      readonly day: number;
    } | null;
  } | null;
};

export type AnniversaryUsersQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
}>;

export type AnniversaryUsersQuery = {
  readonly anniversaryUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly relation: AnniversaryUserRelation;
        readonly user: {
          readonly id: string;
          readonly userName: string;
          readonly avatarImageUrl: string;
        } | null;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type ApproveFollowRequestMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type ApproveFollowRequestMutation = {
  readonly approveFollowRequest: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly user: {
      readonly id: string;
      readonly followingMe: boolean;
    } | null;
  };
};

export type AttachPromotionCodeMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type AttachPromotionCodeMutation = {
  readonly attachPromotionCode: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly giftDraft: {
      readonly id: string;
      readonly promotionCodeAmount: number | null;
      readonly totalAmount: number;
    } | null;
  };
};

export type BlockUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BlockUserMutation = {
  readonly blockUser: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly user: {
      readonly id: string;
      readonly relationStatus: UserRelationStatus | null;
    } | null;
  };
};

export type BlockingUsersQueryVariables = Exact<{
  first: Scalars["Int"];
  after: InputMaybe<Scalars["String"]>;
}>;

export type BlockingUsersQuery = {
  readonly blockingUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly userName: string;
        readonly displayName: string;
        readonly avatarImageUrl: string;
        readonly relationStatus: UserRelationStatus | null;
        readonly followingMe: boolean;
        readonly private: boolean;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type BrandEditPageDataQueryVariables = Exact<{
  brandName: Scalars["String"];
}>;

export type BrandEditPageDataQuery = {
  readonly brand: {
    readonly id: string;
    readonly brandName: string;
    readonly displayName: string;
    readonly description: string;
    readonly visibility: BrandVisibility;
    readonly logoImage: { readonly id: string; readonly url: string } | null;
    readonly alterImage: { readonly id: string; readonly url: string } | null;
  } | null;
};

export type BrandListPageInitialDataQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
  input: InputMaybe<BrandsInput>;
}>;

export type BrandListPageInitialDataQuery = {
  readonly brands: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly displayName: string;
        readonly brandName: string;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type BrandPageInitialDataQueryVariables = Exact<{
  brandName: Scalars["String"];
}>;

export type BrandPageInitialDataQuery = {
  readonly brand: {
    readonly id: string;
    readonly displayName: string;
    readonly description: string;
    readonly logoImage: { readonly id: string; readonly url: string } | null;
    readonly alterImage: { readonly id: string; readonly url: string } | null;
    readonly stores: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly address: string;
      readonly transportation: string;
      readonly businessHours: string;
      readonly phoneNumber: string;
      readonly googleMapSrc: string;
    }>;
  } | null;
};

export type BrandsQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
  input: InputMaybe<BrandsInput>;
}>;

export type BrandsQuery = {
  readonly brands: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly displayName: string;
        readonly brandName: string;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type CancelFollowRequestMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type CancelFollowRequestMutation = {
  readonly cancelFollowRequest: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly user: {
      readonly id: string;
      readonly relationStatus: UserRelationStatus | null;
    } | null;
  };
};

export type CardDesignQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CardDesignQuery = {
  readonly cardDesign: {
    readonly id: string;
    readonly name: string;
    readonly image: { readonly id: string; readonly url: string };
  } | null;
};

export type CardDesignScenesQueryVariables = Exact<{ [key: string]: never }>;

export type CardDesignScenesQuery = {
  readonly cardDesignScenes: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly cardDesigns: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly image: { readonly id: string; readonly url: string };
    }>;
  }>;
};

export type GiftDraftSkuFieldsFragment = {
  readonly id: string;
  readonly sku: {
    readonly id: string;
    readonly onSale: boolean;
    readonly price: number;
    readonly item: {
      readonly id: string;
      readonly name: string;
      readonly expireAt: string;
      readonly topImage: { readonly id: string; readonly url: string };
      readonly brand: { readonly id: string; readonly displayName: string };
    };
    readonly skuGroupValue1: {
      readonly id: string;
      readonly name: string;
      readonly group: { readonly id: string; readonly name: string };
    } | null;
    readonly skuGroupValue2: {
      readonly id: string;
      readonly name: string;
      readonly group: { readonly id: string; readonly name: string };
    } | null;
    readonly shipper: { readonly id: string } | null;
  };
  readonly optionItems: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly price: number;
  }>;
};

export type CartPageDataQueryVariables = Exact<{ [key: string]: never }>;

export type CartPageDataQuery = {
  readonly giftDraft: {
    readonly id: string;
    readonly itemsAmount: number;
    readonly giftDraftSkus: ReadonlyArray<{
      readonly id: string;
      readonly sku: {
        readonly id: string;
        readonly onSale: boolean;
        readonly price: number;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly expireAt: string;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
        };
        readonly skuGroupValue1: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly skuGroupValue2: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly shipper: { readonly id: string } | null;
      };
      readonly optionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly price: number;
      }>;
    }>;
    readonly abandonedGiftDraftSkus: ReadonlyArray<{
      readonly id: string;
      readonly sku: {
        readonly id: string;
        readonly onSale: boolean;
        readonly price: number;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly expireAt: string;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
        };
        readonly skuGroupValue1: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly skuGroupValue2: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly shipper: { readonly id: string } | null;
      };
      readonly optionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly price: number;
      }>;
    }>;
    readonly giftDraftDeliveries: ReadonlyArray<{
      readonly id: string;
      readonly shipper: { readonly id: string; readonly name: string };
      readonly selectedDeliveryOptionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly price: number;
      }>;
    }>;
  } | null;
};

export type CartSkuCountQueryVariables = Exact<{ [key: string]: never }>;

export type CartSkuCountQuery = {
  readonly giftDraft: {
    readonly id: string;
    readonly giftDraftSkus: ReadonlyArray<{ readonly id: string }>;
  } | null;
};

export type ConfirmGiftConsigneeMutationVariables = Exact<{
  giftId: Scalars["ID"];
}>;

export type ConfirmGiftConsigneeMutation = {
  readonly confirmGiftConsignee: {
    readonly code: MutationResponseCode;
    readonly message: string;
  };
};

export type ConsigneesQueryVariables = Exact<{ [key: string]: never }>;

export type ConsigneesQuery = {
  readonly consignees: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly nameKana: string;
    readonly postalCode: string;
    readonly city: string;
    readonly building: string;
    readonly phoneNumber: string;
    readonly email: string;
    readonly prefecture: { readonly id: string; readonly name: string };
  }>;
};

export type CreateAnniversaryMutationVariables = Exact<{
  input: CreateAnniversaryInput;
}>;

export type CreateAnniversaryMutation = {
  readonly createAnniversary: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type CreateAnniversaryUserMutationVariables = Exact<{
  input: CreateAnniversaryUserInput;
}>;

export type CreateAnniversaryUserMutation = {
  readonly createAnniversaryUser: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;

export type CreateBrandMutation = {
  readonly createBrand: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly brand: { readonly id: string; readonly brandName: string } | null;
  };
};

export type CreateConsigneeMutationVariables = Exact<{
  input: CreateConsigneeInput;
}>;

export type CreateConsigneeMutation = {
  readonly createConsignee: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type CreateItemMutationVariables = Exact<{
  input: CreateItemInput;
}>;

export type CreateItemMutation = {
  readonly createItem: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly item: { readonly id: string } | null;
  };
};

export type CreateShipperMutationVariables = Exact<{
  input: CreateShipperInput;
}>;

export type CreateShipperMutation = {
  readonly createShipper: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type CreateSkuMutationVariables = Exact<{
  input: CreateSkuInput;
}>;

export type CreateSkuMutation = {
  readonly createSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type CreateSkuGroupMutationVariables = Exact<{
  input: CreateSkuGroupInput;
}>;

export type CreateSkuGroupMutation = {
  readonly createSkuGroup: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly skuGroup: { readonly id: string } | null;
  };
};

export type CreateSkuGroupValueMutationVariables = Exact<{
  input: CreateSkuGroupValueInput;
}>;

export type CreateSkuGroupValueMutation = {
  readonly createSkuGroupValue: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly skuGroupValue: { readonly id: string } | null;
  };
};

export type CreateStoreMutationVariables = Exact<{
  input: CreateStoreInput;
}>;

export type CreateStoreMutation = {
  readonly createStore: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly store: {
      readonly id: string;
      readonly brand: { readonly id: string; readonly brandName: string };
    } | null;
  };
};

export type CreditCardsQueryVariables = Exact<{ [key: string]: never }>;

export type CreditCardsQuery = {
  readonly creditCards: ReadonlyArray<{
    readonly id: string;
    readonly brand: string;
    readonly last4: string;
    readonly expMonth: number;
    readonly expYear: number;
  }>;
};

export type DeactivateMutationVariables = Exact<{
  input: DeactivateInput;
}>;

export type DeactivateMutation = {
  readonly deactivate: {
    readonly code: MutationResponseCode;
    readonly message: string;
  };
};

export type DecreaseGiftDraftSkuMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DecreaseGiftDraftSkuMutation = {
  readonly decreaseGiftDraftSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly giftDraft: {
      readonly id: string;
      readonly itemsAmount: number;
      readonly giftDraftSkus: ReadonlyArray<{ readonly id: string }>;
    } | null;
  };
};

export type DefaultAvatarImageUrlsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DefaultAvatarImageUrlsQuery = {
  readonly defaultAvatarImageUrls: ReadonlyArray<string>;
};

export type DefaultConsigneeQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultConsigneeQuery = {
  readonly defaultConsignee: {
    readonly id: string;
    readonly name: string;
    readonly nameKana: string;
    readonly postalCode: string;
    readonly city: string;
    readonly building: string;
    readonly phoneNumber: string;
    readonly email: string;
    readonly prefecture: { readonly id: string; readonly name: string };
  } | null;
};

export type DeleteAnniversaryUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteAnniversaryUserMutation = {
  readonly deleteAnniversaryUser: {
    readonly code: MutationResponseCode;
    readonly message: string;
  };
};

export type DeleteConsigneeMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteConsigneeMutation = {
  readonly deleteConsignee: {
    readonly code: MutationResponseCode;
    readonly message: string;
  };
};

export type DeleteGiftDraftSkuMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteGiftDraftSkuMutation = {
  readonly deleteGiftDraftSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly giftDraft: {
      readonly id: string;
      readonly itemsAmount: number;
      readonly giftDraftSkus: ReadonlyArray<{ readonly id: string }>;
      readonly giftDraftDeliveries: ReadonlyArray<{ readonly id: string }>;
    } | null;
  };
};

export type DeleteGiftDraftSkuAllMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteGiftDraftSkuAllMutation = {
  readonly deleteGiftDraftSkuAll: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly giftDraft: {
      readonly id: string;
      readonly itemsAmount: number;
      readonly giftDraftSkus: ReadonlyArray<{ readonly id: string }>;
      readonly abandonedGiftDraftSkus: ReadonlyArray<{ readonly id: string }>;
      readonly giftDraftDeliveries: ReadonlyArray<{ readonly id: string }>;
    } | null;
  };
};

export type DeliveryInfosQueryVariables = Exact<{
  input: DeliveryInfosInput;
}>;

export type DeliveryInfosQuery = {
  readonly deliveryInfos: ReadonlyArray<{
    readonly id: string;
    readonly deliveryDate: string | null;
    readonly deliveryTime: string | null;
    readonly purchasePrice: number;
    readonly createdAt: string;
    readonly gift: {
      readonly id: string;
      readonly orderNumber: string;
      readonly deliveryMethod: DeliveryMethod | null;
      readonly giftFor: GiftFor;
      readonly itemsAmount: number;
      readonly totalAmount: number;
      readonly customer: {
        readonly id: string;
        readonly name: string;
        readonly nameKana: string;
        readonly postalCode: string;
        readonly city: string;
        readonly building: string;
        readonly phoneNumber: string;
        readonly email: string;
        readonly prefecture: { readonly id: string; readonly name: string };
      } | null;
    };
    readonly consignee: {
      readonly id: string;
      readonly name: string;
      readonly nameKana: string;
      readonly postalCode: string;
      readonly city: string;
      readonly building: string;
      readonly phoneNumber: string;
      readonly email: string;
      readonly prefecture: { readonly id: string; readonly name: string };
    };
    readonly sku: {
      readonly id: string;
      readonly skuCode: string | null;
      readonly item: {
        readonly id: string;
        readonly name: string;
        readonly brand: {
          readonly id: string;
          readonly brandName: string;
          readonly displayName: string;
        };
      };
      readonly shipper: { readonly id: string; readonly name: string } | null;
      readonly skuGroupValue1: {
        readonly id: string;
        readonly name: string;
        readonly group: { readonly id: string; readonly name: string };
      } | null;
      readonly skuGroupValue2: {
        readonly id: string;
        readonly name: string;
        readonly group: { readonly id: string; readonly name: string };
      } | null;
    };
    readonly skuOptionItems: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly giftDelivery: {
      readonly id: string;
      readonly deliveryOptionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly deliveryOption: { readonly id: string; readonly name: string };
      }>;
    };
  }> | null;
};

export type DetachPromotionCodeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DetachPromotionCodeMutation = {
  readonly detachPromotionCode: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly giftDraft: {
      readonly id: string;
      readonly promotionCodeAmount: number | null;
      readonly totalAmount: number;
    } | null;
  };
};

export type DynamicLinkQueryVariables = Exact<{
  input: DynamicLinkInput;
}>;

export type DynamicLinkQuery = { readonly dynamicLink: string };

export type EmailAvailabilityQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type EmailAvailabilityQuery = {
  readonly emailAvailability: { readonly available: boolean };
};

export type EmailNotificationSettingPageDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmailNotificationSettingPageDataQuery = {
  readonly setting: {
    readonly id: string;
    readonly emailNotifications: ReadonlyArray<EmailNotificationType>;
  } | null;
};

export type FollowUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FollowUserMutation = {
  readonly followUser: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly user: {
      readonly id: string;
      readonly relationStatus: UserRelationStatus | null;
    } | null;
  };
};

export type GiftQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GiftQuery = {
  readonly gift: {
    readonly id: string;
    readonly gifteeName: string;
    readonly gifterName: string;
    readonly message: string;
    readonly savedAt: string | null;
    readonly cardDesign: {
      readonly id: string;
      readonly image: { readonly id: string; readonly url: string };
    } | null;
    readonly gifter: { readonly id: string; readonly avatarImageUrl: string };
    readonly giftSkus: ReadonlyArray<{
      readonly id: string;
      readonly receivingWay: ReceivingWay;
      readonly expireAt: string;
      readonly usedAt: string | null;
      readonly sku: {
        readonly id: string;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
        };
        readonly skuGroupValue1: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly skuGroupValue2: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
      };
    }>;
  } | null;
};

export type GiftConfirmPageDataQueryVariables = Exact<{ [key: string]: never }>;

export type GiftConfirmPageDataQuery = {
  readonly giftDraft: {
    readonly id: string;
    readonly giftFor: GiftFor;
    readonly gifteeName: string;
    readonly gifterName: string;
    readonly message: string;
    readonly secret: boolean;
    readonly deliveryMethod: DeliveryMethod | null;
    readonly shipFrom: ShipFrom | null;
    readonly itemsAmount: number;
    readonly promotionCodeAmount: number | null;
    readonly totalAmount: number;
    readonly paymentMethod: PaymentMethod;
    readonly cardDesign: {
      readonly id: string;
      readonly image: { readonly id: string; readonly url: string };
    } | null;
    readonly optionsAmount: {
      readonly amount: number;
      readonly details: ReadonlyArray<{
        readonly id: string;
        readonly optionName: string;
        readonly optionItemName: string | null;
        readonly count: number;
        readonly amount: number;
      }>;
    } | null;
    readonly shippingCostsAmount: {
      readonly amount: number;
      readonly details: ReadonlyArray<{
        readonly giftDraftDeliveryId: string;
        readonly amount: number;
      }>;
    } | null;
    readonly creditCard: {
      readonly id: string;
      readonly brand: string;
      readonly last4: string;
      readonly expMonth: number;
      readonly expYear: number;
    } | null;
    readonly giftDraftSkus: ReadonlyArray<{
      readonly id: string;
      readonly sku: {
        readonly id: string;
        readonly price: number;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly expireAt: string;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
        };
        readonly skuGroupValue1: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly skuGroupValue2: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly shipper: { readonly id: string } | null;
      };
      readonly optionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly price: number;
      }>;
    }>;
    readonly giftDraftDeliveries: ReadonlyArray<{
      readonly id: string;
      readonly deliveryDate: string;
      readonly deliveryTime: string;
      readonly shipper: { readonly id: string; readonly name: string };
      readonly selectedDeliveryOptionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly price: number;
      }>;
    }>;
    readonly customer: {
      readonly id: string;
      readonly name: string;
      readonly nameKana: string;
      readonly postalCode: string;
      readonly city: string;
      readonly building: string;
      readonly phoneNumber: string;
      readonly email: string;
      readonly prefecture: { readonly id: string; readonly name: string };
    } | null;
    readonly consignee: {
      readonly id: string;
      readonly name: string;
      readonly nameKana: string;
      readonly postalCode: string;
      readonly city: string;
      readonly building: string;
      readonly phoneNumber: string;
      readonly prefecture: { readonly id: string; readonly name: string };
    } | null;
  } | null;
};

export type GiftConsigneeQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GiftConsigneeQuery = {
  readonly gift: {
    readonly id: string;
    readonly orderNumber: string;
    readonly consigneeDraft: {
      readonly name: string;
      readonly nameKana: string;
      readonly postalCode: string;
      readonly city: string;
      readonly building: string;
      readonly phoneNumber: string;
      readonly email: string;
      readonly prefecture: { readonly id: string; readonly name: string };
    } | null;
    readonly giftSkus: ReadonlyArray<{
      readonly id: string;
      readonly expireAt: string;
      readonly sku: {
        readonly id: string;
        readonly shipper: { readonly id: string; readonly name: string } | null;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
        };
        readonly skuGroupValue1: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly skuGroupValue2: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
      };
    }>;
    readonly giftDeliveries: ReadonlyArray<{
      readonly id: string;
      readonly deliveryDateDraft: string;
      readonly deliveryTimeDraft: string;
      readonly shipper: { readonly id: string; readonly name: string };
    }> | null;
  } | null;
};

export type GiftDraftAmountDataQueryVariables = Exact<{
  prefectureId: InputMaybe<Scalars["ID"]>;
}>;

export type GiftDraftAmountDataQuery = {
  readonly giftDraft: {
    readonly id: string;
    readonly itemsAmount: number;
    readonly promotionCodeAmount: number | null;
    readonly totalAmount: number;
    readonly optionsAmount: {
      readonly amount: number;
      readonly details: ReadonlyArray<{
        readonly id: string;
        readonly optionName: string;
        readonly optionItemName: string | null;
        readonly count: number;
        readonly amount: number;
      }>;
    } | null;
    readonly shippingCostsAmount: {
      readonly amount: number;
      readonly details: ReadonlyArray<{
        readonly giftDraftDeliveryId: string;
        readonly amount: number;
      }>;
    } | null;
  } | null;
};

export type GiftNewPageDataQueryVariables = Exact<{ [key: string]: never }>;

export type GiftNewPageDataQuery = {
  readonly me: {
    readonly user: {
      readonly id: string;
      readonly avatarImageUrl: string;
    } | null;
  };
  readonly giftDraft: {
    readonly id: string;
    readonly giftFor: GiftFor;
    readonly gifteeName: string;
    readonly gifterName: string;
    readonly message: string;
    readonly itemsAmount: number;
    readonly promotionCodeAmount: number | null;
    readonly totalAmount: number;
    readonly secret: boolean;
    readonly cardDesign: {
      readonly id: string;
      readonly image: { readonly id: string; readonly url: string };
    } | null;
    readonly optionsAmount: {
      readonly amount: number;
      readonly details: ReadonlyArray<{
        readonly id: string;
        readonly optionName: string;
        readonly optionItemName: string | null;
        readonly count: number;
        readonly amount: number;
      }>;
    } | null;
    readonly shippingCostsAmount: {
      readonly amount: number;
      readonly details: ReadonlyArray<{
        readonly giftDraftDeliveryId: string;
        readonly amount: number;
      }>;
    } | null;
    readonly giftDraftSkus: ReadonlyArray<{
      readonly id: string;
      readonly sku: {
        readonly id: string;
        readonly price: number;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly expireAt: string;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
        };
        readonly skuGroupValue1: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly skuGroupValue2: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly shipper: { readonly id: string } | null;
        readonly options: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly optionItems: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly price: number;
            readonly image: { readonly id: string; readonly url: string };
          }>;
        }>;
      };
      readonly optionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly price: number;
        readonly image: { readonly id: string; readonly url: string };
      }>;
    }>;
    readonly giftDraftDeliveries: ReadonlyArray<{
      readonly id: string;
      readonly shipper: { readonly id: string; readonly name: string };
      readonly deliveryOptions: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly deliveryOptionItems: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly price: number;
          readonly image: { readonly id: string; readonly url: string };
        }>;
      }>;
      readonly selectedDeliveryOptionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly price: number;
        readonly image: { readonly id: string; readonly url: string };
      }>;
    }>;
  } | null;
};

export type GiftOrderPageDataQueryVariables = Exact<{ [key: string]: never }>;

export type GiftOrderPageDataQuery = {
  readonly giftDraft: {
    readonly id: string;
    readonly giftFor: GiftFor;
    readonly deliveryMethod: DeliveryMethod | null;
    readonly shipFrom: ShipFrom | null;
    readonly secret: boolean;
    readonly paymentMethod: PaymentMethod;
    readonly customer: {
      readonly id: string;
      readonly name: string;
      readonly nameKana: string;
      readonly postalCode: string;
      readonly city: string;
      readonly building: string;
      readonly phoneNumber: string;
      readonly email: string;
      readonly prefecture: { readonly id: string; readonly name: string };
    } | null;
    readonly consignee: {
      readonly id: string;
      readonly originalConsigneeId: string | null;
      readonly name: string;
      readonly nameKana: string;
      readonly postalCode: string;
      readonly city: string;
      readonly building: string;
      readonly phoneNumber: string;
      readonly email: string;
      readonly prefecture: { readonly id: string; readonly name: string };
    } | null;
    readonly giftDraftSkus: ReadonlyArray<{
      readonly id: string;
      readonly sku: {
        readonly id: string;
        readonly skuGroupValue1: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly skuGroupValue2: {
          readonly id: string;
          readonly name: string;
          readonly group: { readonly id: string; readonly name: string };
        } | null;
        readonly item: {
          readonly id: string;
          readonly name: string;
          readonly receivingWay: ReceivingWay;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
        };
        readonly shipper: { readonly id: string } | null;
      };
      readonly optionItems: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly price: number;
      }>;
    }>;
    readonly giftDraftDeliveries: ReadonlyArray<{
      readonly id: string;
      readonly deliveryDate: string;
      readonly deliveryTime: string;
      readonly shipper: { readonly id: string; readonly name: string };
    }>;
  } | null;
  readonly customer: {
    readonly id: string;
    readonly name: string;
    readonly nameKana: string;
    readonly postalCode: string;
    readonly city: string;
    readonly building: string;
    readonly phoneNumber: string;
    readonly email: string;
    readonly prefecture: { readonly id: string; readonly name: string };
  } | null;
  readonly defaultConsignee: {
    readonly id: string;
    readonly name: string;
    readonly nameKana: string;
    readonly postalCode: string;
    readonly city: string;
    readonly building: string;
    readonly phoneNumber: string;
    readonly email: string;
    readonly prefecture: { readonly id: string; readonly name: string };
  } | null;
  readonly defaultCreditCard: { readonly id: string } | null;
  readonly setting: { readonly id: string; readonly email: string } | null;
};

export type GiftSkuQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GiftSkuQuery = {
  readonly giftSku: {
    readonly id: string;
    readonly receivingWay: ReceivingWay;
    readonly expireAt: string;
    readonly usedAt: string | null;
    readonly sku: {
      readonly id: string;
      readonly item: {
        readonly id: string;
        readonly name: string;
        readonly minSkuPrice: number;
        readonly isSkuPriceConstant: boolean;
        readonly onSale: boolean;
        readonly expireAt: string;
        readonly attention: string;
        readonly reconciliationWay: ReconciliationWay;
        readonly shortUsage: string;
        readonly usageExplanations: ReadonlyArray<string>;
        readonly liked: boolean | null;
        readonly wished: boolean | null;
        readonly brand: {
          readonly id: string;
          readonly brandName: string;
          readonly displayName: string;
          readonly pinCodes: ReadonlyArray<string>;
          readonly logoImage: {
            readonly id: string;
            readonly url: string;
          } | null;
          readonly stores: ReadonlyArray<{
            readonly id: string;
            readonly municipalities: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
              readonly area: {
                readonly id: string;
                readonly name: string;
                readonly prefecture: {
                  readonly id: string;
                  readonly name: string;
                };
              };
            }>;
          }>;
        };
        readonly images: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly topImage: {
          readonly id: string;
          readonly url: string;
          readonly width: number;
          readonly height: number;
        };
        readonly description: {
          readonly renderers: ReadonlyArray<
            | {
                readonly type: DescriptionRendererType;
                readonly url: string;
                readonly width: number;
                readonly height: number;
              }
            | {
                readonly type: DescriptionRendererType;
                readonly size: DescriptionTextRendererSize;
                readonly weight: DescriptionTextRendererWeight;
                readonly color: DescriptionTextRendererColor;
                readonly text: string;
              }
          >;
        };
        readonly allergies: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }>;
        readonly skus: ReadonlyArray<{
          readonly id: string;
          readonly price: number;
          readonly onSale: boolean;
          readonly liked: boolean | null;
          readonly wished: boolean | null;
          readonly skuGroupValue1: {
            readonly id: string;
            readonly name: string;
            readonly image: {
              readonly id: string;
              readonly url: string;
            } | null;
            readonly group: { readonly id: string; readonly name: string };
          } | null;
          readonly skuGroupValue2: {
            readonly id: string;
            readonly name: string;
            readonly image: {
              readonly id: string;
              readonly url: string;
            } | null;
            readonly group: { readonly id: string; readonly name: string };
          } | null;
          readonly shippingCosts: ReadonlyArray<{
            readonly id: string;
            readonly cost: number;
            readonly prefecture: { readonly id: string; readonly name: string };
          }> | null;
          readonly item: {
            readonly id: string;
            readonly liked: boolean | null;
            readonly wished: boolean | null;
          };
          readonly options: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly optionItems: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
              readonly price: number;
              readonly image: { readonly id: string; readonly url: string };
            }>;
          }>;
        }>;
      };
      readonly shipper: { readonly id: string } | null;
      readonly skuGroupValue1: {
        readonly id: string;
        readonly name: string;
        readonly group: { readonly id: string; readonly name: string };
      } | null;
      readonly skuGroupValue2: {
        readonly id: string;
        readonly name: string;
        readonly group: { readonly id: string; readonly name: string };
      } | null;
    };
    readonly gift: {
      readonly id: string;
      readonly orderNumber: string;
      readonly secret: boolean;
      readonly giftDeliveries: ReadonlyArray<{
        readonly id: string;
        readonly shipper: { readonly id: string };
      }> | null;
    };
  } | null;
};

export type IncreaseGiftDraftSkuMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IncreaseGiftDraftSkuMutation = {
  readonly increaseGiftDraftSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly giftDraft: {
      readonly id: string;
      readonly itemsAmount: number;
      readonly giftDraftSkus: ReadonlyArray<{ readonly id: string }>;
    } | null;
  };
};

export type InquireMutationVariables = Exact<{
  input: InquireInput;
}>;

export type InquireMutation = {
  readonly inquire: {
    readonly code: MutationResponseCode;
    readonly message: string;
  };
};

export type ItemEditPageDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemEditPageDataQuery = {
  readonly item: {
    readonly id: string;
    readonly name: string;
    readonly minSkuPrice: number;
    readonly onSale: boolean;
    readonly priority: number;
    readonly availableRegion: AvailableRegion;
    readonly receivingWay: ReceivingWay;
    readonly expireTimeUnit: ExpireTimeUnit;
    readonly expireAfter: number;
    readonly attention: string;
    readonly shortUsage: string;
    readonly usageExplanations: ReadonlyArray<string>;
    readonly reconciliationWay: ReconciliationWay;
    readonly relations: ReadonlyArray<AnniversaryUserRelation>;
    readonly images: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
      readonly width: number;
      readonly height: number;
    }>;
    readonly description: {
      readonly renderers: ReadonlyArray<
        | {
            readonly type: DescriptionRendererType;
            readonly url: string;
            readonly width: number;
            readonly height: number;
          }
        | {
            readonly type: DescriptionRendererType;
            readonly size: DescriptionTextRendererSize;
            readonly weight: DescriptionTextRendererWeight;
            readonly color: DescriptionTextRendererColor;
            readonly text: string;
          }
      >;
    };
    readonly itemCategory: { readonly id: string };
    readonly allergies: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly scenes: ReadonlyArray<{ readonly id: string }>;
  } | null;
};

export type ItemListFormDataQueryVariables = Exact<{ [key: string]: never }>;

export type ItemListFormDataQuery = {
  readonly brands: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: { readonly id: string; readonly displayName: string };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
  readonly itemCategoryGroups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly subgroups: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly categories: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    }>;
  }>;
  readonly itemScenes: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly allergies: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly municipalities: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};

export type ItemListPageInitialDataQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  input: ItemsInput;
}>;

export type ItemListPageInitialDataQuery = {
  readonly items: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly minSkuPrice: number;
        readonly isSkuPriceConstant: boolean;
        readonly topImage: { readonly id: string; readonly url: string };
        readonly brand: { readonly id: string; readonly displayName: string };
        readonly municipalities: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }>;
        readonly skus: ReadonlyArray<{ readonly id: string }>;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly endCursor: string | null;
      readonly startCursor: string | null;
    };
  };
};

export type ValueFieldsFragment = {
  readonly id: string;
  readonly name: string;
  readonly image: { readonly id: string; readonly url: string } | null;
  readonly group: { readonly id: string; readonly name: string };
};

export type ItemPageInitialDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemPageInitialDataQuery = {
  readonly item: {
    readonly id: string;
    readonly name: string;
    readonly minSkuPrice: number;
    readonly isSkuPriceConstant: boolean;
    readonly onSale: boolean;
    readonly expireAt: string;
    readonly attention: string;
    readonly shortUsage: string;
    readonly usageExplanations: ReadonlyArray<string>;
    readonly liked: boolean | null;
    readonly wished: boolean | null;
    readonly brand: {
      readonly id: string;
      readonly brandName: string;
      readonly displayName: string;
      readonly logoImage: { readonly id: string; readonly url: string } | null;
      readonly stores: ReadonlyArray<{
        readonly id: string;
        readonly municipalities: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly area: {
            readonly id: string;
            readonly name: string;
            readonly prefecture: { readonly id: string; readonly name: string };
          };
        }>;
      }>;
    };
    readonly images: ReadonlyArray<{
      readonly id: string;
      readonly url: string;
    }>;
    readonly topImage: { readonly id: string; readonly url: string };
    readonly description: {
      readonly renderers: ReadonlyArray<
        | {
            readonly type: DescriptionRendererType;
            readonly url: string;
            readonly width: number;
            readonly height: number;
          }
        | {
            readonly type: DescriptionRendererType;
            readonly size: DescriptionTextRendererSize;
            readonly weight: DescriptionTextRendererWeight;
            readonly color: DescriptionTextRendererColor;
            readonly text: string;
          }
      >;
    };
    readonly allergies: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly skus: ReadonlyArray<{
      readonly id: string;
      readonly price: number;
      readonly onSale: boolean;
      readonly liked: boolean | null;
      readonly wished: boolean | null;
      readonly skuGroupValue1: {
        readonly id: string;
        readonly name: string;
        readonly image: { readonly id: string; readonly url: string } | null;
        readonly group: { readonly id: string; readonly name: string };
      } | null;
      readonly skuGroupValue2: {
        readonly id: string;
        readonly name: string;
        readonly image: { readonly id: string; readonly url: string } | null;
        readonly group: { readonly id: string; readonly name: string };
      } | null;
      readonly shippingCosts: ReadonlyArray<{
        readonly id: string;
        readonly cost: number;
        readonly prefecture: { readonly id: string; readonly name: string };
      }> | null;
      readonly item: {
        readonly id: string;
        readonly liked: boolean | null;
        readonly wished: boolean | null;
      };
      readonly options: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly optionItems: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly price: number;
          readonly image: { readonly id: string; readonly url: string };
        }>;
      }>;
    }>;
  } | null;
};

export type ItemPageUserDataQueryVariables = Exact<{
  itemId: Scalars["ID"];
  brandName: Scalars["String"];
}>;

export type ItemPageUserDataQuery = {
  readonly item: {
    readonly id: string;
    readonly skus: ReadonlyArray<{
      readonly id: string;
      readonly price: number;
      readonly onSale: boolean;
      readonly liked: boolean | null;
      readonly wished: boolean | null;
      readonly skuGroupValue1: {
        readonly id: string;
        readonly name: string;
        readonly image: { readonly id: string; readonly url: string } | null;
        readonly group: { readonly id: string; readonly name: string };
      } | null;
      readonly skuGroupValue2: {
        readonly id: string;
        readonly name: string;
        readonly image: { readonly id: string; readonly url: string } | null;
        readonly group: { readonly id: string; readonly name: string };
      } | null;
      readonly shippingCosts: ReadonlyArray<{
        readonly id: string;
        readonly cost: number;
        readonly prefecture: { readonly id: string; readonly name: string };
      }> | null;
      readonly item: {
        readonly id: string;
        readonly liked: boolean | null;
        readonly wished: boolean | null;
      };
      readonly options: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly optionItems: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly price: number;
          readonly image: { readonly id: string; readonly url: string };
        }>;
      }>;
    }>;
  } | null;
  readonly brand: {
    readonly id: string;
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly minSkuPrice: number;
          readonly isSkuPriceConstant: boolean;
          readonly liked: boolean | null;
          readonly topImage: { readonly id: string; readonly url: string };
          readonly brand: { readonly id: string; readonly displayName: string };
          readonly municipalities: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
          }>;
          readonly skus: ReadonlyArray<{ readonly id: string }>;
        };
      }>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
    };
  } | null;
};

export type ItemScenesQueryVariables = Exact<{ [key: string]: never }>;

export type ItemScenesQuery = {
  readonly itemScenes: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly image: { readonly id: string; readonly url: string };
  }>;
};

export type ItemsQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
  last: InputMaybe<Scalars["Int"]>;
  before: InputMaybe<Scalars["String"]>;
  input: InputMaybe<ItemsInput>;
}>;

export type ItemsQuery = {
  readonly items: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly minSkuPrice: number;
        readonly isSkuPriceConstant: boolean;
        readonly liked: boolean | null;
        readonly topImage: { readonly id: string; readonly url: string };
        readonly brand: {
          readonly id: string;
          readonly brandName: string;
          readonly displayName: string;
        };
        readonly municipalities: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }>;
        readonly skus: ReadonlyArray<{ readonly id: string }>;
      };
    }>;
    readonly pageInfo: {
      readonly hasPreviousPage: boolean;
      readonly hasNextPage: boolean;
      readonly startCursor: string | null;
      readonly endCursor: string | null;
    };
  };
};

export type LeadTimesAndDeliveryTimesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LeadTimesAndDeliveryTimesQuery = {
  readonly deliveryTimes: ReadonlyArray<string>;
  readonly leadTimes: ReadonlyArray<{
    readonly id: string;
    readonly dayCount: number;
    readonly prefecture: { readonly id: string };
  }>;
};

export type LikeSkuMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LikeSkuMutation = {
  readonly likeSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly sku: {
      readonly id: string;
      readonly liked: boolean | null;
    } | null;
  };
};

export type LikesQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
}>;

export type LikesQuery = {
  readonly me: {
    readonly user: {
      readonly id: string;
      readonly likes: {
        readonly totalCount: number;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly price: number;
            readonly liked: boolean | null;
            readonly wished: boolean | null;
            readonly item: {
              readonly id: string;
              readonly name: string;
              readonly topImage: { readonly id: string; readonly url: string };
              readonly brand: {
                readonly id: string;
                readonly displayName: string;
              };
            };
            readonly skuGroupValue1: {
              readonly id: string;
              readonly name: string;
              readonly group: { readonly id: string; readonly name: string };
            } | null;
            readonly skuGroupValue2: {
              readonly id: string;
              readonly name: string;
              readonly group: { readonly id: string; readonly name: string };
            } | null;
          };
        }>;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly endCursor: string | null;
        };
      } | null;
    } | null;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  readonly me: {
    readonly status: MeStatus;
    readonly user: {
      readonly id: string;
      readonly role: UserRole | null;
      readonly displayName: string;
      readonly userName: string;
      readonly avatarImageUrl: string;
    } | null;
  };
};

export type MyPagePageDataQueryVariables = Exact<{ [key: string]: never }>;

export type MyPagePageDataQuery = {
  readonly me: {
    readonly user: {
      readonly id: string;
      readonly userName: string;
      readonly displayName: string;
      readonly biography: string;
      readonly avatarImageUrl: string;
      readonly private: boolean;
      readonly birthYear: number | null;
      readonly birthMonth: number | null;
      readonly birthDay: number | null;
      readonly showYear: boolean;
      readonly followeeCount: number;
      readonly followerCount: number;
      readonly municipalities: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null;
      readonly allergies: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null;
    } | null;
  };
};

export type NotificationsQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
}>;

export type NotificationsQuery = {
  readonly notifications: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
        readonly notifiedAt: string;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type PrefecturesQueryVariables = Exact<{ [key: string]: never }>;

export type PrefecturesQuery = {
  readonly prefectures: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};

export type ProfileEditPageDataQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileEditPageDataQuery = {
  readonly me: {
    readonly user: {
      readonly id: string;
      readonly displayName: string;
      readonly userName: string;
      readonly biography: string;
      readonly avatarImageUrl: string;
      readonly birthYear: number | null;
      readonly birthMonth: number | null;
      readonly birthDay: number | null;
      readonly showYear: boolean;
      readonly private: boolean;
      readonly allergies: ReadonlyArray<{ readonly id: string }> | null;
      readonly municipalities: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly area: {
          readonly id: string;
          readonly name: string;
          readonly prefecture: { readonly id: string; readonly name: string };
        };
      }> | null;
    } | null;
  };
};

export type PromotionCodeQueryVariables = Exact<{ [key: string]: never }>;

export type PromotionCodeQuery = {
  readonly giftDraft: {
    readonly id: string;
    readonly promotionCode: string | null;
  } | null;
};

export type PurchaseGiftMutationVariables = Exact<{ [key: string]: never }>;

export type PurchaseGiftMutation = {
  readonly purchaseGift: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly gift: { readonly id: string } | null;
  };
};

export type PushNotificationSettingPageDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PushNotificationSettingPageDataQuery = {
  readonly setting: {
    readonly id: string;
    readonly pushNotifications: ReadonlyArray<PushNotificationType>;
  } | null;
};

export type ReceivedFollowRequestsQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
}>;

export type ReceivedFollowRequestsQuery = {
  readonly receivedFollowRequests: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly userName: string;
        readonly displayName: string;
        readonly avatarImageUrl: string;
        readonly relationStatus: UserRelationStatus | null;
        readonly followingMe: boolean;
        readonly private: boolean;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type ReconcileGiftSkuMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ReconcileGiftSkuMutation = {
  readonly reconcileGiftSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly giftSku: {
      readonly id: string;
      readonly usedAt: string | null;
    } | null;
  };
};

export type RejectFollowRequestMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type RejectFollowRequestMutation = {
  readonly rejectFollowRequest: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly user: {
      readonly id: string;
      readonly followingMe: boolean;
    } | null;
  };
};

export type RemoveCreditCardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveCreditCardMutation = {
  readonly removeCreditCard: {
    readonly code: MutationResponseCode;
    readonly message: string;
  };
};

export type ReportUserMutationVariables = Exact<{
  id: Scalars["ID"];
  input: ReportUserInput;
}>;

export type ReportUserMutation = {
  readonly reportUser: {
    readonly code: MutationResponseCode;
    readonly message: string;
  };
};

export type SaveGiftMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SaveGiftMutation = {
  readonly saveGift: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly gift: {
      readonly id: string;
      readonly savedAt: string | null;
    } | null;
  };
};

export type SavedGiftSkusQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
  input: SavedGiftSkusInput;
}>;

export type SavedGiftSkusQuery = {
  readonly savedGiftSkus: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly receivingWay: ReceivingWay;
        readonly expireAt: string;
        readonly usedAt: string | null;
        readonly gift: {
          readonly id: string;
          readonly gifterName: string;
          readonly secret: boolean;
        };
        readonly sku: {
          readonly id: string;
          readonly item: {
            readonly id: string;
            readonly name: string;
            readonly brand: {
              readonly id: string;
              readonly displayName: string;
            };
            readonly topImage: { readonly id: string; readonly url: string };
          };
          readonly skuGroupValue1: {
            readonly id: string;
            readonly name: string;
            readonly group: { readonly id: string; readonly name: string };
          } | null;
          readonly skuGroupValue2: {
            readonly id: string;
            readonly name: string;
            readonly group: { readonly id: string; readonly name: string };
          } | null;
        };
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type SavedGiftsQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
}>;

export type SavedGiftsQuery = {
  readonly savedGifts: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly gifterName: string;
        readonly savedAt: string | null;
        readonly message: string;
        readonly gifter: {
          readonly id: string;
          readonly avatarImageUrl: string;
        };
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type SearchUsersQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
  input: SearchUsersInput;
}>;

export type SearchUsersQuery = {
  readonly searchUsers: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly userName: string;
        readonly displayName: string;
        readonly avatarImageUrl: string;
        readonly relationStatus: UserRelationStatus | null;
        readonly followingMe: boolean;
        readonly private: boolean;
      };
    }>;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
  };
};

export type SettingQueryVariables = Exact<{ [key: string]: never }>;

export type SettingQuery = {
  readonly setting: { readonly id: string; readonly email: string } | null;
};

export type ShippersQueryVariables = Exact<{ [key: string]: never }>;

export type ShippersQuery = {
  readonly shippers: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  readonly signUp: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type SpotsQueryVariables = Exact<{ [key: string]: never }>;

export type SpotsQuery = {
  readonly prefectures: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly areas: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly municipalities: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    }>;
  }>;
};

export type StepLabelDataQueryVariables = Exact<{ [key: string]: never }>;

export type StepLabelDataQuery = {
  readonly giftDraft: {
    readonly id: string;
    readonly giftDraftDeliveries: ReadonlyArray<{
      readonly id: string;
      readonly shipper: { readonly id: string };
      readonly deliveryOptions: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly deliveryOptionItems: ReadonlyArray<{ readonly id: string }>;
      }>;
    }>;
    readonly giftDraftSkus: ReadonlyArray<{
      readonly id: string;
      readonly sku: {
        readonly options: ReadonlyArray<{ readonly id: string }>;
      };
    }>;
  } | null;
};

export type StoreEditPageDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type StoreEditPageDataQuery = {
  readonly store: {
    readonly name: string;
    readonly address: string;
    readonly transportation: string;
    readonly businessHours: string;
    readonly phoneNumber: string;
    readonly googleMapSrc: string;
    readonly municipalities: ReadonlyArray<{ readonly id: string }>;
  } | null;
};

export type TransferAnniversariesMutationVariables = Exact<{
  input: TransferAnniversariesInput;
}>;

export type TransferAnniversariesMutation = {
  readonly transferAnniversaries: {
    readonly code: MutationResponseCode;
    readonly message: string;
  };
};

export type UnblockUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnblockUserMutation = {
  readonly unblockUser: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly user: {
      readonly id: string;
      readonly relationStatus: UserRelationStatus | null;
    } | null;
  };
};

export type UnfollowUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnfollowUserMutation = {
  readonly unfollowUser: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly user: {
      readonly id: string;
      readonly relationStatus: UserRelationStatus | null;
    } | null;
  };
};

export type UnlikeSkuMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnlikeSkuMutation = {
  readonly unlikeSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly sku: {
      readonly id: string;
      readonly liked: boolean | null;
    } | null;
  };
};

export type UnwishSkuMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnwishSkuMutation = {
  readonly unwishSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly sku: {
      readonly id: string;
      readonly wished: boolean | null;
    } | null;
  };
};

export type UpdateAnniversaryMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateAnniversaryInput;
}>;

export type UpdateAnniversaryMutation = {
  readonly updateAnniversary: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type UpdateAnniversaryUserMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateAnniversaryUserInput;
}>;

export type UpdateAnniversaryUserMutation = {
  readonly updateAnniversaryUser: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type UpdateBrandMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateBrandInput;
}>;

export type UpdateBrandMutation = {
  readonly updateBrand: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly brand: { readonly id: string; readonly brandName: string } | null;
  };
};

export type UpdateConsigneeMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateConsigneeInput;
}>;

export type UpdateConsigneeMutation = {
  readonly updateConsignee: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type UpdateGiftConsigneeDraftMutationVariables = Exact<{
  giftId: Scalars["ID"];
  input: UpdateGiftConsigneeDraftInput;
}>;

export type UpdateGiftConsigneeDraftMutation = {
  readonly updateGiftConsigneeDraft: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type UpdateGiftDeliveryMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateGiftDeliveryInput;
}>;

export type UpdateGiftDeliveryMutation = {
  readonly updateGiftDelivery: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type UpdateGiftDraftMutationVariables = Exact<{
  input: UpdateGiftDraftInput;
}>;

export type UpdateGiftDraftMutation = {
  readonly updateGiftDraft: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly giftDraft: {
      readonly id: string;
      readonly secret: boolean;
    } | null;
  };
};

export type UpdateGiftDraftDeliveryMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateGiftDraftDeliveryInput;
}>;

export type UpdateGiftDraftDeliveryMutation = {
  readonly updateGiftDraftDelivery: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly giftDraftDelivery: {
      readonly id: string;
      readonly giftDraft: {
        readonly id: string;
        readonly itemsAmount: number;
        readonly totalAmount: number;
        readonly optionsAmount: {
          readonly amount: number;
          readonly details: ReadonlyArray<{
            readonly id: string;
            readonly optionName: string;
            readonly optionItemName: string | null;
            readonly count: number;
            readonly amount: number;
          }>;
        } | null;
      };
      readonly selectedDeliveryOptionItems: ReadonlyArray<{
        readonly id: string;
      }>;
    } | null;
  };
};

export type UpdateGiftDraftSkuMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateGiftDraftSkuInput;
}>;

export type UpdateGiftDraftSkuMutation = {
  readonly updateGiftDraftSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly giftDraft: {
      readonly id: string;
      readonly itemsAmount: number;
      readonly totalAmount: number;
      readonly optionsAmount: {
        readonly amount: number;
        readonly details: ReadonlyArray<{
          readonly id: string;
          readonly optionName: string;
          readonly optionItemName: string | null;
          readonly count: number;
          readonly amount: number;
        }>;
      } | null;
      readonly giftDraftSkus: ReadonlyArray<{
        readonly id: string;
        readonly optionItems: ReadonlyArray<{ readonly id: string }>;
      }>;
    } | null;
  };
};

export type UpdateGiftOrderMutationVariables = Exact<{
  input: UpdateGiftOrderInput;
}>;

export type UpdateGiftOrderMutation = {
  readonly updateGiftOrder: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type UpdateItemMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateItemInput;
}>;

export type UpdateItemMutation = {
  readonly updateItem: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
  };
};

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type UpdateMeMutation = {
  readonly updateMe: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly me: {
      readonly id: string;
      readonly displayName: string;
      readonly userName: string;
      readonly biography: string;
      readonly avatarImageUrl: string;
      readonly birthYear: number | null;
      readonly birthMonth: number | null;
      readonly birthDay: number | null;
      readonly showYear: boolean;
      readonly private: boolean;
      readonly allergies: ReadonlyArray<{ readonly id: string }> | null;
      readonly municipalities: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly area: {
          readonly id: string;
          readonly name: string;
          readonly prefecture: { readonly id: string; readonly name: string };
        };
      }> | null;
    } | null;
  };
};

export type UpdateSettingMutationVariables = Exact<{
  input: UpdateSettingInput;
}>;

export type UpdateSettingMutation = {
  readonly updateSetting: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly setting: {
      readonly id: string;
      readonly emailNotifications: ReadonlyArray<EmailNotificationType>;
      readonly pushNotifications: ReadonlyArray<PushNotificationType>;
    } | null;
  };
};

export type UpdateStoreMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateStoreInput;
}>;

export type UpdateStoreMutation = {
  readonly updateStore: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly errors: ReadonlyArray<{
      readonly key: string;
      readonly message: string;
    }> | null;
    readonly store: {
      readonly id: string;
      readonly brand: { readonly id: string; readonly brandName: string };
    } | null;
  };
};

export type UploadImageMutationVariables = Exact<{
  image: Scalars["Upload"];
}>;

export type UploadImageMutation = {
  readonly uploadImage: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly image: {
      readonly id: string;
      readonly url: string;
      readonly width: number;
      readonly height: number;
    } | null;
  };
};

export type UploadImageByUrlMutationVariables = Exact<{
  url: Scalars["String"];
}>;

export type UploadImageByUrlMutation = {
  readonly uploadImageByUrl: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly image: { readonly id: string } | null;
  };
};

export type UserAnniversariesQueryVariables = Exact<{
  userName: Scalars["String"];
}>;

export type UserAnniversariesQuery = {
  readonly user: {
    readonly id: string;
    readonly anniversaries: ReadonlyArray<{
      readonly id: string;
      readonly anniversaryUser: {
        readonly name: string;
        readonly relation: AnniversaryUserRelation;
        readonly user: {
          readonly id: string;
          readonly avatarImageUrl: string;
        } | null;
      };
      readonly repetitions: ReadonlyArray<{
        readonly id: string;
        readonly type: AnniversaryType;
        readonly name: string;
        readonly frequency: AnniversaryFrequency;
        readonly private: boolean;
        readonly start: {
          readonly year: number;
          readonly month: number;
          readonly day: number;
        };
        readonly until: {
          readonly year: number;
          readonly month: number;
          readonly day: number;
        } | null;
      }>;
    }> | null;
  } | null;
};

export type UserFolloweesDataQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
  userName: Scalars["String"];
}>;

export type UserFolloweesDataQuery = {
  readonly user: {
    readonly id: string;
    readonly userName: string;
    readonly displayName: string;
    readonly followeeCount: number;
    readonly followerCount: number;
    readonly followees: {
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly userName: string;
          readonly displayName: string;
          readonly avatarImageUrl: string;
          readonly relationStatus: UserRelationStatus | null;
          readonly followingMe: boolean;
          readonly private: boolean;
        };
      }>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
    } | null;
  } | null;
};

export type UserFollowersDataQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
  userName: Scalars["String"];
}>;

export type UserFollowersDataQuery = {
  readonly user: {
    readonly id: string;
    readonly userName: string;
    readonly displayName: string;
    readonly private: boolean;
    readonly followeeCount: number;
    readonly followerCount: number;
    readonly followers: {
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly userName: string;
          readonly displayName: string;
          readonly avatarImageUrl: string;
          readonly relationStatus: UserRelationStatus | null;
          readonly followingMe: boolean;
          readonly private: boolean;
        };
      }>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
    } | null;
  } | null;
  readonly receivedFollowRequests: { readonly totalCount: number };
};

export type UserNameAvailabilityQueryVariables = Exact<{
  userName: Scalars["String"];
}>;

export type UserNameAvailabilityQuery = {
  readonly userNameAvailability: { readonly available: boolean };
};

export type UserPageInitialDataQueryVariables = Exact<{
  userName: Scalars["String"];
}>;

export type UserPageInitialDataQuery = {
  readonly user: {
    readonly id: string;
    readonly userName: string;
    readonly displayName: string;
    readonly biography: string;
    readonly avatarImageUrl: string;
    readonly private: boolean;
    readonly birthYear: number | null;
    readonly birthMonth: number | null;
    readonly birthDay: number | null;
    readonly showYear: boolean;
    readonly followeeCount: number;
    readonly followerCount: number;
    readonly municipalities: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly allergies: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
  } | null;
};

export type UserPageUserDataQueryVariables = Exact<{
  userName: Scalars["String"];
}>;

export type UserPageUserDataQuery = {
  readonly user: {
    readonly id: string;
    readonly biography: string;
    readonly birthYear: number | null;
    readonly birthMonth: number | null;
    readonly birthDay: number | null;
    readonly relationStatus: UserRelationStatus | null;
    readonly followingMe: boolean;
    readonly blockingMe: boolean;
    readonly municipalities: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly allergies: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
  } | null;
};

export type UserWishListPageDataQueryVariables = Exact<{
  userName: Scalars["String"];
}>;

export type UserWishListPageDataQuery = {
  readonly user: { readonly id: string; readonly displayName: string } | null;
};

export type UserWishesQueryVariables = Exact<{
  userName: Scalars["String"];
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
}>;

export type UserWishesQuery = {
  readonly user: {
    readonly id: string;
    readonly wishes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly price: number;
          readonly liked: boolean | null;
          readonly item: {
            readonly id: string;
            readonly name: string;
            readonly topImage: { readonly id: string; readonly url: string };
            readonly brand: {
              readonly id: string;
              readonly displayName: string;
            };
            readonly municipalities: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
            }>;
          };
          readonly skuGroupValue1: {
            readonly id: string;
            readonly name: string;
            readonly group: { readonly id: string; readonly name: string };
          } | null;
          readonly skuGroupValue2: {
            readonly id: string;
            readonly name: string;
            readonly group: { readonly id: string; readonly name: string };
          } | null;
        };
      }>;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
    } | null;
  } | null;
};

export type WishSkuMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type WishSkuMutation = {
  readonly wishSku: {
    readonly code: MutationResponseCode;
    readonly message: string;
    readonly sku: {
      readonly id: string;
      readonly wished: boolean | null;
    } | null;
  };
};

export type WishesQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["String"]>;
}>;

export type WishesQuery = {
  readonly me: {
    readonly user: {
      readonly id: string;
      readonly wishes: {
        readonly totalCount: number;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly price: number;
            readonly liked: boolean | null;
            readonly wished: boolean | null;
            readonly item: {
              readonly id: string;
              readonly name: string;
              readonly topImage: { readonly id: string; readonly url: string };
              readonly brand: {
                readonly id: string;
                readonly displayName: string;
              };
            };
            readonly skuGroupValue1: {
              readonly id: string;
              readonly name: string;
              readonly group: { readonly id: string; readonly name: string };
            } | null;
            readonly skuGroupValue2: {
              readonly id: string;
              readonly name: string;
              readonly group: { readonly id: string; readonly name: string };
            } | null;
          };
        }>;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly endCursor: string | null;
        };
      } | null;
    } | null;
  };
};

export type ItemCategoryGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type ItemCategoryGroupsQuery = {
  readonly itemCategoryGroups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly subgroups: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly categories: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    }>;
  }>;
};

export const GiftDraftSkuFieldsFragmentDoc = gql`
  fragment GiftDraftSkuFields on GiftDraftSku {
    id
    sku {
      id
      item {
        id
        topImage {
          id
          url
        }
        name
        brand {
          id
          displayName
        }
        expireAt
      }
      skuGroupValue1 {
        id
        name
        group {
          id
          name
        }
      }
      skuGroupValue2 {
        id
        name
        group {
          id
          name
        }
      }
      shipper {
        id
      }
      onSale
      price
    }
    optionItems {
      id
      name
      price
    }
  }
`;
export const ValueFieldsFragmentDoc = gql`
  fragment ValueFields on SkuGroupValue {
    id
    name
    image {
      id
      url
    }
    group {
      id
      name
    }
  }
`;
export const AccountPrivacyDataDocument = gql`
  query AccountPrivacyData {
    me {
      user {
        id
        private
      }
    }
  }
`;

export function useAccountPrivacyDataQuery(
  options?: Omit<Urql.UseQueryArgs<AccountPrivacyDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    AccountPrivacyDataQuery,
    AccountPrivacyDataQueryVariables
  >({ query: AccountPrivacyDataDocument, ...options });
}
export const AddCreditCardDocument = gql`
  mutation AddCreditCard($input: AddCreditCardInput!) {
    addCreditCard(input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useAddCreditCardMutation() {
  return Urql.useMutation<
    AddCreditCardMutation,
    AddCreditCardMutationVariables
  >(AddCreditCardDocument);
}
export const AddGiftDraftSkuDocument = gql`
  mutation AddGiftDraftSku($skuId: ID!, $input: AddGiftDraftSkuInput) {
    addGiftDraftSku(skuId: $skuId, input: $input) {
      code
      message
      giftDraft {
        id
        itemsAmount
        giftDraftSkus {
          id
          sku {
            id
            item {
              id
              topImage {
                id
                url
              }
              name
              brand {
                id
                displayName
              }
              expireAt
            }
            price
          }
        }
      }
    }
  }
`;

export function useAddGiftDraftSkuMutation() {
  return Urql.useMutation<
    AddGiftDraftSkuMutation,
    AddGiftDraftSkuMutationVariables
  >(AddGiftDraftSkuDocument);
}
export const AllergiesDocument = gql`
  query Allergies {
    allergies {
      id
      name
    }
  }
`;

export function useAllergiesQuery(
  options?: Omit<Urql.UseQueryArgs<AllergiesQueryVariables>, "query">,
) {
  return Urql.useQuery<AllergiesQuery, AllergiesQueryVariables>({
    query: AllergiesDocument,
    ...options,
  });
}
export const AnniversariesDocument = gql`
  query Anniversaries {
    anniversaries {
      id
      anniversaryUser {
        id
        user {
          id
          avatarImageUrl
        }
        name
        relation
      }
      repetitions {
        id
        start {
          year
          month
          day
        }
        until {
          year
          month
          day
        }
        type
        name
        frequency
        private
      }
    }
  }
`;

export function useAnniversariesQuery(
  options?: Omit<Urql.UseQueryArgs<AnniversariesQueryVariables>, "query">,
) {
  return Urql.useQuery<AnniversariesQuery, AnniversariesQueryVariables>({
    query: AnniversariesDocument,
    ...options,
  });
}
export const AnniversaryCreatePageDataDocument = gql`
  query AnniversaryCreatePageData {
    ownAnniversaryUser {
      id
    }
  }
`;

export function useAnniversaryCreatePageDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<AnniversaryCreatePageDataQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    AnniversaryCreatePageDataQuery,
    AnniversaryCreatePageDataQueryVariables
  >({ query: AnniversaryCreatePageDataDocument, ...options });
}
export const AnniversaryEditPageDataDocument = gql`
  query AnniversaryEditPageData {
    ownAnniversaryUser {
      id
    }
  }
`;

export function useAnniversaryEditPageDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<AnniversaryEditPageDataQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    AnniversaryEditPageDataQuery,
    AnniversaryEditPageDataQueryVariables
  >({ query: AnniversaryEditPageDataDocument, ...options });
}
export const AnniversaryPageDataDocument = gql`
  query AnniversaryPageData($id: ID!) {
    anniversaryRepetition(id: $id) {
      id
      anniversary {
        id
        anniversaryUser {
          id
          user {
            id
            userName
            avatarImageUrl
            wishes(first: 4) {
              edges {
                node {
                  id
                  item {
                    id
                    topImage {
                      id
                      url
                    }
                    name
                    brand {
                      id
                      displayName
                    }
                    municipalities {
                      id
                      name
                    }
                  }
                  skuGroupValue1 {
                    id
                    name
                    group {
                      id
                      name
                    }
                  }
                  skuGroupValue2 {
                    id
                    name
                    group {
                      id
                      name
                    }
                  }
                  price
                  liked
                }
              }
              totalCount
            }
          }
          name
          relation
        }
      }
      type
      name
    }
  }
`;

export function useAnniversaryPageDataQuery(
  options: Omit<Urql.UseQueryArgs<AnniversaryPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    AnniversaryPageDataQuery,
    AnniversaryPageDataQueryVariables
  >({ query: AnniversaryPageDataDocument, ...options });
}
export const AnniversaryRecommendedItemsDocument = gql`
  query AnniversaryRecommendedItems(
    $anniversaryRepetitionId: ID!
    $first: Int
    $after: String
  ) {
    anniversaryRepetition(id: $anniversaryRepetitionId) {
      id
      recommendedItems(first: $first, after: $after) {
        edges {
          node {
            id
            topImage {
              id
              url
            }
            name
            brand {
              id
              displayName
            }
            minSkuPrice
            isSkuPriceConstant
            liked
            municipalities {
              id
              name
            }
            skus {
              id
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
`;

export function useAnniversaryRecommendedItemsQuery(
  options: Omit<
    Urql.UseQueryArgs<AnniversaryRecommendedItemsQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    AnniversaryRecommendedItemsQuery,
    AnniversaryRecommendedItemsQueryVariables
  >({ query: AnniversaryRecommendedItemsDocument, ...options });
}
export const AnniversaryRepetitionDocument = gql`
  query AnniversaryRepetition($id: ID!) {
    anniversaryRepetition(id: $id) {
      id
      anniversary {
        id
        anniversaryUser {
          id
          user {
            id
          }
        }
        repetitions {
          id
          start {
            year
            month
            day
          }
          until {
            year
            month
            day
          }
          type
          name
          frequency
          remindTimings
          private
          memo
        }
      }
      start {
        year
        month
        day
      }
      until {
        year
        month
        day
      }
      type
      name
      frequency
      remindTimings
      private
      memo
    }
  }
`;

export function useAnniversaryRepetitionQuery(
  options: Omit<
    Urql.UseQueryArgs<AnniversaryRepetitionQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    AnniversaryRepetitionQuery,
    AnniversaryRepetitionQueryVariables
  >({ query: AnniversaryRepetitionDocument, ...options });
}
export const AnniversaryUsersDocument = gql`
  query AnniversaryUsers($first: Int, $after: String) {
    anniversaryUsers(first: $first, after: $after) {
      edges {
        node {
          id
          user {
            id
            userName
            avatarImageUrl
          }
          name
          relation
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function useAnniversaryUsersQuery(
  options?: Omit<Urql.UseQueryArgs<AnniversaryUsersQueryVariables>, "query">,
) {
  return Urql.useQuery<AnniversaryUsersQuery, AnniversaryUsersQueryVariables>({
    query: AnniversaryUsersDocument,
    ...options,
  });
}
export const ApproveFollowRequestDocument = gql`
  mutation ApproveFollowRequest($userId: ID!) {
    approveFollowRequest(userId: $userId) {
      code
      message
      user {
        id
        followingMe
      }
    }
  }
`;

export function useApproveFollowRequestMutation() {
  return Urql.useMutation<
    ApproveFollowRequestMutation,
    ApproveFollowRequestMutationVariables
  >(ApproveFollowRequestDocument);
}
export const AttachPromotionCodeDocument = gql`
  mutation AttachPromotionCode($code: String!) {
    attachPromotionCode(code: $code) {
      code
      message
      errors {
        key
        message
      }
      giftDraft {
        id
        promotionCodeAmount
        totalAmount
      }
    }
  }
`;

export function useAttachPromotionCodeMutation() {
  return Urql.useMutation<
    AttachPromotionCodeMutation,
    AttachPromotionCodeMutationVariables
  >(AttachPromotionCodeDocument);
}
export const BlockUserDocument = gql`
  mutation BlockUser($id: ID!) {
    blockUser(id: $id) {
      code
      message
      user {
        id
        relationStatus
      }
    }
  }
`;

export function useBlockUserMutation() {
  return Urql.useMutation<BlockUserMutation, BlockUserMutationVariables>(
    BlockUserDocument,
  );
}
export const BlockingUsersDocument = gql`
  query BlockingUsers($first: Int!, $after: String) {
    blockingUsers(first: $first, after: $after) {
      edges {
        node {
          id
          userName
          displayName
          avatarImageUrl
          relationStatus
          followingMe
          private
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function useBlockingUsersQuery(
  options: Omit<Urql.UseQueryArgs<BlockingUsersQueryVariables>, "query">,
) {
  return Urql.useQuery<BlockingUsersQuery, BlockingUsersQueryVariables>({
    query: BlockingUsersDocument,
    ...options,
  });
}
export const BrandEditPageDataDocument = gql`
  query BrandEditPageData($brandName: String!) {
    brand(brandName: $brandName) {
      id
      logoImage {
        id
        url
      }
      alterImage {
        id
        url
      }
      brandName
      displayName
      description
      visibility
    }
  }
`;

export function useBrandEditPageDataQuery(
  options: Omit<Urql.UseQueryArgs<BrandEditPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<BrandEditPageDataQuery, BrandEditPageDataQueryVariables>(
    { query: BrandEditPageDataDocument, ...options },
  );
}
export const BrandListPageInitialDataDocument = gql`
  query BrandListPageInitialData(
    $first: Int
    $after: String
    $input: BrandsInput
  ) {
    brands(first: $first, after: $after, input: $input) {
      edges {
        node {
          id
          displayName
          brandName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export function useBrandListPageInitialDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<BrandListPageInitialDataQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    BrandListPageInitialDataQuery,
    BrandListPageInitialDataQueryVariables
  >({ query: BrandListPageInitialDataDocument, ...options });
}
export const BrandPageInitialDataDocument = gql`
  query BrandPageInitialData($brandName: String!) {
    brand(brandName: $brandName) {
      id
      logoImage {
        id
        url
      }
      alterImage {
        id
        url
      }
      displayName
      description
      stores {
        id
        name
        address
        transportation
        businessHours
        phoneNumber
        googleMapSrc
      }
    }
  }
`;

export function useBrandPageInitialDataQuery(
  options: Omit<Urql.UseQueryArgs<BrandPageInitialDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    BrandPageInitialDataQuery,
    BrandPageInitialDataQueryVariables
  >({ query: BrandPageInitialDataDocument, ...options });
}
export const BrandsDocument = gql`
  query Brands($first: Int, $after: String, $input: BrandsInput) {
    brands(first: $first, after: $after, input: $input) {
      edges {
        node {
          id
          displayName
          brandName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export function useBrandsQuery(
  options?: Omit<Urql.UseQueryArgs<BrandsQueryVariables>, "query">,
) {
  return Urql.useQuery<BrandsQuery, BrandsQueryVariables>({
    query: BrandsDocument,
    ...options,
  });
}
export const CancelFollowRequestDocument = gql`
  mutation CancelFollowRequest($userId: ID!) {
    cancelFollowRequest(userId: $userId) {
      code
      message
      user {
        id
        relationStatus
      }
    }
  }
`;

export function useCancelFollowRequestMutation() {
  return Urql.useMutation<
    CancelFollowRequestMutation,
    CancelFollowRequestMutationVariables
  >(CancelFollowRequestDocument);
}
export const CardDesignDocument = gql`
  query CardDesign($id: ID!) {
    cardDesign(id: $id) {
      id
      image {
        id
        url
      }
      name
    }
  }
`;

export function useCardDesignQuery(
  options: Omit<Urql.UseQueryArgs<CardDesignQueryVariables>, "query">,
) {
  return Urql.useQuery<CardDesignQuery, CardDesignQueryVariables>({
    query: CardDesignDocument,
    ...options,
  });
}
export const CardDesignScenesDocument = gql`
  query CardDesignScenes {
    cardDesignScenes {
      id
      name
      cardDesigns {
        id
        image {
          id
          url
        }
        name
      }
    }
  }
`;

export function useCardDesignScenesQuery(
  options?: Omit<Urql.UseQueryArgs<CardDesignScenesQueryVariables>, "query">,
) {
  return Urql.useQuery<CardDesignScenesQuery, CardDesignScenesQueryVariables>({
    query: CardDesignScenesDocument,
    ...options,
  });
}
export const CartPageDataDocument = gql`
  query CartPageData {
    giftDraft {
      id
      itemsAmount
      giftDraftSkus {
        ...GiftDraftSkuFields
      }
      abandonedGiftDraftSkus {
        ...GiftDraftSkuFields
      }
      giftDraftDeliveries {
        id
        shipper {
          id
          name
        }
        selectedDeliveryOptionItems {
          id
          name
          price
        }
      }
    }
  }
  ${GiftDraftSkuFieldsFragmentDoc}
`;

export function useCartPageDataQuery(
  options?: Omit<Urql.UseQueryArgs<CartPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<CartPageDataQuery, CartPageDataQueryVariables>({
    query: CartPageDataDocument,
    ...options,
  });
}
export const CartSkuCountDocument = gql`
  query CartSkuCount {
    giftDraft {
      id
      giftDraftSkus {
        id
      }
    }
  }
`;

export function useCartSkuCountQuery(
  options?: Omit<Urql.UseQueryArgs<CartSkuCountQueryVariables>, "query">,
) {
  return Urql.useQuery<CartSkuCountQuery, CartSkuCountQueryVariables>({
    query: CartSkuCountDocument,
    ...options,
  });
}
export const ConfirmGiftConsigneeDocument = gql`
  mutation ConfirmGiftConsignee($giftId: ID!) {
    confirmGiftConsignee(giftId: $giftId) {
      code
      message
    }
  }
`;

export function useConfirmGiftConsigneeMutation() {
  return Urql.useMutation<
    ConfirmGiftConsigneeMutation,
    ConfirmGiftConsigneeMutationVariables
  >(ConfirmGiftConsigneeDocument);
}
export const ConsigneesDocument = gql`
  query Consignees {
    consignees {
      id
      name
      nameKana
      postalCode
      prefecture {
        id
        name
      }
      city
      building
      phoneNumber
      email
    }
  }
`;

export function useConsigneesQuery(
  options?: Omit<Urql.UseQueryArgs<ConsigneesQueryVariables>, "query">,
) {
  return Urql.useQuery<ConsigneesQuery, ConsigneesQueryVariables>({
    query: ConsigneesDocument,
    ...options,
  });
}
export const CreateAnniversaryDocument = gql`
  mutation CreateAnniversary($input: CreateAnniversaryInput!) {
    createAnniversary(input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useCreateAnniversaryMutation() {
  return Urql.useMutation<
    CreateAnniversaryMutation,
    CreateAnniversaryMutationVariables
  >(CreateAnniversaryDocument);
}
export const CreateAnniversaryUserDocument = gql`
  mutation CreateAnniversaryUser($input: CreateAnniversaryUserInput!) {
    createAnniversaryUser(input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useCreateAnniversaryUserMutation() {
  return Urql.useMutation<
    CreateAnniversaryUserMutation,
    CreateAnniversaryUserMutationVariables
  >(CreateAnniversaryUserDocument);
}
export const CreateBrandDocument = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      code
      message
      errors {
        key
        message
      }
      brand {
        id
        brandName
      }
    }
  }
`;

export function useCreateBrandMutation() {
  return Urql.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(
    CreateBrandDocument,
  );
}
export const CreateConsigneeDocument = gql`
  mutation CreateConsignee($input: CreateConsigneeInput!) {
    createConsignee(input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useCreateConsigneeMutation() {
  return Urql.useMutation<
    CreateConsigneeMutation,
    CreateConsigneeMutationVariables
  >(CreateConsigneeDocument);
}
export const CreateItemDocument = gql`
  mutation CreateItem($input: CreateItemInput!) {
    createItem(input: $input) {
      code
      message
      errors {
        key
        message
      }
      item {
        id
      }
    }
  }
`;

export function useCreateItemMutation() {
  return Urql.useMutation<CreateItemMutation, CreateItemMutationVariables>(
    CreateItemDocument,
  );
}
export const CreateShipperDocument = gql`
  mutation CreateShipper($input: CreateShipperInput!) {
    createShipper(input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useCreateShipperMutation() {
  return Urql.useMutation<
    CreateShipperMutation,
    CreateShipperMutationVariables
  >(CreateShipperDocument);
}
export const CreateSkuDocument = gql`
  mutation CreateSku($input: CreateSkuInput!) {
    createSku(input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useCreateSkuMutation() {
  return Urql.useMutation<CreateSkuMutation, CreateSkuMutationVariables>(
    CreateSkuDocument,
  );
}
export const CreateSkuGroupDocument = gql`
  mutation CreateSkuGroup($input: CreateSkuGroupInput!) {
    createSkuGroup(input: $input) {
      code
      message
      errors {
        key
        message
      }
      skuGroup {
        id
      }
    }
  }
`;

export function useCreateSkuGroupMutation() {
  return Urql.useMutation<
    CreateSkuGroupMutation,
    CreateSkuGroupMutationVariables
  >(CreateSkuGroupDocument);
}
export const CreateSkuGroupValueDocument = gql`
  mutation CreateSkuGroupValue($input: CreateSkuGroupValueInput!) {
    createSkuGroupValue(input: $input) {
      code
      message
      errors {
        key
        message
      }
      skuGroupValue {
        id
      }
    }
  }
`;

export function useCreateSkuGroupValueMutation() {
  return Urql.useMutation<
    CreateSkuGroupValueMutation,
    CreateSkuGroupValueMutationVariables
  >(CreateSkuGroupValueDocument);
}
export const CreateStoreDocument = gql`
  mutation CreateStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      code
      message
      errors {
        key
        message
      }
      store {
        id
        brand {
          id
          brandName
        }
      }
    }
  }
`;

export function useCreateStoreMutation() {
  return Urql.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(
    CreateStoreDocument,
  );
}
export const CreditCardsDocument = gql`
  query CreditCards {
    creditCards {
      id
      brand
      last4
      expMonth
      expYear
    }
  }
`;

export function useCreditCardsQuery(
  options?: Omit<Urql.UseQueryArgs<CreditCardsQueryVariables>, "query">,
) {
  return Urql.useQuery<CreditCardsQuery, CreditCardsQueryVariables>({
    query: CreditCardsDocument,
    ...options,
  });
}
export const DeactivateDocument = gql`
  mutation Deactivate($input: DeactivateInput!) {
    deactivate(input: $input) {
      code
      message
    }
  }
`;

export function useDeactivateMutation() {
  return Urql.useMutation<DeactivateMutation, DeactivateMutationVariables>(
    DeactivateDocument,
  );
}
export const DecreaseGiftDraftSkuDocument = gql`
  mutation DecreaseGiftDraftSku($id: ID!) {
    decreaseGiftDraftSku(id: $id) {
      code
      message
      giftDraft {
        id
        itemsAmount
        giftDraftSkus {
          id
        }
      }
    }
  }
`;

export function useDecreaseGiftDraftSkuMutation() {
  return Urql.useMutation<
    DecreaseGiftDraftSkuMutation,
    DecreaseGiftDraftSkuMutationVariables
  >(DecreaseGiftDraftSkuDocument);
}
export const DefaultAvatarImageUrlsDocument = gql`
  query DefaultAvatarImageUrls {
    defaultAvatarImageUrls
  }
`;

export function useDefaultAvatarImageUrlsQuery(
  options?: Omit<
    Urql.UseQueryArgs<DefaultAvatarImageUrlsQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    DefaultAvatarImageUrlsQuery,
    DefaultAvatarImageUrlsQueryVariables
  >({ query: DefaultAvatarImageUrlsDocument, ...options });
}
export const DefaultConsigneeDocument = gql`
  query DefaultConsignee {
    defaultConsignee {
      id
      name
      nameKana
      postalCode
      prefecture {
        id
        name
      }
      city
      building
      phoneNumber
      email
    }
  }
`;

export function useDefaultConsigneeQuery(
  options?: Omit<Urql.UseQueryArgs<DefaultConsigneeQueryVariables>, "query">,
) {
  return Urql.useQuery<DefaultConsigneeQuery, DefaultConsigneeQueryVariables>({
    query: DefaultConsigneeDocument,
    ...options,
  });
}
export const DeleteAnniversaryUserDocument = gql`
  mutation DeleteAnniversaryUser($id: ID!) {
    deleteAnniversaryUser(id: $id) {
      code
      message
    }
  }
`;

export function useDeleteAnniversaryUserMutation() {
  return Urql.useMutation<
    DeleteAnniversaryUserMutation,
    DeleteAnniversaryUserMutationVariables
  >(DeleteAnniversaryUserDocument);
}
export const DeleteConsigneeDocument = gql`
  mutation DeleteConsignee($id: ID!) {
    deleteConsignee(id: $id) {
      code
      message
    }
  }
`;

export function useDeleteConsigneeMutation() {
  return Urql.useMutation<
    DeleteConsigneeMutation,
    DeleteConsigneeMutationVariables
  >(DeleteConsigneeDocument);
}
export const DeleteGiftDraftSkuDocument = gql`
  mutation DeleteGiftDraftSku($id: ID!) {
    deleteGiftDraftSku(id: $id) {
      code
      message
      giftDraft {
        id
        itemsAmount
        giftDraftSkus {
          id
        }
        giftDraftDeliveries {
          id
        }
      }
    }
  }
`;

export function useDeleteGiftDraftSkuMutation() {
  return Urql.useMutation<
    DeleteGiftDraftSkuMutation,
    DeleteGiftDraftSkuMutationVariables
  >(DeleteGiftDraftSkuDocument);
}
export const DeleteGiftDraftSkuAllDocument = gql`
  mutation DeleteGiftDraftSkuAll($id: ID!) {
    deleteGiftDraftSkuAll(id: $id) {
      code
      message
      giftDraft {
        id
        itemsAmount
        giftDraftSkus {
          id
        }
        abandonedGiftDraftSkus {
          id
        }
        giftDraftDeliveries {
          id
        }
      }
    }
  }
`;

export function useDeleteGiftDraftSkuAllMutation() {
  return Urql.useMutation<
    DeleteGiftDraftSkuAllMutation,
    DeleteGiftDraftSkuAllMutationVariables
  >(DeleteGiftDraftSkuAllDocument);
}
export const DeliveryInfosDocument = gql`
  query DeliveryInfos($input: DeliveryInfosInput!) {
    deliveryInfos(input: $input) {
      id
      gift {
        id
        orderNumber
        deliveryMethod
        giftFor
        customer {
          id
          name
          nameKana
          postalCode
          prefecture {
            id
            name
          }
          city
          building
          phoneNumber
          email
        }
        itemsAmount
        totalAmount
      }
      consignee {
        id
        name
        nameKana
        postalCode
        prefecture {
          id
          name
        }
        city
        building
        phoneNumber
        email
      }
      deliveryDate
      deliveryTime
      sku {
        id
        skuCode
        item {
          id
          name
          brand {
            id
            brandName
            displayName
          }
        }
        shipper {
          id
          name
        }
        skuGroupValue1 {
          id
          name
          group {
            id
            name
          }
        }
        skuGroupValue2 {
          id
          name
          group {
            id
            name
          }
        }
      }
      skuOptionItems {
        id
        name
      }
      giftDelivery {
        id
        deliveryOptionItems {
          id
          name
          deliveryOption {
            id
            name
          }
        }
      }
      purchasePrice
      createdAt
    }
  }
`;

export function useDeliveryInfosQuery(
  options: Omit<Urql.UseQueryArgs<DeliveryInfosQueryVariables>, "query">,
) {
  return Urql.useQuery<DeliveryInfosQuery, DeliveryInfosQueryVariables>({
    query: DeliveryInfosDocument,
    ...options,
  });
}
export const DetachPromotionCodeDocument = gql`
  mutation DetachPromotionCode {
    detachPromotionCode {
      code
      message
      errors {
        key
        message
      }
      giftDraft {
        id
        promotionCodeAmount
        totalAmount
      }
    }
  }
`;

export function useDetachPromotionCodeMutation() {
  return Urql.useMutation<
    DetachPromotionCodeMutation,
    DetachPromotionCodeMutationVariables
  >(DetachPromotionCodeDocument);
}
export const DynamicLinkDocument = gql`
  query DynamicLink($input: DynamicLinkInput!) {
    dynamicLink(input: $input)
  }
`;

export function useDynamicLinkQuery(
  options: Omit<Urql.UseQueryArgs<DynamicLinkQueryVariables>, "query">,
) {
  return Urql.useQuery<DynamicLinkQuery, DynamicLinkQueryVariables>({
    query: DynamicLinkDocument,
    ...options,
  });
}
export const EmailAvailabilityDocument = gql`
  query EmailAvailability($email: String!) {
    emailAvailability(email: $email) {
      available
    }
  }
`;

export function useEmailAvailabilityQuery(
  options: Omit<Urql.UseQueryArgs<EmailAvailabilityQueryVariables>, "query">,
) {
  return Urql.useQuery<EmailAvailabilityQuery, EmailAvailabilityQueryVariables>(
    { query: EmailAvailabilityDocument, ...options },
  );
}
export const EmailNotificationSettingPageDataDocument = gql`
  query EmailNotificationSettingPageData {
    setting {
      id
      emailNotifications
    }
  }
`;

export function useEmailNotificationSettingPageDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<EmailNotificationSettingPageDataQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    EmailNotificationSettingPageDataQuery,
    EmailNotificationSettingPageDataQueryVariables
  >({ query: EmailNotificationSettingPageDataDocument, ...options });
}
export const FollowUserDocument = gql`
  mutation FollowUser($id: ID!) {
    followUser(id: $id) {
      code
      message
      user {
        id
        relationStatus
      }
    }
  }
`;

export function useFollowUserMutation() {
  return Urql.useMutation<FollowUserMutation, FollowUserMutationVariables>(
    FollowUserDocument,
  );
}
export const GiftDocument = gql`
  query Gift($id: ID!) {
    gift(id: $id) {
      id
      cardDesign {
        id
        image {
          id
          url
        }
      }
      gifteeName
      gifterName
      gifter {
        id
        avatarImageUrl
      }
      message
      savedAt
      giftSkus {
        id
        sku {
          id
          item {
            id
            topImage {
              id
              url
            }
            name
            brand {
              id
              displayName
            }
          }
          skuGroupValue1 {
            id
            name
            group {
              id
              name
            }
          }
          skuGroupValue2 {
            id
            name
            group {
              id
              name
            }
          }
        }
        receivingWay
        expireAt
        usedAt
      }
    }
  }
`;

export function useGiftQuery(
  options: Omit<Urql.UseQueryArgs<GiftQueryVariables>, "query">,
) {
  return Urql.useQuery<GiftQuery, GiftQueryVariables>({
    query: GiftDocument,
    ...options,
  });
}
export const GiftConfirmPageDataDocument = gql`
  query GiftConfirmPageData {
    giftDraft {
      id
      giftFor
      cardDesign {
        id
        image {
          id
          url
        }
      }
      gifteeName
      gifterName
      message
      secret
      deliveryMethod
      shipFrom
      itemsAmount
      optionsAmount {
        amount
        details {
          id
          optionName
          optionItemName
          count
          amount
        }
      }
      shippingCostsAmount {
        amount
        details {
          giftDraftDeliveryId
          amount
        }
      }
      promotionCodeAmount
      totalAmount
      paymentMethod
      creditCard {
        id
        brand
        last4
        expMonth
        expYear
      }
      giftDraftSkus {
        id
        sku {
          id
          item {
            id
            topImage {
              id
              url
            }
            name
            brand {
              id
              displayName
            }
            expireAt
          }
          skuGroupValue1 {
            id
            name
            group {
              id
              name
            }
          }
          skuGroupValue2 {
            id
            name
            group {
              id
              name
            }
          }
          shipper {
            id
          }
          price
        }
        optionItems {
          id
          name
          price
        }
      }
      giftDraftDeliveries {
        id
        shipper {
          id
          name
        }
        deliveryDate
        deliveryTime
        selectedDeliveryOptionItems {
          id
          name
          price
        }
      }
      customer {
        id
        name
        nameKana
        postalCode
        prefecture {
          id
          name
        }
        city
        building
        phoneNumber
        email
      }
      consignee {
        id
        name
        nameKana
        postalCode
        prefecture {
          id
          name
        }
        city
        building
        phoneNumber
      }
    }
  }
`;

export function useGiftConfirmPageDataQuery(
  options?: Omit<Urql.UseQueryArgs<GiftConfirmPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    GiftConfirmPageDataQuery,
    GiftConfirmPageDataQueryVariables
  >({ query: GiftConfirmPageDataDocument, ...options });
}
export const GiftConsigneeDocument = gql`
  query GiftConsignee($id: ID!) {
    gift(id: $id) {
      id
      orderNumber
      consigneeDraft {
        name
        nameKana
        postalCode
        prefecture {
          id
          name
        }
        city
        building
        phoneNumber
        email
      }
      giftSkus {
        id
        sku {
          id
          shipper {
            id
            name
          }
          item {
            id
            topImage {
              id
              url
            }
            name
            brand {
              id
              displayName
            }
          }
          skuGroupValue1 {
            id
            name
            group {
              id
              name
            }
          }
          skuGroupValue2 {
            id
            name
            group {
              id
              name
            }
          }
        }
        expireAt
      }
      giftDeliveries {
        id
        deliveryDateDraft
        deliveryTimeDraft
        shipper {
          id
          name
        }
      }
    }
  }
`;

export function useGiftConsigneeQuery(
  options: Omit<Urql.UseQueryArgs<GiftConsigneeQueryVariables>, "query">,
) {
  return Urql.useQuery<GiftConsigneeQuery, GiftConsigneeQueryVariables>({
    query: GiftConsigneeDocument,
    ...options,
  });
}
export const GiftDraftAmountDataDocument = gql`
  query GiftDraftAmountData($prefectureId: ID) {
    giftDraft {
      id
      itemsAmount
      optionsAmount {
        amount
        details {
          id
          optionName
          optionItemName
          count
          amount
        }
      }
      shippingCostsAmount(input: { prefectureId: $prefectureId }) {
        amount
        details {
          giftDraftDeliveryId
          amount
        }
      }
      promotionCodeAmount
      totalAmount(input: { prefectureId: $prefectureId })
    }
  }
`;

export function useGiftDraftAmountDataQuery(
  options?: Omit<Urql.UseQueryArgs<GiftDraftAmountDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    GiftDraftAmountDataQuery,
    GiftDraftAmountDataQueryVariables
  >({ query: GiftDraftAmountDataDocument, ...options });
}
export const GiftNewPageDataDocument = gql`
  query GiftNewPageData {
    me {
      user {
        id
        avatarImageUrl
      }
    }
    giftDraft {
      id
      giftFor
      cardDesign {
        id
        image {
          id
          url
        }
      }
      gifteeName
      gifterName
      message
      itemsAmount
      optionsAmount {
        amount
        details {
          id
          optionName
          optionItemName
          count
          amount
        }
      }
      shippingCostsAmount {
        amount
        details {
          giftDraftDeliveryId
          amount
        }
      }
      promotionCodeAmount
      totalAmount
      secret
      giftDraftSkus {
        id
        sku {
          id
          item {
            id
            topImage {
              id
              url
            }
            name
            brand {
              id
              displayName
            }
            expireAt
          }
          skuGroupValue1 {
            id
            name
            group {
              id
              name
            }
          }
          skuGroupValue2 {
            id
            name
            group {
              id
              name
            }
          }
          shipper {
            id
          }
          options {
            id
            name
            optionItems {
              id
              image {
                id
                url
              }
              name
              price
            }
          }
          price
        }
        optionItems {
          id
          image {
            id
            url
          }
          name
          price
        }
      }
      giftDraftDeliveries {
        id
        shipper {
          id
          name
        }
        deliveryOptions {
          id
          name
          deliveryOptionItems {
            id
            image {
              id
              url
            }
            name
            price
          }
        }
        selectedDeliveryOptionItems {
          id
          image {
            id
            url
          }
          name
          price
        }
      }
    }
  }
`;

export function useGiftNewPageDataQuery(
  options?: Omit<Urql.UseQueryArgs<GiftNewPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<GiftNewPageDataQuery, GiftNewPageDataQueryVariables>({
    query: GiftNewPageDataDocument,
    ...options,
  });
}
export const GiftOrderPageDataDocument = gql`
  query GiftOrderPageData {
    giftDraft {
      id
      giftFor
      deliveryMethod
      shipFrom
      secret
      paymentMethod
      customer {
        id
        name
        nameKana
        postalCode
        prefecture {
          id
          name
        }
        city
        building
        phoneNumber
        email
      }
      consignee {
        id
        originalConsigneeId
        name
        nameKana
        postalCode
        prefecture {
          id
          name
        }
        city
        building
        phoneNumber
        email
      }
      giftDraftSkus {
        id
        sku {
          id
          skuGroupValue1 {
            id
            name
            group {
              id
              name
            }
          }
          skuGroupValue2 {
            id
            name
            group {
              id
              name
            }
          }
          item {
            id
            topImage {
              id
              url
            }
            brand {
              id
              displayName
            }
            name
            receivingWay
          }
          shipper {
            id
          }
        }
        optionItems {
          id
          name
          price
        }
      }
      giftDraftDeliveries {
        id
        shipper {
          id
          name
        }
        deliveryDate
        deliveryTime
      }
    }
    customer {
      id
      name
      nameKana
      postalCode
      prefecture {
        id
        name
      }
      city
      building
      phoneNumber
      email
    }
    defaultConsignee {
      id
      name
      nameKana
      postalCode
      prefecture {
        id
        name
      }
      city
      building
      phoneNumber
      email
    }
    defaultCreditCard {
      id
    }
    setting {
      id
      email
    }
  }
`;

export function useGiftOrderPageDataQuery(
  options?: Omit<Urql.UseQueryArgs<GiftOrderPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<GiftOrderPageDataQuery, GiftOrderPageDataQueryVariables>(
    { query: GiftOrderPageDataDocument, ...options },
  );
}
export const GiftSkuDocument = gql`
  query GiftSku($id: ID!) {
    giftSku(id: $id) {
      id
      sku {
        id
        item {
          id
          name
          brand {
            id
            brandName
            displayName
            pinCodes
            logoImage {
              id
              url
            }
            stores {
              id
              municipalities {
                id
                name
                area {
                  id
                  name
                  prefecture {
                    id
                    name
                  }
                }
              }
            }
          }
          images {
            id
            url
          }
          topImage {
            id
            url
            width
            height
          }
          minSkuPrice
          isSkuPriceConstant
          onSale
          description {
            renderers {
              ... on DescriptionTextRenderer {
                type
                size
                weight
                color
                text
              }
              ... on DescriptionImageRenderer {
                type
                url
                width
                height
              }
            }
          }
          expireAt
          attention
          reconciliationWay
          shortUsage
          usageExplanations
          liked
          wished
          allergies {
            id
            name
          }
          skus {
            id
            skuGroupValue1 {
              ...ValueFields
            }
            skuGroupValue2 {
              ...ValueFields
            }
            shippingCosts {
              id
              prefecture {
                id
                name
              }
              cost
            }
            item {
              id
              liked
              wished
            }
            price
            onSale
            options {
              id
              name
              optionItems {
                id
                image {
                  id
                  url
                }
                name
                price
              }
            }
            liked
            wished
          }
        }
        shipper {
          id
        }
        skuGroupValue1 {
          id
          name
          group {
            id
            name
          }
        }
        skuGroupValue2 {
          id
          name
          group {
            id
            name
          }
        }
      }
      gift {
        id
        orderNumber
        secret
        giftDeliveries {
          id
          shipper {
            id
          }
        }
      }
      receivingWay
      expireAt
      usedAt
    }
  }
  ${ValueFieldsFragmentDoc}
`;

export function useGiftSkuQuery(
  options: Omit<Urql.UseQueryArgs<GiftSkuQueryVariables>, "query">,
) {
  return Urql.useQuery<GiftSkuQuery, GiftSkuQueryVariables>({
    query: GiftSkuDocument,
    ...options,
  });
}
export const IncreaseGiftDraftSkuDocument = gql`
  mutation IncreaseGiftDraftSku($id: ID!) {
    increaseGiftDraftSku(id: $id) {
      code
      message
      giftDraft {
        id
        itemsAmount
        giftDraftSkus {
          id
        }
      }
    }
  }
`;

export function useIncreaseGiftDraftSkuMutation() {
  return Urql.useMutation<
    IncreaseGiftDraftSkuMutation,
    IncreaseGiftDraftSkuMutationVariables
  >(IncreaseGiftDraftSkuDocument);
}
export const InquireDocument = gql`
  mutation Inquire($input: InquireInput!) {
    inquire(input: $input) {
      code
      message
    }
  }
`;

export function useInquireMutation() {
  return Urql.useMutation<InquireMutation, InquireMutationVariables>(
    InquireDocument,
  );
}
export const ItemEditPageDataDocument = gql`
  query ItemEditPageData($id: ID!) {
    item(id: $id) {
      id
      images {
        id
        url
        width
        height
      }
      name
      minSkuPrice
      description {
        renderers {
          ... on DescriptionTextRenderer {
            type
            size
            weight
            color
            text
          }
          ... on DescriptionImageRenderer {
            type
            url
            width
            height
          }
        }
      }
      onSale
      priority
      itemCategory {
        id
      }
      availableRegion
      receivingWay
      expireTimeUnit
      expireAfter
      attention
      shortUsage
      usageExplanations
      reconciliationWay
      allergies {
        id
        name
      }
      relations
      scenes {
        id
      }
    }
  }
`;

export function useItemEditPageDataQuery(
  options: Omit<Urql.UseQueryArgs<ItemEditPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<ItemEditPageDataQuery, ItemEditPageDataQueryVariables>({
    query: ItemEditPageDataDocument,
    ...options,
  });
}
export const ItemListFormDataDocument = gql`
  query ItemListFormData {
    brands(first: 1000) {
      edges {
        node {
          id
          displayName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
    itemCategoryGroups {
      id
      name
      subgroups {
        id
        name
        categories {
          id
          name
        }
      }
    }
    itemScenes {
      id
      name
    }
    allergies {
      id
      name
    }
    municipalities {
      id
      name
    }
  }
`;

export function useItemListFormDataQuery(
  options?: Omit<Urql.UseQueryArgs<ItemListFormDataQueryVariables>, "query">,
) {
  return Urql.useQuery<ItemListFormDataQuery, ItemListFormDataQueryVariables>({
    query: ItemListFormDataDocument,
    ...options,
  });
}
export const ItemListPageInitialDataDocument = gql`
  query ItemListPageInitialData($first: Int, $last: Int, $input: ItemsInput!) {
    items(first: $first, last: $last, input: $input) {
      edges {
        node {
          id
          topImage {
            id
            url
          }
          name
          brand {
            id
            displayName
          }
          minSkuPrice
          isSkuPriceConstant
          municipalities {
            id
            name
          }
          skus {
            id
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
`;

export function useItemListPageInitialDataQuery(
  options: Omit<
    Urql.UseQueryArgs<ItemListPageInitialDataQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    ItemListPageInitialDataQuery,
    ItemListPageInitialDataQueryVariables
  >({ query: ItemListPageInitialDataDocument, ...options });
}
export const ItemPageInitialDataDocument = gql`
  query ItemPageInitialData($id: ID!) {
    item(id: $id) {
      id
      name
      brand {
        id
        brandName
        displayName
        logoImage {
          id
          url
        }
        stores {
          id
          municipalities {
            id
            name
            area {
              id
              name
              prefecture {
                id
                name
              }
            }
          }
        }
      }
      images {
        id
        url
      }
      topImage {
        id
        url
      }
      minSkuPrice
      isSkuPriceConstant
      onSale
      description {
        renderers {
          ... on DescriptionTextRenderer {
            type
            size
            weight
            color
            text
          }
          ... on DescriptionImageRenderer {
            type
            url
            width
            height
          }
        }
      }
      expireAt
      attention
      shortUsage
      usageExplanations
      liked
      wished
      allergies {
        id
        name
      }
      skus {
        id
        skuGroupValue1 {
          ...ValueFields
        }
        skuGroupValue2 {
          ...ValueFields
        }
        shippingCosts {
          id
          prefecture {
            id
            name
          }
          cost
        }
        item {
          id
          liked
          wished
        }
        price
        onSale
        options {
          id
          name
          optionItems {
            id
            image {
              id
              url
            }
            name
            price
          }
        }
        liked
        wished
      }
    }
  }
  ${ValueFieldsFragmentDoc}
`;

export function useItemPageInitialDataQuery(
  options: Omit<Urql.UseQueryArgs<ItemPageInitialDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    ItemPageInitialDataQuery,
    ItemPageInitialDataQueryVariables
  >({ query: ItemPageInitialDataDocument, ...options });
}
export const ItemPageUserDataDocument = gql`
  query ItemPageUserData($itemId: ID!, $brandName: String!) {
    item(id: $itemId) {
      id
      skus {
        id
        skuGroupValue1 {
          ...ValueFields
        }
        skuGroupValue2 {
          ...ValueFields
        }
        shippingCosts {
          id
          prefecture {
            id
            name
          }
          cost
        }
        item {
          id
          liked
          wished
        }
        price
        onSale
        options {
          id
          name
          optionItems {
            id
            image {
              id
              url
            }
            name
            price
          }
        }
        liked
        wished
      }
    }
    brand(brandName: $brandName) {
      id
      items(first: 4) {
        edges {
          node {
            id
            topImage {
              id
              url
            }
            name
            brand {
              id
              displayName
            }
            minSkuPrice
            isSkuPriceConstant
            liked
            municipalities {
              id
              name
            }
            skus {
              id
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${ValueFieldsFragmentDoc}
`;

export function useItemPageUserDataQuery(
  options: Omit<Urql.UseQueryArgs<ItemPageUserDataQueryVariables>, "query">,
) {
  return Urql.useQuery<ItemPageUserDataQuery, ItemPageUserDataQueryVariables>({
    query: ItemPageUserDataDocument,
    ...options,
  });
}
export const ItemScenesDocument = gql`
  query ItemScenes {
    itemScenes {
      id
      name
      image {
        id
        url
      }
    }
  }
`;

export function useItemScenesQuery(
  options?: Omit<Urql.UseQueryArgs<ItemScenesQueryVariables>, "query">,
) {
  return Urql.useQuery<ItemScenesQuery, ItemScenesQueryVariables>({
    query: ItemScenesDocument,
    ...options,
  });
}
export const ItemsDocument = gql`
  query Items(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $input: ItemsInput
  ) {
    items(
      first: $first
      after: $after
      last: $last
      before: $before
      input: $input
    ) {
      edges {
        node {
          id
          topImage {
            id
            url
          }
          name
          brand {
            id
            brandName
            displayName
          }
          minSkuPrice
          isSkuPriceConstant
          liked
          municipalities {
            id
            name
          }
          skus {
            id
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export function useItemsQuery(
  options?: Omit<Urql.UseQueryArgs<ItemsQueryVariables>, "query">,
) {
  return Urql.useQuery<ItemsQuery, ItemsQueryVariables>({
    query: ItemsDocument,
    ...options,
  });
}
export const LeadTimesAndDeliveryTimesDocument = gql`
  query LeadTimesAndDeliveryTimes($id: ID!) {
    leadTimes(input: { giftDeliveryId: $id }) {
      id
      prefecture {
        id
      }
      dayCount
    }
    deliveryTimes(input: { giftDeliveryId: $id })
  }
`;

export function useLeadTimesAndDeliveryTimesQuery(
  options: Omit<
    Urql.UseQueryArgs<LeadTimesAndDeliveryTimesQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    LeadTimesAndDeliveryTimesQuery,
    LeadTimesAndDeliveryTimesQueryVariables
  >({ query: LeadTimesAndDeliveryTimesDocument, ...options });
}
export const LikeSkuDocument = gql`
  mutation LikeSku($id: ID!) {
    likeSku(id: $id) {
      code
      message
      sku {
        id
        liked
      }
    }
  }
`;

export function useLikeSkuMutation() {
  return Urql.useMutation<LikeSkuMutation, LikeSkuMutationVariables>(
    LikeSkuDocument,
  );
}
export const LikesDocument = gql`
  query Likes($first: Int, $after: String) {
    me {
      user {
        id
        likes(first: $first, after: $after) {
          edges {
            node {
              id
              item {
                id
                name
                topImage {
                  id
                  url
                }
                brand {
                  id
                  displayName
                }
              }
              skuGroupValue1 {
                id
                name
                group {
                  id
                  name
                }
              }
              skuGroupValue2 {
                id
                name
                group {
                  id
                  name
                }
              }
              price
              liked
              wished
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
`;

export function useLikesQuery(
  options?: Omit<Urql.UseQueryArgs<LikesQueryVariables>, "query">,
) {
  return Urql.useQuery<LikesQuery, LikesQueryVariables>({
    query: LikesDocument,
    ...options,
  });
}
export const MeDocument = gql`
  query Me {
    me {
      user {
        id
        role
        displayName
        userName
        avatarImageUrl
      }
      status
    }
  }
`;

export function useMeQuery(
  options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, "query">,
) {
  return Urql.useQuery<MeQuery, MeQueryVariables>({
    query: MeDocument,
    ...options,
  });
}
export const MyPagePageDataDocument = gql`
  query MyPagePageData {
    me {
      user {
        id
        userName
        displayName
        biography
        avatarImageUrl
        private
        biography
        birthYear
        birthMonth
        birthDay
        showYear
        followeeCount
        followerCount
        municipalities {
          id
          name
        }
        allergies {
          id
          name
        }
      }
    }
  }
`;

export function useMyPagePageDataQuery(
  options?: Omit<Urql.UseQueryArgs<MyPagePageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<MyPagePageDataQuery, MyPagePageDataQueryVariables>({
    query: MyPagePageDataDocument,
    ...options,
  });
}
export const NotificationsDocument = gql`
  query Notifications($first: Int, $after: String) {
    notifications(first: $first, after: $after) {
      edges {
        node {
          id
          title
          notifiedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function useNotificationsQuery(
  options?: Omit<Urql.UseQueryArgs<NotificationsQueryVariables>, "query">,
) {
  return Urql.useQuery<NotificationsQuery, NotificationsQueryVariables>({
    query: NotificationsDocument,
    ...options,
  });
}
export const PrefecturesDocument = gql`
  query Prefectures {
    prefectures {
      id
      name
    }
  }
`;

export function usePrefecturesQuery(
  options?: Omit<Urql.UseQueryArgs<PrefecturesQueryVariables>, "query">,
) {
  return Urql.useQuery<PrefecturesQuery, PrefecturesQueryVariables>({
    query: PrefecturesDocument,
    ...options,
  });
}
export const ProfileEditPageDataDocument = gql`
  query ProfileEditPageData {
    me {
      user {
        id
        displayName
        userName
        biography
        avatarImageUrl
        birthYear
        birthMonth
        birthDay
        showYear
        allergies {
          id
        }
        municipalities {
          id
          name
          area {
            id
            name
            prefecture {
              id
              name
            }
          }
        }
        private
      }
    }
  }
`;

export function useProfileEditPageDataQuery(
  options?: Omit<Urql.UseQueryArgs<ProfileEditPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    ProfileEditPageDataQuery,
    ProfileEditPageDataQueryVariables
  >({ query: ProfileEditPageDataDocument, ...options });
}
export const PromotionCodeDocument = gql`
  query PromotionCode {
    giftDraft {
      id
      promotionCode
    }
  }
`;

export function usePromotionCodeQuery(
  options?: Omit<Urql.UseQueryArgs<PromotionCodeQueryVariables>, "query">,
) {
  return Urql.useQuery<PromotionCodeQuery, PromotionCodeQueryVariables>({
    query: PromotionCodeDocument,
    ...options,
  });
}
export const PurchaseGiftDocument = gql`
  mutation PurchaseGift {
    purchaseGift {
      code
      message
      errors {
        key
        message
      }
      gift {
        id
      }
    }
  }
`;

export function usePurchaseGiftMutation() {
  return Urql.useMutation<PurchaseGiftMutation, PurchaseGiftMutationVariables>(
    PurchaseGiftDocument,
  );
}
export const PushNotificationSettingPageDataDocument = gql`
  query PushNotificationSettingPageData {
    setting {
      id
      pushNotifications
    }
  }
`;

export function usePushNotificationSettingPageDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<PushNotificationSettingPageDataQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    PushNotificationSettingPageDataQuery,
    PushNotificationSettingPageDataQueryVariables
  >({ query: PushNotificationSettingPageDataDocument, ...options });
}
export const ReceivedFollowRequestsDocument = gql`
  query ReceivedFollowRequests($first: Int, $after: String) {
    receivedFollowRequests(first: $first, after: $after) {
      edges {
        node {
          id
          userName
          displayName
          avatarImageUrl
          relationStatus
          followingMe
          private
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export function useReceivedFollowRequestsQuery(
  options?: Omit<
    Urql.UseQueryArgs<ReceivedFollowRequestsQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    ReceivedFollowRequestsQuery,
    ReceivedFollowRequestsQueryVariables
  >({ query: ReceivedFollowRequestsDocument, ...options });
}
export const ReconcileGiftSkuDocument = gql`
  mutation ReconcileGiftSku($id: ID!) {
    reconcileGiftSku(id: $id) {
      code
      message
      giftSku {
        id
        usedAt
      }
    }
  }
`;

export function useReconcileGiftSkuMutation() {
  return Urql.useMutation<
    ReconcileGiftSkuMutation,
    ReconcileGiftSkuMutationVariables
  >(ReconcileGiftSkuDocument);
}
export const RejectFollowRequestDocument = gql`
  mutation RejectFollowRequest($userId: ID!) {
    rejectFollowRequest(userId: $userId) {
      code
      message
      user {
        id
        followingMe
      }
    }
  }
`;

export function useRejectFollowRequestMutation() {
  return Urql.useMutation<
    RejectFollowRequestMutation,
    RejectFollowRequestMutationVariables
  >(RejectFollowRequestDocument);
}
export const RemoveCreditCardDocument = gql`
  mutation RemoveCreditCard($id: ID!) {
    removeCreditCard(id: $id) {
      code
      message
    }
  }
`;

export function useRemoveCreditCardMutation() {
  return Urql.useMutation<
    RemoveCreditCardMutation,
    RemoveCreditCardMutationVariables
  >(RemoveCreditCardDocument);
}
export const ReportUserDocument = gql`
  mutation ReportUser($id: ID!, $input: ReportUserInput!) {
    reportUser(id: $id, input: $input) {
      code
      message
    }
  }
`;

export function useReportUserMutation() {
  return Urql.useMutation<ReportUserMutation, ReportUserMutationVariables>(
    ReportUserDocument,
  );
}
export const SaveGiftDocument = gql`
  mutation SaveGift($id: ID!) {
    saveGift(id: $id) {
      code
      message
      gift {
        id
        savedAt
      }
    }
  }
`;

export function useSaveGiftMutation() {
  return Urql.useMutation<SaveGiftMutation, SaveGiftMutationVariables>(
    SaveGiftDocument,
  );
}
export const SavedGiftSkusDocument = gql`
  query SavedGiftSkus(
    $first: Int
    $after: String
    $input: SavedGiftSkusInput!
  ) {
    savedGiftSkus(first: $first, after: $after, input: $input) {
      edges {
        node {
          id
          gift {
            id
            gifterName
            secret
          }
          sku {
            id
            item {
              id
              name
              brand {
                id
                displayName
              }
              topImage {
                id
                url
              }
            }
            skuGroupValue1 {
              id
              name
              group {
                id
                name
              }
            }
            skuGroupValue2 {
              id
              name
              group {
                id
                name
              }
            }
          }
          receivingWay
          expireAt
          usedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export function useSavedGiftSkusQuery(
  options: Omit<Urql.UseQueryArgs<SavedGiftSkusQueryVariables>, "query">,
) {
  return Urql.useQuery<SavedGiftSkusQuery, SavedGiftSkusQueryVariables>({
    query: SavedGiftSkusDocument,
    ...options,
  });
}
export const SavedGiftsDocument = gql`
  query SavedGifts($first: Int, $after: String) {
    savedGifts(first: $first, after: $after) {
      edges {
        node {
          id
          gifterName
          gifter {
            id
            avatarImageUrl
          }
          savedAt
          message
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export function useSavedGiftsQuery(
  options?: Omit<Urql.UseQueryArgs<SavedGiftsQueryVariables>, "query">,
) {
  return Urql.useQuery<SavedGiftsQuery, SavedGiftsQueryVariables>({
    query: SavedGiftsDocument,
    ...options,
  });
}
export const SearchUsersDocument = gql`
  query SearchUsers($first: Int, $after: String, $input: SearchUsersInput!) {
    searchUsers(first: $first, after: $after, input: $input) {
      edges {
        node {
          id
          userName
          displayName
          avatarImageUrl
          relationStatus
          followingMe
          private
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export function useSearchUsersQuery(
  options: Omit<Urql.UseQueryArgs<SearchUsersQueryVariables>, "query">,
) {
  return Urql.useQuery<SearchUsersQuery, SearchUsersQueryVariables>({
    query: SearchUsersDocument,
    ...options,
  });
}
export const SettingDocument = gql`
  query Setting {
    setting {
      id
      email
    }
  }
`;

export function useSettingQuery(
  options?: Omit<Urql.UseQueryArgs<SettingQueryVariables>, "query">,
) {
  return Urql.useQuery<SettingQuery, SettingQueryVariables>({
    query: SettingDocument,
    ...options,
  });
}
export const ShippersDocument = gql`
  query Shippers {
    shippers {
      id
      name
    }
  }
`;

export function useShippersQuery(
  options?: Omit<Urql.UseQueryArgs<ShippersQueryVariables>, "query">,
) {
  return Urql.useQuery<ShippersQuery, ShippersQueryVariables>({
    query: ShippersDocument,
    ...options,
  });
}
export const SignUpDocument = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useSignUpMutation() {
  return Urql.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
  );
}
export const SpotsDocument = gql`
  query Spots {
    prefectures {
      id
      name
      areas {
        id
        name
        municipalities {
          id
          name
        }
      }
    }
  }
`;

export function useSpotsQuery(
  options?: Omit<Urql.UseQueryArgs<SpotsQueryVariables>, "query">,
) {
  return Urql.useQuery<SpotsQuery, SpotsQueryVariables>({
    query: SpotsDocument,
    ...options,
  });
}
export const StepLabelDataDocument = gql`
  query StepLabelData {
    giftDraft {
      id
      giftDraftDeliveries {
        id
        shipper {
          id
        }
        deliveryOptions {
          id
          name
          deliveryOptionItems {
            id
          }
        }
      }
      giftDraftSkus {
        id
        sku {
          options {
            id
          }
        }
      }
    }
  }
`;

export function useStepLabelDataQuery(
  options?: Omit<Urql.UseQueryArgs<StepLabelDataQueryVariables>, "query">,
) {
  return Urql.useQuery<StepLabelDataQuery, StepLabelDataQueryVariables>({
    query: StepLabelDataDocument,
    ...options,
  });
}
export const StoreEditPageDataDocument = gql`
  query StoreEditPageData($id: ID!) {
    store(id: $id) {
      name
      address
      transportation
      businessHours
      phoneNumber
      googleMapSrc
      municipalities {
        id
      }
    }
  }
`;

export function useStoreEditPageDataQuery(
  options: Omit<Urql.UseQueryArgs<StoreEditPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<StoreEditPageDataQuery, StoreEditPageDataQueryVariables>(
    { query: StoreEditPageDataDocument, ...options },
  );
}
export const TransferAnniversariesDocument = gql`
  mutation TransferAnniversaries($input: TransferAnniversariesInput!) {
    transferAnniversaries(input: $input) {
      code
      message
    }
  }
`;

export function useTransferAnniversariesMutation() {
  return Urql.useMutation<
    TransferAnniversariesMutation,
    TransferAnniversariesMutationVariables
  >(TransferAnniversariesDocument);
}
export const UnblockUserDocument = gql`
  mutation UnblockUser($id: ID!) {
    unblockUser(id: $id) {
      code
      message
      user {
        id
        relationStatus
      }
    }
  }
`;

export function useUnblockUserMutation() {
  return Urql.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(
    UnblockUserDocument,
  );
}
export const UnfollowUserDocument = gql`
  mutation UnfollowUser($id: ID!) {
    unfollowUser(id: $id) {
      code
      message
      user {
        id
        relationStatus
      }
    }
  }
`;

export function useUnfollowUserMutation() {
  return Urql.useMutation<UnfollowUserMutation, UnfollowUserMutationVariables>(
    UnfollowUserDocument,
  );
}
export const UnlikeSkuDocument = gql`
  mutation UnlikeSku($id: ID!) {
    unlikeSku(id: $id) {
      code
      message
      sku {
        id
        liked
      }
    }
  }
`;

export function useUnlikeSkuMutation() {
  return Urql.useMutation<UnlikeSkuMutation, UnlikeSkuMutationVariables>(
    UnlikeSkuDocument,
  );
}
export const UnwishSkuDocument = gql`
  mutation UnwishSku($id: ID!) {
    unwishSku(id: $id) {
      code
      message
      sku {
        id
        wished
      }
    }
  }
`;

export function useUnwishSkuMutation() {
  return Urql.useMutation<UnwishSkuMutation, UnwishSkuMutationVariables>(
    UnwishSkuDocument,
  );
}
export const UpdateAnniversaryDocument = gql`
  mutation UpdateAnniversary($id: ID!, $input: UpdateAnniversaryInput!) {
    updateAnniversary(id: $id, input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useUpdateAnniversaryMutation() {
  return Urql.useMutation<
    UpdateAnniversaryMutation,
    UpdateAnniversaryMutationVariables
  >(UpdateAnniversaryDocument);
}
export const UpdateAnniversaryUserDocument = gql`
  mutation UpdateAnniversaryUser(
    $id: ID!
    $input: UpdateAnniversaryUserInput!
  ) {
    updateAnniversaryUser(id: $id, input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useUpdateAnniversaryUserMutation() {
  return Urql.useMutation<
    UpdateAnniversaryUserMutation,
    UpdateAnniversaryUserMutationVariables
  >(UpdateAnniversaryUserDocument);
}
export const UpdateBrandDocument = gql`
  mutation UpdateBrand($id: ID!, $input: UpdateBrandInput!) {
    updateBrand(id: $id, input: $input) {
      code
      message
      errors {
        key
        message
      }
      brand {
        id
        brandName
      }
    }
  }
`;

export function useUpdateBrandMutation() {
  return Urql.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(
    UpdateBrandDocument,
  );
}
export const UpdateConsigneeDocument = gql`
  mutation UpdateConsignee($id: ID!, $input: UpdateConsigneeInput!) {
    updateConsignee(id: $id, input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useUpdateConsigneeMutation() {
  return Urql.useMutation<
    UpdateConsigneeMutation,
    UpdateConsigneeMutationVariables
  >(UpdateConsigneeDocument);
}
export const UpdateGiftConsigneeDraftDocument = gql`
  mutation UpdateGiftConsigneeDraft(
    $giftId: ID!
    $input: UpdateGiftConsigneeDraftInput!
  ) {
    updateGiftConsigneeDraft(giftId: $giftId, input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useUpdateGiftConsigneeDraftMutation() {
  return Urql.useMutation<
    UpdateGiftConsigneeDraftMutation,
    UpdateGiftConsigneeDraftMutationVariables
  >(UpdateGiftConsigneeDraftDocument);
}
export const UpdateGiftDeliveryDocument = gql`
  mutation UpdateGiftDelivery($id: ID!, $input: UpdateGiftDeliveryInput!) {
    updateGiftDelivery(id: $id, input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useUpdateGiftDeliveryMutation() {
  return Urql.useMutation<
    UpdateGiftDeliveryMutation,
    UpdateGiftDeliveryMutationVariables
  >(UpdateGiftDeliveryDocument);
}
export const UpdateGiftDraftDocument = gql`
  mutation UpdateGiftDraft($input: UpdateGiftDraftInput!) {
    updateGiftDraft(input: $input) {
      code
      message
      errors {
        key
        message
      }
      giftDraft {
        id
        secret
      }
    }
  }
`;

export function useUpdateGiftDraftMutation() {
  return Urql.useMutation<
    UpdateGiftDraftMutation,
    UpdateGiftDraftMutationVariables
  >(UpdateGiftDraftDocument);
}
export const UpdateGiftDraftDeliveryDocument = gql`
  mutation UpdateGiftDraftDelivery(
    $id: ID!
    $input: UpdateGiftDraftDeliveryInput!
  ) {
    updateGiftDraftDelivery(id: $id, input: $input) {
      code
      message
      giftDraftDelivery {
        id
        giftDraft {
          id
          optionsAmount {
            amount
            details {
              id
              optionName
              optionItemName
              count
              amount
            }
          }
          itemsAmount
          totalAmount
        }
        selectedDeliveryOptionItems {
          id
        }
      }
    }
  }
`;

export function useUpdateGiftDraftDeliveryMutation() {
  return Urql.useMutation<
    UpdateGiftDraftDeliveryMutation,
    UpdateGiftDraftDeliveryMutationVariables
  >(UpdateGiftDraftDeliveryDocument);
}
export const UpdateGiftDraftSkuDocument = gql`
  mutation UpdateGiftDraftSku($id: ID!, $input: UpdateGiftDraftSkuInput!) {
    updateGiftDraftSku(id: $id, input: $input) {
      code
      message
      giftDraft {
        id
        optionsAmount {
          amount
          details {
            id
            optionName
            optionItemName
            count
            amount
          }
        }
        itemsAmount
        totalAmount
        giftDraftSkus {
          id
          optionItems {
            id
          }
        }
      }
    }
  }
`;

export function useUpdateGiftDraftSkuMutation() {
  return Urql.useMutation<
    UpdateGiftDraftSkuMutation,
    UpdateGiftDraftSkuMutationVariables
  >(UpdateGiftDraftSkuDocument);
}
export const UpdateGiftOrderDocument = gql`
  mutation UpdateGiftOrder($input: UpdateGiftOrderInput!) {
    updateGiftOrder(input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useUpdateGiftOrderMutation() {
  return Urql.useMutation<
    UpdateGiftOrderMutation,
    UpdateGiftOrderMutationVariables
  >(UpdateGiftOrderDocument);
}
export const UpdateItemDocument = gql`
  mutation UpdateItem($id: ID!, $input: UpdateItemInput!) {
    updateItem(id: $id, input: $input) {
      code
      message
      errors {
        key
        message
      }
    }
  }
`;

export function useUpdateItemMutation() {
  return Urql.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(
    UpdateItemDocument,
  );
}
export const UpdateMeDocument = gql`
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      code
      message
      errors {
        key
        message
      }
      me {
        id
        displayName
        userName
        biography
        avatarImageUrl
        birthYear
        birthMonth
        birthDay
        showYear
        allergies {
          id
        }
        municipalities {
          id
          name
          area {
            id
            name
            prefecture {
              id
              name
            }
          }
        }
        private
      }
    }
  }
`;

export function useUpdateMeMutation() {
  return Urql.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(
    UpdateMeDocument,
  );
}
export const UpdateSettingDocument = gql`
  mutation UpdateSetting($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      code
      errors {
        key
        message
      }
      message
      setting {
        id
        emailNotifications
        pushNotifications
      }
    }
  }
`;

export function useUpdateSettingMutation() {
  return Urql.useMutation<
    UpdateSettingMutation,
    UpdateSettingMutationVariables
  >(UpdateSettingDocument);
}
export const UpdateStoreDocument = gql`
  mutation UpdateStore($id: ID!, $input: UpdateStoreInput!) {
    updateStore(id: $id, input: $input) {
      code
      message
      errors {
        key
        message
      }
      store {
        id
        brand {
          id
          brandName
        }
      }
    }
  }
`;

export function useUpdateStoreMutation() {
  return Urql.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(
    UpdateStoreDocument,
  );
}
export const UploadImageDocument = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      code
      message
      image {
        id
        url
        width
        height
      }
    }
  }
`;

export function useUploadImageMutation() {
  return Urql.useMutation<UploadImageMutation, UploadImageMutationVariables>(
    UploadImageDocument,
  );
}
export const UploadImageByUrlDocument = gql`
  mutation UploadImageByUrl($url: String!) {
    uploadImageByUrl(url: $url) {
      code
      message
      image {
        id
      }
    }
  }
`;

export function useUploadImageByUrlMutation() {
  return Urql.useMutation<
    UploadImageByUrlMutation,
    UploadImageByUrlMutationVariables
  >(UploadImageByUrlDocument);
}
export const UserAnniversariesDocument = gql`
  query UserAnniversaries($userName: String!) {
    user(userName: $userName) {
      id
      anniversaries {
        id
        anniversaryUser {
          user {
            id
            avatarImageUrl
          }
          name
          relation
        }
        repetitions {
          id
          start {
            year
            month
            day
          }
          until {
            year
            month
            day
          }
          type
          name
          frequency
          private
        }
      }
    }
  }
`;

export function useUserAnniversariesQuery(
  options: Omit<Urql.UseQueryArgs<UserAnniversariesQueryVariables>, "query">,
) {
  return Urql.useQuery<UserAnniversariesQuery, UserAnniversariesQueryVariables>(
    { query: UserAnniversariesDocument, ...options },
  );
}
export const UserFolloweesDataDocument = gql`
  query UserFolloweesData($first: Int, $after: String, $userName: String!) {
    user(userName: $userName) {
      id
      userName
      displayName
      followeeCount
      followerCount
      followees(first: $first, after: $after) {
        edges {
          node {
            id
            userName
            displayName
            avatarImageUrl
            relationStatus
            followingMe
            private
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
`;

export function useUserFolloweesDataQuery(
  options: Omit<Urql.UseQueryArgs<UserFolloweesDataQueryVariables>, "query">,
) {
  return Urql.useQuery<UserFolloweesDataQuery, UserFolloweesDataQueryVariables>(
    { query: UserFolloweesDataDocument, ...options },
  );
}
export const UserFollowersDataDocument = gql`
  query UserFollowersData($first: Int, $after: String, $userName: String!) {
    user(userName: $userName) {
      id
      userName
      displayName
      private
      followeeCount
      followerCount
      followers(first: $first, after: $after) {
        edges {
          node {
            id
            userName
            displayName
            avatarImageUrl
            relationStatus
            followingMe
            private
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
    receivedFollowRequests(first: 1) {
      totalCount
    }
  }
`;

export function useUserFollowersDataQuery(
  options: Omit<Urql.UseQueryArgs<UserFollowersDataQueryVariables>, "query">,
) {
  return Urql.useQuery<UserFollowersDataQuery, UserFollowersDataQueryVariables>(
    { query: UserFollowersDataDocument, ...options },
  );
}
export const UserNameAvailabilityDocument = gql`
  query UserNameAvailability($userName: String!) {
    userNameAvailability(userName: $userName) {
      available
    }
  }
`;

export function useUserNameAvailabilityQuery(
  options: Omit<Urql.UseQueryArgs<UserNameAvailabilityQueryVariables>, "query">,
) {
  return Urql.useQuery<
    UserNameAvailabilityQuery,
    UserNameAvailabilityQueryVariables
  >({ query: UserNameAvailabilityDocument, ...options });
}
export const UserPageInitialDataDocument = gql`
  query UserPageInitialData($userName: String!) {
    user(userName: $userName) {
      id
      userName
      displayName
      biography
      avatarImageUrl
      private
      birthYear
      birthMonth
      birthDay
      showYear
      municipalities {
        id
        name
      }
      followeeCount
      followerCount
      allergies {
        id
        name
      }
    }
  }
`;

export function useUserPageInitialDataQuery(
  options: Omit<Urql.UseQueryArgs<UserPageInitialDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    UserPageInitialDataQuery,
    UserPageInitialDataQueryVariables
  >({ query: UserPageInitialDataDocument, ...options });
}
export const UserPageUserDataDocument = gql`
  query UserPageUserData($userName: String!) {
    user(userName: $userName) {
      id
      biography
      birthYear
      birthMonth
      birthDay
      municipalities {
        id
        name
      }
      allergies {
        id
        name
      }
      relationStatus
      followingMe
      blockingMe
    }
  }
`;

export function useUserPageUserDataQuery(
  options: Omit<Urql.UseQueryArgs<UserPageUserDataQueryVariables>, "query">,
) {
  return Urql.useQuery<UserPageUserDataQuery, UserPageUserDataQueryVariables>({
    query: UserPageUserDataDocument,
    ...options,
  });
}
export const UserWishListPageDataDocument = gql`
  query UserWishListPageData($userName: String!) {
    user(userName: $userName) {
      id
      displayName
    }
  }
`;

export function useUserWishListPageDataQuery(
  options: Omit<Urql.UseQueryArgs<UserWishListPageDataQueryVariables>, "query">,
) {
  return Urql.useQuery<
    UserWishListPageDataQuery,
    UserWishListPageDataQueryVariables
  >({ query: UserWishListPageDataDocument, ...options });
}
export const UserWishesDocument = gql`
  query UserWishes($userName: String!, $first: Int, $after: String) {
    user(userName: $userName) {
      id
      wishes(first: $first, after: $after) {
        edges {
          node {
            id
            item {
              id
              topImage {
                id
                url
              }
              name
              brand {
                id
                displayName
              }
              municipalities {
                id
                name
              }
            }
            skuGroupValue1 {
              id
              name
              group {
                id
                name
              }
            }
            skuGroupValue2 {
              id
              name
              group {
                id
                name
              }
            }
            price
            liked
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export function useUserWishesQuery(
  options: Omit<Urql.UseQueryArgs<UserWishesQueryVariables>, "query">,
) {
  return Urql.useQuery<UserWishesQuery, UserWishesQueryVariables>({
    query: UserWishesDocument,
    ...options,
  });
}
export const WishSkuDocument = gql`
  mutation WishSku($id: ID!) {
    wishSku(id: $id) {
      code
      message
      sku {
        id
        wished
      }
    }
  }
`;

export function useWishSkuMutation() {
  return Urql.useMutation<WishSkuMutation, WishSkuMutationVariables>(
    WishSkuDocument,
  );
}
export const WishesDocument = gql`
  query Wishes($first: Int, $after: String) {
    me {
      user {
        id
        wishes(first: $first, after: $after) {
          edges {
            node {
              id
              item {
                id
                topImage {
                  id
                  url
                }
                name
                brand {
                  id
                  displayName
                }
              }
              skuGroupValue1 {
                id
                name
                group {
                  id
                  name
                }
              }
              skuGroupValue2 {
                id
                name
                group {
                  id
                  name
                }
              }
              price
              liked
              wished
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
`;

export function useWishesQuery(
  options?: Omit<Urql.UseQueryArgs<WishesQueryVariables>, "query">,
) {
  return Urql.useQuery<WishesQuery, WishesQueryVariables>({
    query: WishesDocument,
    ...options,
  });
}
export const ItemCategoryGroupsDocument = gql`
  query itemCategoryGroups {
    itemCategoryGroups {
      id
      name
      subgroups {
        id
        name
        categories {
          id
          name
        }
      }
    }
  }
`;

export function useItemCategoryGroupsQuery(
  options?: Omit<Urql.UseQueryArgs<ItemCategoryGroupsQueryVariables>, "query">,
) {
  return Urql.useQuery<
    ItemCategoryGroupsQuery,
    ItemCategoryGroupsQueryVariables
  >({ query: ItemCategoryGroupsDocument, ...options });
}
