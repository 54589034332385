import { useRouter } from "next/router";
import { useMediaQueries } from "../../hooks/useMediaQueries";
import { useSession } from "../../hooks/useSession";
import { i18n } from "../../i18n";
import { staticPath } from "../../utils/$path";
import { BackButton } from "../ui/BackButton";
import { BottomMenu } from "../ui/BottomMenu";
import { ButtonLikeLink } from "../ui/ButtonLikeLink";
import { HeaderMenu } from "../ui/HeaderMenu";
import { Layout } from "../ui/Layout";
import { Loading } from "../ui/Loading";
import { PrettyLink } from "../ui/PrettyLink";

// Web 版をリリースするまでは下記のパス以外にアクセスできないようにする
const pathnameWhitelist = [
  "/signin",
  "/signin/email",
  "/signout",
  "/password/reset",
  "/g/[id]",
  "/g/[id]/consignee",
  "/i/[id]",
  "/privacy-policy",
  "/tos",
  "/faq",
  "/tokutei",
  "/company",
  "/inquiry",
  "/articles/how-to-use-giff-letter",
  "/articles/haha-no-hi-2023",
];

const AppStoreIcon: React.FC = () => {
  return (
    <svg
      width="131"
      height="48"
      viewBox="0 0 131 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_20370_147870)">
        <path
          d="M119.196 0H11.4507C11.0106 0 10.5758 0 10.137 0.0024C9.76959 0.0048 9.40514 0.011772 9.03423 0.01764C8.22845 0.0271222 7.42456 0.0980115 6.62955 0.229692C5.83566 0.364239 5.06664 0.617899 4.3485 0.982092C3.63123 1.34937 2.97585 1.8266 2.40612 2.39648C1.8334 2.96476 1.35599 3.62162 0.992253 4.34179C0.627514 5.06053 0.374602 5.83073 0.242253 6.62578C0.108646 7.41982 0.0367513 8.22303 0.0272132 9.02818C0.0160772 9.3961 0.0149012 9.76524 0.0090332 10.1332V37.8703C0.0149012 38.2429 0.0160772 38.6039 0.0272132 38.9766C0.0367542 39.7817 0.108649 40.5849 0.242253 41.3789C0.374236 42.1744 0.627163 42.945 0.992253 43.664C1.35582 44.3819 1.8333 45.0361 2.40612 45.6012C2.97369 46.1736 3.6295 46.6512 4.3485 47.0156C5.06664 47.3808 5.83557 47.636 6.62955 47.7726C7.4247 47.9032 8.2285 47.9741 9.03423 47.9848C9.40514 47.9929 9.76959 47.9976 10.137 47.9976C10.5758 48 11.0106 48 11.4507 48H119.196C119.627 48 120.065 48 120.497 47.9976C120.862 47.9976 121.237 47.9929 121.603 47.9848C122.407 47.9747 123.209 47.9038 124.003 47.7726C124.799 47.635 125.571 47.3799 126.293 47.0156C127.011 46.651 127.666 46.1734 128.233 45.6012C128.805 45.0339 129.283 44.3801 129.651 43.664C130.014 42.9445 130.264 42.1739 130.394 41.3789C130.528 40.5848 130.602 39.7818 130.617 38.9766C130.622 38.6039 130.622 38.2429 130.622 37.8703C130.631 37.4344 130.631 37.0008 130.631 36.5578V11.4434C130.631 11.0039 130.631 10.568 130.622 10.1332C130.622 9.76524 130.622 9.3961 130.617 9.02813C130.602 8.22291 130.528 7.41988 130.394 6.62573C130.264 5.83114 130.013 5.061 129.651 4.34174C128.911 2.89824 127.736 1.72323 126.293 0.981984C125.571 0.618681 124.799 0.365089 124.003 0.229584C123.209 0.0973234 122.407 0.0264093 121.603 0.017472C121.237 0.011616 120.862 0.004584 120.497 0.002244C120.065 0 119.627 0 119.196 0Z"
          fill="#A6A6A6"
        />
        <path
          d="M10.1428 46.9508C9.77721 46.9508 9.42043 46.9461 9.05768 46.9379C8.30622 46.9281 7.55651 46.8627 6.81471 46.7422C6.12302 46.6231 5.45295 46.4015 4.82662 46.0847C4.20603 45.7706 3.64 45.3587 3.15022 44.8648C2.65335 44.3767 2.23968 43.8107 1.92561 43.189C1.60808 42.5633 1.38832 41.8927 1.27401 41.2004C1.15056 40.4565 1.08377 39.7044 1.07421 38.9504C1.0666 38.6973 1.05664 37.8547 1.05664 37.8547V10.134C1.05664 10.134 1.06725 9.3043 1.07427 9.06054C1.08342 8.30776 1.14982 7.55676 1.2729 6.81406C1.38743 6.11988 1.60735 5.44728 1.92506 4.81954C2.23797 4.19831 2.64935 3.63181 3.14322 3.142C3.63655 2.64753 4.20439 2.23351 4.82603 1.91503C5.45093 1.59929 6.11972 1.37926 6.81002 1.26231C7.55425 1.14058 8.30656 1.07478 9.06063 1.06543L10.1434 1.05078H120.491L121.586 1.06602C122.334 1.0749 123.079 1.14012 123.816 1.26113C124.514 1.37955 125.189 1.60112 125.821 1.91855C127.067 2.56036 128.08 3.57578 128.719 4.82247C129.032 5.44587 129.249 6.11299 129.362 6.80118C129.486 7.54998 129.556 8.30688 129.57 9.06583C129.574 9.40567 129.574 9.77071 129.574 10.134C129.583 10.584 129.583 11.0123 129.583 11.4441V36.5585C129.583 36.9945 129.583 37.4199 129.574 37.8488C129.574 38.239 129.574 38.5965 129.569 38.9644C129.555 39.7098 129.487 40.4532 129.364 41.1886C129.252 41.8859 129.034 42.5618 128.716 43.1926C128.4 43.8075 127.989 44.3687 127.497 44.8555C127.007 45.352 126.44 45.7663 125.818 46.0825C125.188 46.4017 124.513 46.6241 123.817 46.7422C123.075 46.8634 122.325 46.9288 121.573 46.9379C121.222 46.9461 120.854 46.9508 120.497 46.9508L119.196 46.9532L10.1428 46.9508Z"
          fill="black"
        />
        <path
          d="M49.4099 19.8622H44.548L43.3804 23.3098H41.3212L45.9263 10.5547H48.0659L52.671 23.3097H50.5765L49.4099 19.8622ZM45.0515 18.2713H48.9054L47.0056 12.6762H46.9525L45.0515 18.2713Z"
          fill="white"
        />
        <path
          d="M62.6164 18.6632C62.6164 21.5531 61.0697 23.4098 58.7355 23.4098C58.1442 23.4407 57.5562 23.3045 57.0387 23.0168C56.5213 22.729 56.0953 22.3014 55.8096 21.7828H55.7654V26.3889H53.8566V14.013H55.7042V15.5598H55.7393C56.0382 15.0437 56.4714 14.6183 56.9928 14.3288C57.5142 14.0393 58.1043 13.8965 58.7004 13.9158C61.0606 13.9157 62.6164 15.7814 62.6164 18.6632ZM60.6544 18.6632C60.6544 16.7804 59.6814 15.5427 58.1969 15.5427C56.7384 15.5427 55.7574 16.8066 55.7574 18.6632C55.7574 20.537 56.7384 21.7918 58.1969 21.7918C59.6814 21.7918 60.6544 20.5631 60.6544 18.6632Z"
          fill="white"
        />
        <path
          d="M72.8522 18.6632C72.8522 21.5531 71.305 23.4098 68.9709 23.4098C68.3796 23.4407 67.7915 23.3045 67.2741 23.0168C66.7566 22.729 66.3306 22.3014 66.0449 21.7828H66.0008V26.3889H64.0919V14.013H65.9396V15.5598H65.9747C66.2736 15.0437 66.7067 14.6183 67.2281 14.3288C67.7496 14.0393 68.3397 13.8965 68.9358 13.9158C71.296 13.9157 72.8522 15.7814 72.8522 18.6632ZM70.8897 18.6632C70.8897 16.7804 69.9167 15.5427 68.4322 15.5427C66.9737 15.5427 65.9927 16.8066 65.9927 18.6632C65.9927 20.537 66.9737 21.7918 68.4322 21.7918C69.9168 21.7918 70.8897 20.5631 70.8897 18.6632Z"
          fill="white"
        />
        <path
          d="M79.616 19.7576C79.7574 21.0225 80.9862 21.853 82.6653 21.853C84.2742 21.853 85.4318 21.0225 85.4318 19.882C85.4318 18.892 84.7337 18.2992 83.0806 17.8929L81.4275 17.4946C79.0854 16.9289 77.998 15.8336 77.998 14.0561C77.998 11.8553 79.9159 10.3438 82.6382 10.3438C85.3345 10.3438 87.1822 11.8554 87.2443 14.0561H85.3174C85.2021 12.7832 84.1498 12.0149 82.6122 12.0149C81.0745 12.0149 80.0222 12.7923 80.0222 13.9238C80.0222 14.8255 80.6942 15.3562 82.3383 15.7624L83.7436 16.1075C86.3606 16.7264 87.447 17.7776 87.447 19.6433C87.447 22.0296 85.5472 23.5242 82.5239 23.5242C79.6952 23.5242 77.7854 22.0647 77.662 19.7576L79.616 19.7576Z"
          fill="white"
        />
        <path
          d="M91.5686 11.8126V14.0134H93.337V15.525H91.5686V20.6517C91.5686 21.4481 91.9227 21.8193 92.7001 21.8193C92.91 21.8157 93.1196 21.8009 93.328 21.7752V23.2778C92.9785 23.3431 92.6232 23.3727 92.2677 23.366C90.3849 23.366 89.6507 22.6589 89.6507 20.8554V15.525H88.2986V14.0133H89.6507V11.8125L91.5686 11.8126Z"
          fill="white"
        />
        <path
          d="M94.3601 18.663C94.3601 15.7371 96.0834 13.8984 98.7706 13.8984C101.467 13.8984 103.182 15.7371 103.182 18.663C103.182 21.597 101.476 23.4276 98.7706 23.4276C96.0663 23.4276 94.3601 21.597 94.3601 18.663ZM101.237 18.663C101.237 16.6559 100.317 15.4713 98.7706 15.4713C97.2239 15.4713 96.3041 16.6649 96.3041 18.663C96.3041 20.6782 97.2239 21.8538 98.7706 21.8538C100.317 21.8538 101.237 20.6782 101.237 18.663Z"
          fill="white"
        />
        <path
          d="M104.756 14.013H106.577V15.5958H106.621C106.744 15.1015 107.033 14.6646 107.441 14.3586C107.848 14.0527 108.348 13.8962 108.857 13.9157C109.077 13.9149 109.297 13.9388 109.511 13.9869V15.7724C109.234 15.6875 108.944 15.6485 108.654 15.657C108.377 15.6458 108.1 15.6947 107.843 15.8003C107.587 15.906 107.356 16.066 107.167 16.2693C106.978 16.4725 106.835 16.7143 106.749 16.9779C106.662 17.2416 106.633 17.5209 106.665 17.7966V23.3125H104.756V14.013Z"
          fill="white"
        />
        <path
          d="M118.313 20.5809C118.103 21.4597 117.581 22.2326 116.845 22.7561C116.108 23.2796 115.207 23.5182 114.308 23.4276C111.603 23.4276 109.924 21.6151 109.924 18.7072C109.924 15.7902 111.612 13.8984 114.228 13.8984C116.801 13.8984 118.419 15.6658 118.419 18.4855V19.1395H111.851V19.2548C111.82 19.5971 111.863 19.9419 111.976 20.2663C112.09 20.5907 112.271 20.8873 112.507 21.1364C112.744 21.3856 113.031 21.5815 113.349 21.7112C113.667 21.841 114.009 21.9015 114.352 21.8889C114.803 21.9312 115.256 21.8267 115.643 21.591C116.03 21.3554 116.331 21.0011 116.5 20.5809L118.313 20.5809ZM111.86 17.8054H116.509C116.526 17.4977 116.479 17.1897 116.372 16.9009C116.264 16.612 116.098 16.3485 115.884 16.1269C115.67 15.9053 115.412 15.7303 115.127 15.613C114.842 15.4957 114.536 15.4385 114.228 15.4452C113.917 15.4433 113.609 15.503 113.321 15.6209C113.034 15.7388 112.772 15.9124 112.552 16.1318C112.332 16.3513 112.157 16.6121 112.039 16.8994C111.92 17.1866 111.859 17.4945 111.86 17.8054Z"
          fill="white"
        />
        <path
          d="M43.7328 36.9719L43.8119 35.9887C44.1806 36.1077 44.5654 36.169 44.9528 36.1704C45.3032 36.1704 45.4385 36.1364 45.6084 35.8985C46.0215 35.0337 46.2006 34.0756 46.1276 33.12C46.1276 32.1028 45.8117 31.8087 45.0096 31.8087C44.8285 31.8087 44.6252 31.8204 44.399 31.8321C43.9674 33.5866 43.25 35.2581 42.2756 36.7797L41.3375 36.2711C42.2527 34.956 42.9225 33.4863 43.3145 31.9328C42.6955 31.9998 42.0808 32.1019 41.4735 32.2387L41.2701 31.2438C41.9592 31.1195 42.7836 31.007 43.5518 30.9391C43.7097 30.1889 43.8267 29.4308 43.9022 28.668L45.0096 28.7922C44.9076 29.4813 44.7834 30.1715 44.6252 30.8488H45.0659C46.5008 30.8488 47.1899 31.5602 47.1899 33.0742C47.2521 34.2295 47.027 35.3823 46.5348 36.4293C46.4051 36.67 46.2073 36.867 45.9661 36.9956C45.7248 37.1242 45.451 37.1788 45.1789 37.1524C44.6915 37.1491 44.2061 37.0885 43.7328 36.9719ZM47.3703 30.8829L48.1953 30.3286C49.3392 31.4319 50.0284 32.9236 50.1272 34.5098L49.0426 34.6786C49.0346 33.9658 48.8823 33.2619 48.5949 32.6096C48.3075 31.9572 47.8909 31.3698 47.3703 30.8829Z"
          fill="white"
        />
        <path
          d="M53.8731 37.1289L53.6475 36.1117C53.9299 36.1352 54.3026 36.1457 54.5288 36.1457C56.7653 36.1457 57.6126 35.5141 57.6126 34.5754C57.6126 33.8301 57.1268 33.4012 56.0422 33.4012C54.8598 33.5096 53.7582 34.0484 52.9467 34.9153L52.0766 34.5309C51.9118 33.051 52.2501 31.5587 53.037 30.2945L54.0425 30.6215C53.416 31.4675 53.1116 32.5091 53.1841 33.5593C54.0901 32.9204 55.1608 32.5555 56.2685 32.5081C57.7825 32.5081 58.7201 33.2874 58.7201 34.5531C58.7201 36.1574 57.4544 37.1406 54.4158 37.1406C54.2686 37.1407 54.0542 37.1289 53.8731 37.1289ZM52.8225 29.6277L53.1049 28.6328C54.6079 28.8627 56.092 29.2027 57.5452 29.65L57.2739 30.6332C55.8233 30.165 54.3335 29.8285 52.8225 29.6277Z"
          fill="white"
        />
        <path
          d="M61.9918 36.3508C63.1569 35.9673 64.2034 35.2901 65.0305 34.3844C64.3753 33.8413 63.6677 33.3647 62.9182 32.9617L63.5393 32.1824C64.295 32.5668 65.0135 33.0203 65.6856 33.5371C66.2254 32.7499 66.6019 31.8625 66.793 30.9274H63.912C63.25 31.9997 62.4182 32.9575 61.4493 33.7633L60.6243 33.052C62.0189 31.9015 63.0995 30.4167 63.7655 28.736L64.8501 28.9961C64.7258 29.3125 64.6017 29.6395 64.4545 29.9453H67.2565L68.0587 30.318C67.8641 31.8514 67.2583 33.3036 66.3056 34.5208C65.3529 35.738 64.0887 36.6748 62.647 37.232L61.9918 36.3508ZM66.759 28.4652L67.3918 28.1594C67.713 28.5894 67.9823 29.056 68.194 29.5492L67.4821 29.8774C67.3029 29.3775 67.0599 28.9028 66.759 28.4652ZM68.1711 28.3516L68.8151 28.0469C69.1406 28.4903 69.4172 28.9677 69.6401 29.4707L68.9282 29.7754C68.7389 29.2699 68.4844 28.7912 68.1711 28.3516Z"
          fill="white"
        />
        <path
          d="M73.1147 37.1863L72.5387 36.2711C73.703 36.0853 74.756 35.4716 75.4915 34.5502C76.2271 33.6288 76.5923 32.466 76.5155 31.2894H71.7706V33.5605H70.6292V30.3285H73.4651V28.668H74.6059V30.3285H76.7188L77.7582 30.6227C77.6223 33.8313 76.5494 36.2828 73.1147 37.1863Z"
          fill="white"
        />
        <path
          d="M79.9561 30.4074L80.5889 29.4805C81.5772 29.9322 82.5096 30.4973 83.3674 31.1645L82.6443 32.1359C81.8212 31.4535 80.9185 30.8731 79.9561 30.4074ZM80.1705 35.9773C81.5528 35.7693 82.8655 35.2343 83.9994 34.4168C85.1333 33.5992 86.0556 32.5228 86.6896 31.2769L87.435 32.1137C86.7465 33.4001 85.7726 34.5118 84.5879 35.3635C83.4033 36.2153 82.0393 36.7844 80.6006 37.0273L80.1705 35.9773Z"
          fill="white"
        />
        <path
          d="M96.694 36.5199H89.5878V29.7969H96.694L96.694 36.5199ZM90.7069 30.8363V35.4805H95.5643V30.8363H90.7069Z"
          fill="white"
        />
        <path
          d="M106.868 33.5036H98.8807V32.3516H106.868V33.5036Z"
          fill="white"
        />
        <path
          d="M109.586 28.7039H110.704V31.5961C112.191 32.2149 113.633 32.9362 115.02 33.7547L114.387 34.7484C113.212 33.9961 111.98 33.3352 110.704 32.7714V37.132H109.586V28.7039ZM112.24 29.3144L112.907 28.998C113.233 29.4771 113.503 29.9927 113.71 30.5344L112.975 30.8625C112.798 30.3168 112.551 29.7964 112.24 29.3144ZM113.528 28.8281L114.207 28.5117C114.549 28.988 114.834 29.5038 115.054 30.048L114.319 30.3761C114.117 29.8307 113.852 29.311 113.528 28.8281Z"
          fill="white"
        />
        <path
          d="M29.7316 24.3627C29.7445 23.3611 30.0106 22.3791 30.505 21.5079C30.9994 20.6368 31.7062 19.9049 32.5595 19.3803C32.0174 18.6061 31.3023 17.9689 30.4709 17.5195C29.6395 17.07 28.7148 16.8206 27.7701 16.7911C25.755 16.5795 23.8015 17.9968 22.7746 17.9968C21.7279 17.9968 20.1469 16.8121 18.4444 16.8471C17.3432 16.8827 16.27 17.2029 15.3294 17.7766C14.3887 18.3502 13.6127 19.1578 13.0769 20.1205C10.7561 24.1386 12.4872 30.0439 14.7104 33.2918C15.8226 34.8822 17.1226 36.6588 18.8235 36.5958C20.488 36.5267 21.1096 35.5344 23.1188 35.5344C25.1093 35.5344 25.6925 36.5958 27.428 36.5557C29.2141 36.5267 30.3394 34.9582 31.4126 33.3528C32.2118 32.2195 32.8267 30.9671 33.2347 29.6417C32.1971 29.2029 31.3116 28.4683 30.6886 27.5295C30.0657 26.5907 29.7328 25.4894 29.7316 24.3627Z"
          fill="white"
        />
        <path
          d="M26.4537 14.6536C27.4276 13.4845 27.9073 11.9819 27.7912 10.4648C26.3034 10.6211 24.929 11.3322 23.9421 12.4564C23.4595 13.0056 23.0899 13.6445 22.8544 14.3366C22.6189 15.0287 22.5221 15.7605 22.5696 16.49C23.3138 16.4977 24.05 16.3364 24.7228 16.0183C25.3956 15.7002 25.9874 15.2336 26.4537 14.6536Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20370_147870">
          <rect
            width="130.622"
            height="48"
            fill="white"
            transform="translate(0.0090332)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Preparing: React.FC = () => {
  const { isMd } = useMediaQueries();

  return (
    <Layout
      header={
        <HeaderMenu
          left={<BackButton />}
          center={
            <PrettyLink href="/">
              <img
                src={staticPath.assets.logo_png}
                width={115}
                height={26}
                alt="giff letter"
                decoding="async"
                loading="lazy"
              />
            </PrettyLink>
          }
        />
      }
      bottom={<BottomMenu />}
    >
      <main>
        <div className="mx-auto flex max-w-screen-md flex-col items-center justify-center pb-5 md:mb-24">
          {isMd ? (
            <div className="mb-20">
              <h1 className="mt-12 text-2xl font-bold">
                このページは準備中です
              </h1>
              <p className="mt-4 text-sm">公開までしばらくお待ちください</p>
            </div>
          ) : (
            <img
              src={staticPath.assets.download_app_jpg}
              width={848}
              height={848}
              alt={i18n.downloadApp}
              className="mb-10"
            />
          )}
          <img
            src={staticPath.assets.app_icon_jpg}
            width={80}
            height={80}
            alt="アプリアイコン"
            decoding="async"
            className="rounded-xl"
          />
          <p className="mt-6 font-bold">giff letter公式アプリ</p>
          <p className="mt-4 whitespace-pre-wrap text-sm">
            {
              "アプリならレターやギフトの保存、\nリマインド機能などを利用できます。"
            }
          </p>
          {isMd ? (
            <div className="mt-4 flex space-x-4">
              <PrettyLink href="https://apps.apple.com/jp/app/id1589022685">
                <AppStoreIcon />
              </PrettyLink>
              <PrettyLink href="https://play.google.com/store/apps/details?id=com.giffletter">
                <img
                  src={staticPath.assets.google_play_store_png}
                  width={159}
                  height={46}
                  alt="Google Play Store"
                />
              </PrettyLink>
            </div>
          ) : (
            <div className="mt-4 w-full max-w-[254px]">
              <ButtonLikeLink href="https://link.giff-letter.com/4Yif">
                ダウンロード
              </ButtonLikeLink>
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
};

type Props = {
  children: React.ReactNode;
};

export const OnlyWhitelistPages: React.FC<Props> = ({ children }) => {
  const router = useRouter();
  const [me, isLoading] = useSession();

  if (
    pathnameWhitelist.includes(router.pathname) ||
    process.env["NODE_ENV"] === "development" ||
    me?.role === "ADMIN"
  ) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return <Preparing />;
};
