import Head from "next/head";
import { withBaseUrl } from "../../helpers/withBaseUrl";

const siteName = "giff letter ギフレター";

type Image = {
  url: string;
  type?: "article" | "website";
  width?: number;
  height?: number;
};

type Props = {
  description?: string;
  image?: Image;
  noindex?: boolean;
  path?: string;
  title?: string;
  type?: "article" | "website";
};

export const Seo: React.FC<Props> = ({
  description,
  image,
  noindex = false,
  path,
  title,
  type = "article",
}) => {
  return (
    <Head>
      <title>{title !== undefined ? `${title} | ${siteName}` : siteName}</title>

      {description !== undefined && (
        <meta content={description} name="description" key="description" />
      )}
      {noindex && <meta content="noindex" name="robots" key="robots" />}
      {description !== undefined && (
        <meta
          content={description}
          property="og:description"
          key="og:description"
        />
      )}
      {image !== undefined && (
        <>
          <meta
            content={withBaseUrl(image.url)}
            property="og:image"
            key="og:image"
          />
          {image.type !== undefined && (
            <meta
              content={image.type}
              property="og:image:type"
              key="og:image:type"
            />
          )}
          {image.width !== undefined && (
            <meta
              content={`${image.width}`}
              property="og:image:width"
              key="og:image:width"
            />
          )}
          {image.height !== undefined && (
            <meta
              content={`${image.height}`}
              property="og:image:height"
              key="og:image:height"
            />
          )}
        </>
      )}
      {title !== undefined && (
        <meta content={siteName} property="og:site_name" key="og:site_name" />
      )}
      <meta content={title ?? siteName} property="og:title" key="og:title" />
      <meta content={type} property="og:type" />
      {path !== undefined && (
        <meta content={withBaseUrl(path)} property="og:url" key="og:url" />
      )}
      <meta
        content="summary_large_image"
        name="twitter:card"
        key="twitter:card"
      />
      <meta
        content={title !== undefined ? `${title} | ${siteName}` : siteName}
        name="twitter:title"
        key="twitter:title"
      />

      {path && <link href={withBaseUrl(path)} rel="canonical" />}
    </Head>
  );
};
