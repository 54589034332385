import { useRouter } from "next/router";
import type { User } from "@firebase/auth";
import { useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { auth } from "../firebase/auth";
import { handleError } from "../helpers/handleError";
import { Me, meState } from "../recoil/meState";
import { assertNever } from "../utils/assertNever";
import { checkUsingPasswordSignIn } from "../helpers/checkUsingPasswordSignIn";
import { unwrap } from "../utils/unwrap";
import { pagesPath } from "../utils/$path";
import { useMeQuery } from "../generated/graphql";
import { signOut } from "firebase/auth";

type UseSessionOptions = {
  onLoaded?: () => void;
  onSignedIn?: (me: Me, firebaseUser: User) => void;
  onNotSignedIn?: () => void;
};

// firebase の認証情報を使って giff letter のバックエンドからユーザーを取得
export function useSession(
  options: UseSessionOptions = {},
): [null | Me, boolean] {
  // レンダリングする度に副作用が走るのを防ぐ
  // 本当は使う側でメモ化したいが、利便性のため
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const opts = useMemo(() => options, []);
  const router = useRouter();
  const [state, setStates] = useRecoilState<[null | Me, boolean]>(meState);

  const [{ data }, meQuery] = useMeQuery({
    requestPolicy: "network-only",
    pause: true,
  });

  useEffect(() => {
    auth.onAuthStateChanged(
      (user) => {
        if (user === null) {
          setStates([null, false]);
          opts.onLoaded?.();
          opts.onNotSignedIn?.();
          return;
        }

        const url = pagesPath.email_verification.$url();
        if (!user.emailVerified && router.asPath !== url.pathname) {
          void router.push(url);
          return;
        }

        meQuery();
      },
      (err) => {
        handleError(err);
      },
    );
  }, [setStates, opts, meQuery, router]);

  useEffect(() => {
    if (data === undefined) {
      return;
    }

    const { me } = data;

    setStates([me.user, false]);
    opts.onLoaded?.();

    switch (me.status) {
      case "OK": {
        const firebaseUser = unwrap(auth.currentUser);
        opts.onSignedIn?.(unwrap(me.user), firebaseUser);
        return;
      }
      case "TOKEN_INVALID": {
        opts.onNotSignedIn?.();
        return;
      }
      case "USER_NOT_FOUND": {
        if (auth.currentUser === null) {
          return;
        }

        // ありえない場面ではサインアウトさせておく
        type Url = { pathname: string };
        const signOutWithUrlCheck = (ignoreUrls: Url[]): void => {
          const ignorePaths = ignoreUrls.map((u) => u.pathname);
          if (!ignorePaths.includes(router.pathname)) {
            void signOut(auth);
          }
        };

        if (checkUsingPasswordSignIn(auth.currentUser)) {
          signOutWithUrlCheck([
            pagesPath.signup.email.$url(),
            pagesPath.signin.email.$url(),
            pagesPath.email_verification.$url(),
          ]);
        } else {
          signOutWithUrlCheck([
            pagesPath.signup.social.$url(),
            pagesPath.signup.$url(),
            pagesPath.signin.$url(),
          ]);
        }
        return;
      }
      default: {
        assertNever(me.status);
      }
    }
  }, [data, setStates, opts, router]);

  return state;
}
