import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import "firebase/compat/auth";

const firebaseApp = initializeApp({
  apiKey: process.env["NEXT_PUBLIC_FIREBASE_API_KEY"],
  authDomain: process.env["NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN"],
  projectId: process.env["NEXT_PUBLIC_FIREBASE_PROJECT_ID"],
  storageBucket: process.env["NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET"],
  messagingSenderId: process.env["NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID"],
  appId: process.env["NEXT_PUBLIC_FIREBASE_APP_ID"],
  measurementId: process.env["NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID"],
});

export const auth = getAuth(firebaseApp);
