// ログ収集は Sentry に任せて throw する
export function handleError(err: unknown): never {
  console.error(err);

  if (err instanceof Error) {
    throw err;
  }

  throw new Error(JSON.stringify(err));
}
