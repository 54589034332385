import { default as clsx } from "clsx";
import Link, { LinkProps } from "next/link";

type Props = {
  href: LinkProps["href"];
  disabled?: boolean;
  children: React.ReactNode;
};

export const PrettyLink: React.FC<Props> = ({
  href,
  disabled = false,
  children,
}) => {
  return (
    <Link
      href={href}
      className={clsx(
        "rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-light md:hover:opacity-80",
        disabled && "pointer-events-none opacity-50",
      )}
    >
      {children}
    </Link>
  );
};
