type Props = {
  size?: number;
  color?: "black" | "light-gray";
};

export const SearchIcon: React.FC<Props> = ({ size = 20, color = "black" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7039 13.2005C14.6548 11.2477 14.6542 8.08318 12.7022 6.13113C10.7496 4.17851 7.58375 4.17851 5.63113 6.13113C3.67851 8.08375 3.67851 11.2496 5.63113 13.2022C7.58375 15.1548 10.7496 15.1548 12.7022 13.2022C12.7028 13.2016 12.7033 13.2011 12.7039 13.2005ZM14.4335 13.755C16.4689 11.1396 16.2846 7.35656 13.8807 4.95262C11.2772 2.34913 7.05612 2.34913 4.45262 4.95262C1.84913 7.55612 1.84913 11.7772 4.45262 14.3807C6.85656 16.7846 10.6396 16.9689 13.255 14.9335L16.2377 17.9162C16.5632 18.2417 17.0908 18.2417 17.4162 17.9162C17.7417 17.5908 17.7417 17.0632 17.4162 16.7377L14.4335 13.755Z"
        fill={color === "light-gray" ? "#888686" : "#383635"}
      />
    </svg>
  );
};
