import { useEffect, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

type UseMediaQueriesHook = {
  isMd: boolean;
};

// TODO: コード全体で、コンポーネントに渡す width, height に対して isMd で場合分けを行う
export function useMediaQueries(): UseMediaQueriesHook {
  const [isMd, setIsMd] = useState(false);

  useEffect(() => {
    const screens = fullConfig.theme?.screens;

    if (screens === undefined || !("md" in screens)) {
      return;
    }

    const mqlMd = window.matchMedia(`(min-width: ${screens["md"]})`);

    const handle = (e: MediaQueryList | MediaQueryListEvent) => {
      setIsMd(e.matches);
    };
    handle(mqlMd);

    mqlMd.addEventListener("change", handle);

    return () => {
      mqlMd.removeEventListener("change", handle);
    };
  }, []);

  return {
    isMd,
  };
}
