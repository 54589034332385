import { PrettyLink } from "../PrettyLink";

type LinkListItem = {
  title: string;
  links: { href: string; label: string }[];
};

type Props = {
  items: readonly LinkListItem[];
};

export const LinkList: React.FC<Props> = ({ items }) => {
  return (
    <div>
      {items.map(({ title, links }) => (
        <div key={title} className="mt-[10px] first:mt-0">
          <h3 className="text-sm font-bold text-subtext">{title}</h3>
          <ul className="mt-1 -ml-3 flex flex-wrap">
            {links.map(({ href, label }) => (
              <li key={label} className="ml-3">
                <span className="text-sm text-subtext">
                  <PrettyLink href={href}>{label}</PrettyLink>
                </span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
