import { i18n } from "../../../i18n";
import { SearchIcon } from "../Input/SearchIcon";

export const SearchBox: React.FC = () => {
  return (
    <label className="relative block">
      <input
        className="h-[30px] w-[240px] rounded-lg bg-background pl-[32px] text-xs placeholder-placeholder focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-light"
        placeholder={i18n.whatKindOfGift}
      />
      <div className="absolute top-[7px] left-[12px]">
        <SearchIcon color="light-gray" size={16} />
      </div>
    </label>
  );
};
