import type { ReactNode } from "react";
import { BottomMenu } from "../BottomMenu";
import { HeaderMenu } from "../HeaderMenu";

type Props = {
  header?: ReactNode;
  bottom?: ReactNode;
  breadcrumbs?: ReactNode;
  children: React.ReactNode;
};

export const Layout: React.FC<Props> = ({
  header = <HeaderMenu />,
  bottom = <BottomMenu />,
  breadcrumbs = null,
  children,
}) => {
  return (
    <div className="flex min-h-screen flex-col">
      {header}
      {breadcrumbs}
      <main className="flex-grow">{children}</main>
      <div>{bottom}</div>
    </div>
  );
};
