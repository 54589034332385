type Props = {
  active: boolean;
};

export const LikeIcon: React.FC<Props> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19515 7.83219C4.81177 8.92076 5.02633 10.1979 5.54527 10.9123C5.89847 11.3985 6.6088 12.177 7.51585 13.1103C8.40883 14.0291 9.44531 15.0505 10.4191 16.01L10.4272 16.018C10.9854 16.568 11.5245 17.0993 12 17.5755C12.4755 17.0993 13.0147 16.568 13.5729 16.0179L13.5809 16.01C14.5547 15.0505 15.5912 14.0291 16.4842 13.1103C17.3912 12.177 18.1015 11.3985 18.4547 10.9123C18.9737 10.1979 19.1882 8.92076 18.8048 7.83219C18.6216 7.31176 18.3121 6.86656 17.8661 6.54863C17.4232 6.23283 16.7859 6 15.874 6C14.962 6 14.2483 6.32431 13.7467 6.72149C13.2054 7.15009 13.0023 7.58398 12.9818 7.69017C12.8907 8.16039 12.479 8.5 12 8.5C11.521 8.5 11.1093 8.16039 11.0182 7.69017C10.9977 7.58398 10.7946 7.15009 10.2533 6.72149C9.75172 6.32431 9.03799 6 8.12597 6C7.21414 6 6.57684 6.23283 6.13388 6.54863C5.68792 6.86656 5.37844 7.31176 5.19515 7.83219ZM4.97286 4.92012C5.80107 4.32967 6.85865 4 8.12597 4C9.53837 4 10.6809 4.50903 11.4949 5.15351C11.6752 5.29629 11.8445 5.45008 12 5.61119C12.1555 5.45008 12.3248 5.29629 12.5051 5.15351C13.3191 4.50903 14.4616 4 15.874 4C17.1413 4 18.1989 4.32967 19.0271 4.92012C19.8524 5.50844 20.3903 6.31324 20.6913 7.16781C21.2764 8.82924 21.0067 10.8021 20.0729 12.0877C19.6235 12.7064 18.8152 13.5814 17.9184 14.5042C17.0089 15.44 15.958 16.4755 14.9892 17.4302L14.9847 17.4346C14.1106 18.296 13.3077 19.0872 12.7183 19.6957C12.53 19.8902 12.2708 20 12 20C11.7292 20 11.47 19.8902 11.2817 19.6957C10.6923 19.0872 9.88944 18.296 9.01535 17.4346L9.01072 17.4301C8.04192 16.4754 6.99109 15.4399 6.08163 14.5042C5.18478 13.5814 4.37653 12.7064 3.92711 12.0877C2.99329 10.8021 2.7236 8.82924 3.30872 7.16781C3.60969 6.31324 4.14765 5.50844 4.97286 4.92012Z"
        fill={active ? "#D0576B" : "#BAB7B7"}
      />
    </svg>
  );
};
