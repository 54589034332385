type Props = {
  active: boolean;
};

export const LetterIcon: React.FC<Props> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4253 7H6.17274L11.9805 11.2469L17.4253 7ZM4 7.88886V17H20V7.52821L13.2106 12.8239C12.5057 13.3737 11.5216 13.389 10.8 12.8614L4 7.88886ZM2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V7Z"
        fill={active ? "#D0576B" : "#BAB7B7"}
      />
    </svg>
  );
};
