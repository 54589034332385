import { default as clsx } from "clsx";
import { i18n } from "../../../i18n";

type Props = {
  active: boolean;
  url: string;
};

export const AvatarIcon: React.FC<Props> = ({ active, url }) => {
  return (
    <img
      src={url}
      className={clsx("h-6 w-6 rounded-full", active && "ring-2 ring-brand")}
      alt={i18n.avatarImage}
      width={24}
      height={24}
      decoding="async"
      loading="lazy"
    />
  );
};
