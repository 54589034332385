import { auth } from "../firebase/auth";
import { unwrap } from "../utils/unwrap";
import { createClient, dedupExchange, errorExchange } from "urql";
import { authExchange } from "@urql/exchange-auth";
import { makeOperation } from "@urql/core";
import { cacheExchange } from "@urql/exchange-graphcache";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { handleError } from "../helpers/handleError";

const endpointUrl = unwrap(process.env["NEXT_PUBLIC_ENDPOINT_URL"]);

export const client = createClient({
  url: endpointUrl,
  requestPolicy: "network-only", // NOTE: パフォーマンスに影響がありそうだったら変更する
  fetchOptions: {
    headers: {
      // CSRF 防止問題を回避するため
      "x-apollo-operation-name": "true",
    },
  },
  exchanges: [
    dedupExchange,
    cacheExchange({
      keys: {
        MeResponse: () => null,
        Date: () => null,
      },
      resolvers: {
        User: {
          likes: relayPagination(),
          wishes: relayPagination(),
          followees: relayPagination(),
          followers: relayPagination(),
        },
        Brand: {
          items: relayPagination(),
        },
        AnniversaryRepetition: {
          recommendedItems: relayPagination(),
        },
        Query: {
          notifications: relayPagination(),
          items: relayPagination(),
          brands: relayPagination(),
          receivedFollowRequests: relayPagination(),
          savedGiftSkus: relayPagination(),
          savedGifts: relayPagination(),
          purchasedGifts: relayPagination(),
          searchUsers: relayPagination(),
          blockingUsers: relayPagination(),
        },
      },
    }),
    authExchange({
      willAuthError: ({ authState }) => {
        return typeof authState !== "string";
      },
      getAuth: async ({ authState }) => {
        if (typeof authState === "string") {
          return authState;
        }

        const formatToken = (token: string): string => {
          return `Bearer ${token}`;
        };

        if (auth.currentUser !== null) {
          return auth.currentUser.getIdToken().then(formatToken);
        }

        return new Promise((resolve, reject) => {
          auth.onAuthStateChanged((user) => {
            if (user === null) {
              resolve(null);
              return;
            }

            user.getIdToken().then(formatToken).then(resolve).catch(reject);
          });
        });
      },
      addAuthToOperation: ({ authState, operation }) => {
        if (typeof authState !== "string") {
          return operation;
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === "function"
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: authState,
            },
          },
        });
      },
    }),
    multipartFetchExchange,
    errorExchange({
      onError: (err) => {
        handleError(err);
      },
    }),
  ],
});
