type Props = {
  active: boolean;
};

export const CalendarIcon: React.FC<Props> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5H15C15 4.44772 15.4477 4 16 4C16.5523 4 17 4.44772 17 5H19C20.1046 5 21 5.89543 21 7V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V7C3 5.89543 3.89543 5 5 5H7ZM15 7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7H19V9H5V7H7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7H15ZM5 11H19V19H5L5 11ZM7 13.5C7 12.9477 7.44772 12.5 8 12.5H16C16.5523 12.5 17 12.9477 17 13.5C17 14.0523 16.5523 14.5 16 14.5H8C7.44772 14.5 7 14.0523 7 13.5ZM7 16.5C7 15.9477 7.44772 15.5 8 15.5H11C11.5523 15.5 12 15.9477 12 16.5C12 17.0523 11.5523 17.5 11 17.5H8C7.44772 17.5 7 17.0523 7 16.5Z"
        fill={active ? "#D0576B" : "#BAB7B7"}
      />
    </svg>
  );
};
