import "tailwindcss/tailwind.css";
import type { AppProps } from "next/app";
import { client } from "../graphql/client";
import { RecoilRoot } from "recoil";
import { Progressbar } from "../components/ui/Progressbar";
import { Seo } from "../components/utils/Seo";
import { staticPath } from "../utils/$path";
import { Provider } from "urql";
import { i18n } from "../i18n";
import { OnlyWhitelistPages } from "../components/utils/OnlyWhitelistPages";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Script from "next/script";
import { GA_TRACKING_ID, pageview } from "../helpers/gtag";

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouterChange = (url: string) => {
      pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouterChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouterChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${GA_TRACKING_ID}');
              `,
        }}
      />

      <Seo
        image={{
          url: staticPath.assets.ogp_png,
          width: 1200,
          height: 630,
        }}
        description={i18n.ogDescription}
      />

      <Progressbar />
      <Provider value={client}>
        <RecoilRoot>
          <OnlyWhitelistPages>
            <Component {...pageProps} />
          </OnlyWhitelistPages>
        </RecoilRoot>
      </Provider>

      <style global jsx>{`
        html {
          font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue",
            "Yu Gothic", "YuGothic", Verdana, Meiryo, sans-serif;
          color: #383635;
          background-color: #f7f6f3;
        }
      `}</style>
    </>
  );
};

export default App;
