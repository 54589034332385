import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { LeftIcon } from "../../icons/LeftIcon";

type Props = {
  color?: "default" | "white";
};

export const BackButton: React.FC<Props> = ({ color = "default" }) => {
  const router = useRouter();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(typeof history !== "undefined" && history.length > 1);
  }, []);

  if (!isShow) {
    return null;
  }

  const handleClick = () => {
    router.back();
  };

  return (
    <button
      type="button"
      className="rounded focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-light"
      onClick={handleClick}
    >
      <LeftIcon color={color} />
    </button>
  );
};
