import { match } from "ts-pattern";

type Props = {
  size?: number;
  color?: "default" | "white" | "gray";
};

export const CartIcon: React.FC<Props> = ({ size = 24, color = "default" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.5C0 3.94772 0.447715 3.5 1 3.5H4.31565H5.19905H21.6844C22.3457 3.5 22.8249 4.13045 22.6479 4.76764L19.8701 14.7676C19.7499 15.2005 19.3558 15.5 18.9066 15.5H7.09342C6.64422 15.5 6.25013 15.2005 6.12991 14.7676L3.55556 5.5H1C0.447715 5.5 0 5.05228 0 4.5ZM5.63129 5.5L7.85351 13.5H18.1465L20.3687 5.5H5.63129ZM17.5 22C18.8807 22 20 20.8807 20 19.5C20 18.1193 18.8807 17 17.5 17C16.1193 17 15 18.1193 15 19.5C15 20.8807 16.1193 22 17.5 22ZM11 19.5C11 20.8807 9.88071 22 8.5 22C7.11929 22 6 20.8807 6 19.5C6 18.1193 7.11929 17 8.5 17C9.88071 17 11 18.1193 11 19.5ZM10 8.5C9.44771 8.5 9 8.94772 9 9.5C9 10.0523 9.44771 10.5 10 10.5H16C16.5523 10.5 17 10.0523 17 9.5C17 8.94772 16.5523 8.5 16 8.5H10Z"
        fill={match(color)
          .with("default", () => "#383635")
          .with("white", () => "#ffffff")
          .with("gray", () => "#bab7b7")
          .exhaustive()}
      />
    </svg>
  );
};
