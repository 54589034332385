export const GA_TRACKING_ID =
  process.env["NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID"];

export function pageview(url: string) {
  if (GA_TRACKING_ID === undefined) {
    return;
  }

  gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
}
