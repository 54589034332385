import { match } from "ts-pattern";

type Props = {
  color?: "default" | "white";
};

export const LeftIcon: React.FC<Props> = ({ color = "default" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2218 5.63606C15.6123 5.24554 15.6123 4.61237 15.2218 4.22185C14.8313 3.83132 14.1981 3.83132 13.8076 4.22185L6.74397 11.2855C6.74148 11.2879 6.739 11.2904 6.73653 11.2928C6.54125 11.4881 6.44362 11.7441 6.44364 12C6.44364 12.0357 6.44554 12.0715 6.44935 12.107C6.4728 12.3262 6.56853 12.5391 6.73653 12.7071C6.73898 12.7096 6.74144 12.712 6.7439 12.7144L13.8076 19.7781C14.1981 20.1686 14.8313 20.1686 15.2218 19.7781C15.6123 19.3876 15.6123 18.7544 15.2218 18.3639L8.85789 12L15.2218 5.63606Z"
        fill={match(color)
          .with("default", () => "#222222")
          .with("white", () => "#fff")
          .exhaustive()}
      />
    </svg>
  );
};
