type Props = {
  active: boolean;
};

export const HomeIcon: React.FC<Props> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 18.6676V9.8L12 5L4 9.8V18.6676H20ZM2.97101 8.08501C2.3686 8.44646 2 9.09747 2 9.8V18.6676C2 19.7722 2.89543 20.6676 4 20.6676H20C21.1046 20.6676 22 19.7722 22 18.6676V9.8C22 9.09747 21.6314 8.44646 21.029 8.08501L13.029 3.28501C12.3956 2.905 11.6044 2.905 10.971 3.28501L2.97101 8.08501Z"
        fill={active ? "#D0576B" : "#BAB7B7"}
      />
    </svg>
  );
};
