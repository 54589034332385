export const ja = {
  aboutAccount: "アカウントについて",
  aboutFeaturesAndBugs: "機能のご意見や不具合について",
  aboutItem: "商品について",
  aboutOrder: "注文について",
  aboutService: "サービスについて",
  access: "アクセス",
  accountDeactivationCompleted: "退会が完了しました",
  accountDeletionCompletedMessage:
    "再度サービスをご利用になる場合は、\nまたアカウント登録手続きにお進みください。",
  accountExistsWithDifferentCredential:
    "同じメールアドレスで登録されているアカウントがあります。別の認証方法をお試しください。",
  accountPublishing: "アカウントの公開",
  accountPublishingSetting: "アカウントの公開設定",
  accountPublishingSettingDescription:
    "アカウント情報をフォロワーのみに表示します。この設定をオンにすると、今後は新しいフォロワーを1人ずつ許可する必要があります。",
  add: "追加",
  addAnniversary: "記念日をメモする",
  addAnniversaryUser: "お相手を新規追加",
  addConsignee: "お届け先情報の追加",
  addDeliveryAddress: "お届け先を追加",
  addedItemToCart: "ギフトをカートに追加しました！",
  addedItemToCartWithWrapping: "ギフトとラッピングをカートに追加しました！",
  addNewCreditCard: "新しいクレジットカードを登録",
  addOrDeletePaymentMethods: "決済方法の追加・削除",
  addOrEditConsignees: "お届け先の追加・編集",
  addPaymentMethod: "決済方法を追加",
  address: "住所",
  addressOfCustomer: "購入者情報の住所",
  addToCart: "カートに追加",
  addToLikedItems: "お気に入り",
  addToWishedItems: "ほしいもの",
  afterThisAnniversary: "これ以降のすべての記念日",
  all: "すべて",
  allergy: "アレルギー",
  allergyInfo: "アレルギー情報",
  allergyQueryLabel: "アレルギー（該当する商品を除外）",
  allX: (x: string) => `すべての${x}`,
  alreadySaved: "保存済みです",
  amex: "アメリカン・エキスプレス",
  androidApp: "Androidアプリ",
  anniversariesEmptyMessage:
    "大事な人の誕生日や記念日を記録して特別な時を祝いませんか？",
  anniversary: "記念日",
  anniversaryAdding: "記念日追加",
  anniversaryEditing: "記念日編集",
  anniversaryMemo: "記念日メモ",
  anniversaryNamePlaceholder: "記念日を入力",
  anniversaryPublication: "記念日の公開",
  anniversaryPublishingMessageForOthers:
    "自分以外の記念日は\nマイページには公開されません",
  anniversaryUser: "お相手",
  anniversaryUserName: "お相手の名前",
  anniversaryUserRelation: "お相手との関係",
  anniversaryUserRelationSection1: "恋人・友達・知人",
  anniversaryUserRelationSection2: "家族・親戚",
  anniversaryUserRelationSection3: "お仕事",
  anonymous: "匿名",
  aOfB: (a: string, b: string) => `${b}の${a}`,
  applied: "適用済",
  apply: "適用",
  approve: "許可",
  authenticationEmail: "認証用メールを送信しました",
  area: "エリア",
  areaList: "エリア一覧",
  attention: "注意事項",
  availableCreditCards: "以下のクレジットカードがご利用いただけます。",
  avatarImage: "アバター画像",
  back: "前へ戻る",
  backToGiftList: "ギフト一覧に戻る",
  backToHome: "ホームに戻る",
  backToSignIn: "ログインに戻る",
  backToTop: "トップに戻る",
  beforeOneDay: "前日",
  beforeOneWeek: "1週間前",
  beforeOneWeekAndBeforeOneDay: "1週間前/前日",
  biography: "プロフィール",
  biographyPlaceholder: "自分の好みやほしいものなどについて書いてみよう",
  birthCelebration: "出産祝い",
  birthday: "誕生日",
  blocked: "ブロックしました",
  blockedUsers: "ブロックした相手",
  blockedUsersEmpty: "ブロックしているユーザーはいません。",
  blockingMe: "このアカウントからブロックされています",
  blockingMeMessage:
    "このユーザーのコンテンツを確認するには\nブロックを解除される必要があります。",
  blockingYou: "このアカウントをブロックしています",
  blockingYouMessage:
    "ブロックを解除すると、このユーザーの\nコンテンツを確認できます。",
  blockThisUser: "このユーザーをブロック",
  boyfriend: "彼氏",
  brand: "ブランド",
  brandDescription: "ブランド説明",
  brandItems: "このブランドのギフト",
  brandItemsNotFound: "現在商品をお取り扱いしておりません。",
  brandList: "ブランド一覧",
  brandName: "ブランド名",
  brandsNotFound: "条件に一致するブランドはありません",
  buildingLabel: "マンション名/部屋番号",
  buildingPlaceholder: "ABCマンション 101",
  businessHours: "営業時間",
  cancel: "キャンセル",
  canSaveLetter: "大切なレターを保存できる",
  cardCvc: "CVC",
  cardCvcPlaceholder: "123",
  cardDesign: "カードデザイン",
  cardExpiry: "有効期限",
  cardNumber: "カード番号",
  cardNumberPlaceholder: "1111222233334444",
  cart: "カート",
  cartItems: "カート内のギフト",
  cartItemsNotFound: "カート内にギフトはまだありません",
  category: "カテゴリー",
  categoryList: "カテゴリー一覧",
  change: "変更",
  changeAll: "一括変更",
  changeEmail: "メールアドレスを変更",
  changePassword: "パスワードを変更",
  checkBelowContentBeforeDeactivate:
    "退会に進む前に以下の項目を確認して、手続きにお進みください。",
  checked: "確認しました",
  chichiNoHi: "父の日",
  christmas: "クリスマス",
  city: "市区町村/番地",
  cityPlaceholder: "渋谷区明原1-10-5 ",
  clear: "クリア",
  clearCondition: "条件をクリア",
  close: "閉じる",
  closeGiffLetter: "ギフレターを閉じる",
  commentLabel: "コメント",
  company: "運営会社",
  companyInformation: "会社情報",
  confirmDelivery: "配送の確定",
  confirmedAboveMatters: "上記の事項を確認しました。",
  confirmOrder: "注文を確定",
  consignee: "お届け先情報",
  consigneeEmpty: "登録済みの住所がありません",
  consigneeInputed: "お届け先を入力済み",
  consigneeNotRegistered: "お届け先情報が登録されていません。",
  consignees: "お届け先情報",
  copied: "コピーしました！",
  copiedLink: "リンクがクリップボードにコピーされました！",
  copyByTap: "タップでコピー",
  copyUrl: "URLをコピー",
  createLetter: "レターの作成",
  createNew: "新規登録",
  creditCard: "クレジットカード",
  creditCardDescription:
    "Visa、Mastercard、American Express、JCB、Diners Clubに対応しています。",
  creditCardList: "支払い方法一覧",
  creditCardEmpty: "決済情報が登録されていません。",
  currentEmail: "現在のメールアドレス",
  currentPassword: "現在のパスワード",
  currentPasswordPlaceholder: "現在のパスワードを入力",
  customer: "購入者情報",
  date: "日付",
  daughter: "娘",
  dayAfterTomorrow: "明後日",
  deactivate: "退会",
  deactivateAccount: "退会の手続き",
  deactivationConfirmation:
    "退会が行われると、ほしいものリストや記念日メモの内容も削除されます。",
  deactivationConfirmation2: "退会してよろしいですか？",
  deactivationNote1: "このアカウントでログインが不可能になります",
  deactivationNote2:
    "お気に入り、ほしいもの、記念日メモ、購入履歴などの情報が削除されます",
  deactivationReason: "退会理由",
  deactivationReason1: "サービスに満足できなかった",
  deactivationReason2: "商品に満足できなかった",
  deactivationReason3: "二重にアカウント登録してしまった",
  deactivationReasonOther: "その他",
  decide: "決定",
  delete: "削除",
  deleteAccount: "アカウントを削除",
  deleteAfterThisAnniversaries: "これ以降すべての記念日を削除",
  deleteAllAnniversaries: "すべての記念日を削除",
  deleteAnniversary: "記念日を削除",
  deleteAnniversaryUser: "お相手を削除",
  deleteAnniversaryUserMessage:
    "あなたが作成した江口美宇さんの記\n念日がすべて削除されます。",
  deleteAnniversaryUserMessageConfirmation: "お相手を削除しますか？",
  deleteConfirmation: "本当に削除しますか？",
  deleteCardConfirmation: "このカードを削除しますか？",
  deleteOnlyThisAnniversary: "この記念日を削除",
  delivery: "配送",
  deliveryDate: "希望日",
  deliveryDate2: "お届け日",
  deliveryDateTime: "お届け日時",
  deliveryDateTimeAttention:
    "※離島・一部地域ではご希望の日時にお届けできない場合がございます。",
  deliveryMethod: "お届け方法",
  deliveryName: "配送名",
  deliveryTime: "希望時間帯",
  deliveryToThisAddress: "この住所に届ける",
  deliveryWrapping: "配送ラッピング",
  deliveryWrappingDescription:
    "以下の商品をダンボールに入れて配送します。ダンボールの装飾オプションを選択できます。",
  dinersClub: "ダイナースクラブ",
  displayName: "お名前",
  done: "完了",
  dontHaveAccount: "アカウントをお持ちでない方はこちら",
  download: "ダウンロード",
  downloadApp: "アプリをダウンロード",
  downloadGiffLetterApp: "giff letterアプリを\nダウンロード",
  dropdownMenu: "ドロップダウンメニュー",
  edit: "編集",
  editAnniversary: "記念日を編集",
  editAnniversaryUser: "お相手を編集",
  editConsignee: "お届け先情報の編集",
  editDeliveryAddress: "お届け先の編集",
  eGift: "eギフト",
  eGiftAttention:
    "入力した電話番号や住所などのお届先情報は、ギフトの贈り主に知られることはありません。",
  eGiftDescription:
    "お相手の住所を知らなくても、メールやSNSで簡単にギフトを贈ることができます。お相手が指定した受け取り住所へギフトを直接お送りします。",
  email: "メールアドレス",
  emailAlreadyInUse: "このメールアドレスはすでに使われています",
  emailChanging: "メールアドレスの変更",
  emailConfirmation: "メールアドレス（確認）",
  emailConfirmationDoesNotMatch: "メールアドレスが一致しません",
  emailNote:
    "※携帯電話のメールアドレスをご利用の場合は、「@pamxy.co.jp」からのメールを受信できるようにご設定ください",
  emailNotification: "メール通知",
  emailPlaceholder: "gift@example.com",
  emailVerification: "アカウント認証",
  emailVerificationDescription:
    "宛にアカウント認証メールをお送りしました。メールに記載されたリンクをクリックして認証を完了してください。",
  emailVerificationDescription02:
    "宛に認証用メールをお送りしました。\nメールアドレスを認証してください。",
  emailVerificationSent: "アカウント認証メールを送信しました。",
  engagement: "婚約記念日",
  error: "エラー",
  errorDescription: "データを取得できません。\nリトライしてください。",
  etc: "その他",
  expireAt: "有効期限",
  expired: "期限切れ",
  facebook: "Facebook",
  faq: "よくある質問",
  father: "父親",
  fatherInLaw: "義父",
  femaleBaby: "女の子の\n赤ちゃん",
  femaleClient: "取引先\n女性",
  femaleCoworker: "同僚女性",
  femaleFriend: "女友達",
  femaleJunior: "部下女性",
  femaleSenior: "上司女性",
  findGift: "ギフトを探す",
  follow: "フォロー",
  followees: "フォロー",
  followeesEmpty: "ユーザーをフォローすると\nここに表示されます",
  followeesOf: (name: string) => `${name} のフォロー`,
  followers: "フォロワー",
  followersEmpty: "ユーザーにフォローされると\nここに表示されます",
  followersOf: (name: string) => `${name} のフォロワー`,
  following: "フォロー中",
  followingMe: "フォローされています",
  followRequests: "フォローリクエスト",
  followRequestsEmpty: "フォローリクエストがあると\nここに表示されます",
  followsAndFollowers: "フォロー/フォロワー",
  food: "食品",
  forDeliveriesTo: "へのお届けの場合",
  frequency: "繰り返し",
  from: (name: string) => `${name}より`,
  giffLetter: "ギフレター",
  giffLetterFrom: (name: string) => `${name}からのギフレター`,
  giffLetterOfficialApp: "giff letter公式アプリ",
  giffLetterSecretariat: "ギフレター事務局",
  gift: "ギフト",
  gifteeName: "お相手の名前",
  gifterName: "自分の名前",
  giftFor: "贈り先",
  giftForMe: "自分に贈る",
  giftForOutsider: "相手に贈る",
  giftHasArrivedForYou: "あなたへギフトが届いています",
  giftImage: "ギフトの画像",
  giftOnOnline: "オンラインで贈る",
  giftSecret: "ギフトの公開設定",
  giftSecretDescription: "ギフレター上でのギフト情報の公開/非公開を選べます。",
  giftSkusEmpty:
    "まだギフトは届いていません。\nまずは自分から大切な人へ\nギフトを贈りましょう！",
  girlfriend: "彼女",
  goldenWedding: "金婚式",
  goToGiftDeliveryConfirmation: "ギフト配送の確認へ",
  goToOrder: "注文に進む",
  goToOrderConfirmation: "注文内容の確認へ",
  goToTopPage: "トップページに戻る",
  grandfather: "祖父",
  grandmother: "祖母",
  hahaNoHi: "母の日",
  home: "ホーム",
  husband: "夫",
  ifYouAlreadyHaveAnAccount: "すでにアカウントをお持ちの方",
  ifYouDontHaveAnAccountYet: "まだアカウントをお持ちでない方",
  ifYouForgotPassword: "パスワードを忘れた場合",
  iiFufuNoHi: "いい夫婦の日",
  imageEmptyMessage: "画像が選択されていません",
  inputAddressManually: "住所を入力する",
  inputConsignee: "お届け先を入力してください",
  inputConsignee2: "お届け先を入力",
  inputConsigneeAndReceive: "お届け先を入力して受け取る",
  inputDelivery: "配送入力",
  inputMemo: "メモを入力する",
  inputNewAddress: "新規で別の住所に送る",
  inputOrder: "注文入力",
  inputPinCode: "お店の方から伝えられた\n暗証番号を入力してください",
  inputReason: "理由を入力してください",
  inputUserId: "ユーザーIDを入力",
  inquiry: "お問い合わせ",
  inquiryContent: "お問い合わせ内容",
  inquiryContentPlaceholder: "お問い合わせ内容を入力",
  inquiryNoteBeforeSubmit: "にご同意の上、お問い合わせください",
  inquiryNoteFirstHalf: "お問い合わせの前に",
  inquiryNoteSecondHalf: "をご確認ください。",
  inquiryType: "お問い合わせの種類",
  instagram: "Instagram",
  invalidDate: "日付の形式が正しくありません",
  invalidEmail: "メールアドレスの形式が正しくありません",
  invalidEmailOrPassword: "メールアドレスまたはパスワードが不適切です",
  invalidPinCode: "暗証番号が間違っています",
  invalidUrl: "URL が無効です",
  iPhoneApp: "iPhoneアプリ",
  item: "ギフト",
  itemDescription: "商品説明",
  itemList: "ギフト一覧",
  itemName: "商品名",
  itemsInformation: "ギフト情報",
  itemsNotFound: "条件に一致するギフトはありません",
  jcb: "JCB",
  joiner: ", ",
  keiroNoHi: "敬老の日",
  keyword: "キーワード",
  lastSignIn: "前回のログイン",
  letsFindGift: (name: string) =>
    `${name}さんに\nぴったりなギフトを探しましょう！`,
  letter: "レター",
  lettersEmpty:
    "まだレターは届いていません。\nまずは自分から大切な人へ\nレターを贈りましょう！",
  like: "お気に入り",
  likesEmpty: "ギフトをお気に入りに追加すると\nここに表示されます",
  linePay: "LINE Pay",
  mainImage: "メイン画像",
  maleBaby: "男の子の\n赤ちゃん",
  maleClient: "取引先\n男性",
  maleCoworker: "同僚男性",
  maleFriend: "男友達",
  maleJunior: "部下男性",
  maleSenior: "上司男性",
  mastercard: "Mastercard",
  memo: "メモ",
  menu: "メニュー",
  merpay: "メルペイ",
  message: "メッセージ",
  minPricePlaceholder: "¥0",
  monthDay: (month: number, day: number) => `${month}/${day}`,
  monthly: "毎月",
  mother: "母親",
  motherInLaw: "義母",
  moveToTop: "↑トップへ移動",
  municipality: "駅",
  mustBeSizeGte: (min: number) => `${min} 以上の値で入力してください`,
  mustBeSizeInRange: (min: number, max: number) =>
    `${min} 以上 ${max} 以下の値で入力してください`,
  mustBeSizeLte: (max: number) => `${max} 以下の値で入力してください`,
  myPage: "マイページ",
  myPageEmpty: "ギフトをほしいものに追加すると、\nここに表示されます。",
  name: "お名前",
  name2: "氏名",
  nameKana: "フリガナ",
  nameKanaLabel: "フリガナ(全角カタカナ)",
  nameKanaPlaceholder: "タナカタロウ",
  nameOfCustomer: "購入者情報の名前",
  namePlaceholder: "田中太郎",
  narrowDown: "絞り込む",
  narrowDowning: "絞り込み",
  nDaysAgo: (n: number) => `${n} 日前`,
  nephew: "甥",
  newEmail: "新しいメールアドレス",
  newEmailConfirmation: "新しいメールアドレス（確認）",
  newPassword: "新しいパスワード",
  newPasswordConfirmation: "新しいパスワード（確認）",
  newPasswordConfirmationDoesNotMatch: "パスワードが一致しません",
  newPasswordConfirmationPlaceholder: "新しいパスワード（確認）を入力",
  newPasswordPlaceholder: "新しいパスワードを入力",
  next: "次へ",
  nHoursAgo: (n: number) => `${n} 時間前`,
  niece: "姪",
  nItems: (n: number) => `${n}件`,
  nItemsPerM: (n: number, m: number) => `${n}/${m}件`,
  nMinutesAgo: (n: number) => `${n} 分前`,
  nMonth: (n: number) => `${n}月`,
  noLimit: "上限なし",
  none: "なし",
  noSpecification: "希望なし",
  notChange: "変更しない",
  notificationFromOperation: "運営からのお知らせ",
  notificationOfAnniversaryReminder: "記念日が近くなったとき",
  notificationOfFollowRequestApproved: "フォローリクエストが承認された時",
  notificationOfGiftReminder: "期限付きギフトのリマインド",
  notificationOfNewFollower: "フォローされた時",
  notificationOfNewsletter: "新着アイテムやお得な情報",
  notificationOfRestock: "再入荷通知",
  notifications: "通知",
  notificationSetting: "通知の設定",
  notificationsNotFound: "通知はまだありません",
  noTimeSpecification: "時間指定なし",
  noOptions: "追加なし",
  nSecondsAgo: (n: number) => `${n} 秒前`,
  numberMustBeAtLeast: (min: number) => `${min} 以上の値で入力してください`,
  numberMustBeAtMost: (max: number) => `${max} 以下の値で入力してください`,
  numberMustBeInRange: (min: number, max: number) =>
    `${min} 以上 ${max} 以下の値で入力してください`,
  nYear: (n: number) => `${n}年`,
  ogDescription:
    "giff letterは厳選ギフトのオンラインショップです。「シーン」や「お相手との関係」から人気ギフトをご紹介します。お相手の住所が分からなくても、LINEやInstagram等のSNS・メールなどで贈れるオンラインギフトにも対応しています。",
  ogDescriptionGift: "レターを開いて、ギフトを受け取りましょう！",
  olderBrother: "兄",
  olderSister: "姉",
  online: "オンライン",
  onlineGift: "オンラインギフト",
  onlineOnlyAttention:
    "※配送が不要なオンラインギフトと配送が必要なギフトを同時に購入する場合、お届け方法はオンラインのみとなります。",
  onlyThisAnniversary: "この記念日",
  option: "任意",
  options: "オプション",
  optionsAmount: "オプション合計",
  order: "注文する",
  orderComplete: "注文完了",
  orderCompleted: "注文が完了しました",
  orderCompletedText:
    "以下のリンクをコピーして、お好きなタイミングでお相手にギフレターを贈りましょう。",
  orderConfirmation: "注文確認",
  orderedGift: "注文したギフト",
  orderHistory: "注文履歴",
  orderId: "注文ID",
  orderInfo: "注文情報",
  others: "その他",
  otherInquiries: "その他のお問い合わせ",
  outOfStock: "在庫切れ",
  outOfStockItems: "品切れ中のギフト",
  ownName: "自分名義",
  pageNotFound: "お探しのページは見つかりませんでした",
  pageNotFoundDescription:
    "お探しのページは削除、変更されたか、現在利用できない可能性があります。お手数ですがトップページまたは下記のリンクよりお探しください。",
  paidy: "後払いペイディ",
  paidyDescription:
    "事前登録やクレジットカードは不要の、オンラインショップ向け翌月払い決済、ペイディ（Paidy）に対応しています。",
  partner: "パートナー",
  password: "パスワード",
  passwordChanging: "パスワードの変更",
  passwordPlaceholder: "パスワードを入力",
  passwordReset: "パスワード再設定",
  passwordResetDescription:
    "アカウント登録時に設定したメールアドレスを入力してください。パスワード再設定用メールをお送りします。",
  passwordResetEmailSent: "パスワード再設定メールを送信しました",
  passwordResetEmailSentDescription:
    "宛にパスワード再設定用メールをお送りしました。内容にしたがってお手続きください。",
  passwordWrong: "パスワードが間違っています",
  paymentMethod: "決済方法",
  paypay: "PayPay",
  pet: "ペット",
  phoneNumber: "電話番号",
  phoneNumberLabel: "電話番号(ハイフンなし)",
  phoneNumberPlaceholder: "090XXXXYYYY",
  postalCode: "郵便番号",
  postalCodeLabel: "郵便番号(ハイフンなしの数字7桁)",
  postalCodePlaceholder: "1500000",
  prefecture: "都道府県",
  previewGiffletter: "ギフレターのプレビュー",
  price: "価格",
  privacy: "プライバシー",
  privacyPolicy: "プライバシーポリシー",
  private: "非公開",
  privateCelebration: "内祝い",
  profileEdit: "プロフィールを編集",
  promotionCode: "プロモーションコード",
  promotionCodePlaceholder: "コードを入力",
  public: "公開",
  purchases: "購入履歴",
  pushNotification: "プッシュ通知",
  rankingList: "ランキング一覧",
  reason: "理由",
  receiveByDelivery: "配送で受け取り",
  receivedGifts: "受け取ったギフト",
  receivedLetters: "受け取ったレター",
  recommendedItemsFor: (name: string) => `${name}におすすめなギフト`,
  registerAccount: "アカウント登録",
  reject: "削除",
  relatives: "親戚",
  remainingDays: (days: number) => `残り${days}日`,
  remindTimings: "お知らせ",
  report: "報告",
  reported: "報告しました",
  reportThisUser: "このユーザーを報告",
  requested: "リクエスト済",
  required: "必須",
  requiredMessage: "この項目は必須です",
  requiredAnniversaryMessage: "記念日を選択してください",
  requiredPartnerMessage: "お相手を選択してください",
  resendEmailVerification: "アカウント認証メールの再送信",
  resetSelections: "選択をリセット",
  restockNotification: "再入荷通知",
  retry: "リトライ",
  sameAddressAsCustomer: "購入者情報と同じ住所",
  save: "保存",
  saveChanges: "変更を保存",
  saveConsignee: "入力したお届け先情報を保存",
  saveCustomer: "入力した購入者情報を保存",
  saved: "保存しました",
  saveGiftAndLetter: "ギフトとレターを保存する",
  scene: "シーン",
  scrollToThisMonth: "今月へ移動",
  searchByBrandName: "ブランド名で探す",
  searchByList: "一覧から探す",
  searchItemForGift: "贈りたいギフトをさがす",
  secret: "非公開",
  secretGift: "秘密のギフト",
  secretGiftNote: "ギフトが届くまでのお楽しみ",
  seeAll: "すべて見る",
  select: "選択してください",
  selectAll: "すべて選択",
  selectAtLeastOne: "1 つ以上選択してください",
  selectConsignee: (num: number) => `保存済みの住所から選択（${num}件）`,
  selectShippingMethod: "贈る方法を選択",
  selectSkuWrapping: "ギフトラッピングを選択",
  selectWrappingAnd: "ラッピングを選択して",
  send: "送信",
  sendPasswordResetMail: "再設定用メールを送信",
  sentEmailVerification: "アカウント認証メールを送信しました",
  sentInquiry: "お問い合わせを送信しました",
  shipper: "発送元",
  shippers: "発送元",
  shippersEmpty: "発送元が登録されていません",
  shippingCost: "送料",
  shippingCostsAmount: "送料合計",
  shipToMe: "自分の住所に贈る",
  shipToMeDescription:
    "購入したギフトをご自身の住所に直接お送りします。ギフトは値段がわからない状態でのお届けとなり、納品書なども同梱されることはありません。",
  shipToOutsider: "お相手の住所に贈る",
  shipToOutsiderDescription:
    "購入したギフトを相手の住所に直接お送りします。ギフトは値段がわからない状態でのお届けとなり、納品書なども同梱されることはありません。",
  showThisScreenToClerk:
    "この画面をお店の方に見せてから\nギフトを利用してください",
  showYear: "西暦を表示",
  signIn: "ログイン",
  signInOrSignUp: "ログイン/新規登録",
  signInWithApple: "Appleでログイン",
  signInWithEmail: "メールアドレスでログイン",
  signInWithGoogle: "Googleでログイン",
  signOut: "ログアウト",
  signOutConfirmation: "ログアウトしますか？",
  signUp: "アカウント登録",
  signUpWithApple: "Appleで登録",
  signUpWithEmail: "メールアドレスで登録",
  signUpWithGoogle: "Googleで登録",
  silverWedding: "銀婚式",
  skuWrapping: "ギフトラッピング",
  slip: "伝票",
  slipNameSetting: "伝票の名義設定",
  slipNameSettingDescription:
    "匿名を選択の場合、お客様の住所や氏名などの個人情報がお相手様を含めた第三者に公開されることはございません。",
  smartphonePayment: "スマホ決済",
  smartphonePaymentDescription: "PayPay、LINE Pay、メルペイに対応しています。",
  son: "息子",
  sortByNew: "新着順",
  sortByPopularity: "人気順",
  sortByPriceAsc: "価格の安い順",
  sortByPriceDesc: "価格の高い順",
  sorting: "並び替え",
  spot: "スポット",
  spotCountMustBeAtMost: (count: number) => `最大${count}個まで選択できます。`,
  store: "店舗",
  storeInformation: "店舗情報",
  storeList: "店舗一覧",
  storeName: "店舗名",
  stringLengthMustBe: (length: number) => `${length} 文字で入力してください`,
  stringLengthMustBeAtLeast: (min: number) =>
    `${min} 文字以上で入力してください`,
  stringLengthMustBeAtMost: (max: number) =>
    `${max} 文字以下で入力してください`,
  stringLengthMustBeInRange: (min: number, max: number) =>
    `${min} 文字以上 ${max} 文字以下で入力してください`,
  stringMustBeAlphanumeric: "半角英数字で入力してください",
  stringMustBeKatakana: "カタカナで入力してください",
  stringMustBeNumeric: "半角数字で入力してください",
  subImage: "サブ画像",
  subtotal: "小計",
  termsOfService: "利用規約",
  thisAccountIsPrivate: "このアカウントは非公開です。",
  thisAccountIsPrivateDetails:
    "このアカウントをフォローして、\n欲しい物リストや記念日をチェックしよう。",
  today: "本日",
  tokutei: "特定商取引法に基づく表示",
  tomorrow: "明日",
  tooManySignInAttempts:
    "ログインの最大試行回数に達しています。しばらくした後、もう一度お試しください。",
  topPageDescription:
    "大切な人に贈る素敵な「ギフト」と出逢えるギフトECサービス。ギフレターでは誕生日や記念日などの各種シーンにオススメのギフトを多数ご用意しています。また、相手の住所を知らなくても贈れるオンラインギフトも充実しています。",
  topPageTitle: "贈りたいギフトと出逢える",
  toRemenberLettersAndGifts: "大切なレターやギフトを\n忘れないために",
  toRemenberLettersAndGiftsDescription:
    "新規登録すると、レターやギフトの保存、リマインド機能などを利用できます。",
  total: "合計",
  totalAmount: "合計金額",
  totalItemAmount: "商品合計",
  totalNItems: (n: number) => `全${n}件`,
  transferAnniversariesConfirmMessage:
    "このユーザーの記念日をすべて移動しますか？",
  truck: "トラック",
  turnOffRestockNotification: "再入荷通知を解除",
  twitter: "Twitter",
  unblock: "ブロック解除",
  unblocked: "ブロック解除しました",
  unblockThisUser: "ブロックを解除",
  undo: "取り消す",
  unfollow: "フォローを外す",
  unfollowConfirmation: "フォローを外しますか？",
  unfollowConfirmationDescription:
    "一度フォローを外すと、このユーザーには再度フォローリクエストを承認してもらう必要があります。",
  unspecified: "未指定",
  unused: "利用前",
  usage: "利用方法",
  used: "利用済",
  usedGift: "ギフトを利用しました",
  usedGiftMessage: "すてきなお時間をギフトと共にお過ごしください",
  usedGiftSkusEmpty: "利用済みのギフトはありません。",
  useGift: "ギフトを利用する",
  useOnOnline: "オンラインで使用",
  userAnniversariesEmptyMessage: "記念日が登録されていません",
  userName: "ユーザーID",
  userNameAlreadyInUse: "このユーザーIDはすでに使われています",
  userNotFound: "ユーザーが見つかりませんでした",
  userRelation: "お相手との関係",
  userSearching: "ユーザー検索",
  userSpot: "よく行くスポット",
  usingGift: "ギフトを利用",
  usingGiftCompleted: "利用完了",
  usingGiftConfirmation: "ギフトを利用しますか？",
  usingGiftConfirmationMessage:
    "この操作は取り消すことはできません。お店の方に利用できるギフトか確認と同意の上、「ギフトを利用する」を押してください。",
  valentineDay: "バレンタインデー",
  viewItem: "商品を確認する",
  visa: "Visa",
  waitForTheGiftToArrive: "ギフトの到着まで今しばらくお待ち下さい。",
  wayToUseGift: "ギフトの使用方法",
  weakPassword: "パスワードは 6 文字以上で入力してください",
  wedding: "結婚記念日",
  weekday: (d: number) => ["日", "月", "火", "水", "木", "金", "土"][d],
  whatKindOfGift: "どんなギフトをおさがしですか？",
  whiteDay: "ホワイトデー",
  wife: "妻",
  wishes: "ほしいもの",
  wishesEmpty: "ほしいものに追加すると\nお友達にほしいものが公開されます",
  wishesNotFound: "ほしいものが登録されていません",
  wishesOf: (name: string) => `${name}さんのほしいもの`,
  wrapping: "ラッピング",
  yearly: "毎年",
  youngerBrother: "弟",
  youngerSister: "妹",
  yourName: "お名前",
} as const;
