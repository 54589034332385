import Link from "next/link";

type Props = {
  href: string;
  children: React.ReactNode;
};

export const MenuLink: React.FC<Props> = ({ href, children }) => {
  return (
    <Link
      href={href}
      className="flex w-full items-center justify-center focus:outline-none focus-visible:bg-[#edeceb]"
    >
      {children}
    </Link>
  );
};
