import Link from "next/link";
import { useSession } from "../../../hooks/useSession";
import { i18n } from "../../../i18n";
import { pagesPath } from "../../../utils/$path";
import { NotificationIcon } from "../../icons/NotificationIcon";
import { CartBox } from "./CartBox";

export const HeaderRightMenu: React.FC = () => {
  const [me] = useSession();

  return me ? (
    <>
      {/* TODO: Dropdown実装 */}
      <button type="button">
        <NotificationIcon size={20} />
      </button>
      <CartBox iconSize={20} />
      <button type="button">
        <span className="sr-only">{i18n.dropdownMenu}</span>
        <img
          src={me.avatarImageUrl}
          alt={me.displayName}
          width={24}
          height={24}
          className="rounded-full"
          decoding="async"
          loading="lazy"
        />
      </button>
    </>
  ) : (
    <>
      <div className="flex h-8 items-center space-x-4 border-r pr-6">
        <Link
          href={pagesPath.signup.$url()}
          className="rounded text-xs font-bold text-brand focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-light"
        >
          {i18n.createNew}
        </Link>
        <Link
          href={pagesPath.signin.$url()}
          className="rounded text-xs font-bold text-brand focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-light"
        >
          {i18n.signIn}
        </Link>
      </div>
      <CartBox iconSize={20} />
    </>
  );
};
