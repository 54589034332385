import type { ReactNode } from "react";
import { useMediaQueries } from "../../../hooks/useMediaQueries";
import { staticPath } from "../../../utils/$path";
import { PrettyLink } from "../PrettyLink";
import { HeaderRightMenu } from "./HeaderRightMenu";
import { SearchBox } from "./SearchBox";

type Props = {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  bottom?: ReactNode;
};

export const HeaderMenu: React.FC<Props> = ({
  left,
  center,
  right,
  bottom,
}) => {
  const { isMd } = useMediaQueries();
  return (
    <>
      {isMd ? (
        <header className="sticky top-0 z-10 bg-white px-5 drop-shadow-header-menu">
          <div className="mx-auto flex h-16 max-w-screen-lg">
            <div className="flex items-center">
              <div className="flex items-center">
                <PrettyLink href="/">
                  <img
                    src={staticPath.assets.logo_png}
                    width={115}
                    height={26}
                    alt="giff letter"
                    decoding="async"
                    loading="lazy"
                  />
                </PrettyLink>
              </div>
              <div className="ml-5 flex items-center">
                <SearchBox />
              </div>
            </div>
            <div className="ml-auto flex items-center">
              <div className="flex items-center space-x-6">
                <HeaderRightMenu />
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header className="sticky top-0 z-10 bg-background drop-shadow-header-menu">
          <div className="flex h-header-menu-height items-center px-3">
            <div className="flex w-1/6 items-center justify-start">{left}</div>
            <div className="flex w-4/6 items-center justify-center">
              {center}
            </div>
            <div className="flex w-1/6 items-center justify-end">{right}</div>
          </div>
          {bottom}
        </header>
      )}
    </>
  );
};
