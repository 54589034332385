type ProviderData = {
  providerId: string;
}[];

type User = {
  providerData: ProviderData;
};

export function checkUsingPasswordSignIn(user: User): boolean {
  return user.providerData.some(({ providerId }) => providerId === "password");
}
